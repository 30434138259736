import axiosClient from "./index";
import Cookies from "js-cookie";

export const SigninAPI = async (payload) => {
  const URL = "/auth/signin";
  // console.log("payload", payload);
  try {
    const response = await axiosClient.post(URL, {
      //   headers: {
      //     "Content-Type": "application/json",
      //     "Access-Control-Allow-Credentials": true,
      //   },
      ...payload,
      /* params: {
        token: Cookies.get("token"),
      }, */
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetCeo = async () => {
  const URL = "/uniqueRock";
  try {
    const response = await axiosClient.get(URL, {
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // ...payload,
      /* params: {
        token: Cookies.get("token"),
      }, */
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetVisitorNumAssumptionsCommentsAPI = async () => {
  const URL = "/v1/visitorAssumptions/comments";
  try {
    const response = await axiosClient.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetVisitorNumAssumptionsByIdAPI = async (id) => {
  const URL = `/v1/visitorAssumptions/${id}`;
  try {
    const response = await axiosClient.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const AddVisitorNumAssumptionsAPI = async (payload) => {
  const URL = "/v1/VisitorAssumptions";
  try {
    const response = await axiosClient.post(URL, {
      ...payload,
      token: Cookies.get("token"),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetVisitorNumAssumptionsDraftByIdAPI = async (
  parentId,
  draftId
) => {
  const URL = `/v1/VisitorAssumptions/getDraft/${parentId}/${draftId}`;
  try {
    const response = await axiosClient.post(URL, {
      token: Cookies.get("token"),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
