import { useState, useEffect } from "react";
import {
  Box,
  Flex,
  HStack,
  TabPanel,
  TabPanels,
  Tabs,
  Image,
} from "@chakra-ui/react";

import { GetProductsAPI } from "../api/ProductsAPI";

import { MainButton } from "../components/button/MainButton";
import { TablePagination2 } from "../components/tablePagination/TablePagination2";

export const ProductsTable = ({
  onDelModalOpen,
  generalData,
  setSelectedID,
  seoRole,
}) => {
  let [currPagData, setCurrPagData] = useState({
    page: 1,
  });
  const [totalRowsTable, setTotalRowsTable] = useState(generalData.totalRows);

  const handleSort = async () => {
    handleOnPageChange(currPagData.page);
    setCurrPagData({ ...currPagData });
  };
  //console.log("currPagData", currPagData);
  const [dataArray, setDataArray] = useState([]);
  const columns = [
    {
      name: "العنوان",
      selector: (row) => row.title,
    },

    {
      name: "الكود",
      selector: (row) => row.code,
    },

    {
      name: "الإجراء",
      selector: (row) => row.action,
      cell: (row) => (
        <Flex
          justifyContent="space-between"
          gap="15px"
          alignItems="center"
          flexDirection={["column", "column", "row"]}
        >
          <MainButton
            title="تعديل"
            btnStyle="transparent"
            fontColor="dark"
            marginRight="5"
            navLink={true}
            path={`/Product/${row.idCustom}`}
          />
          {!seoRole && (
            <MainButton
              title="حذف"
              btnStyle="transparent"
              fontColor="danger"
              marginRight="5"
              onPress={() => {
                setSelectedID(`delete-${row.idCustom}`);
                onDelModalOpen();
              }}
            />
          )}
        </Flex>
      ),
    },
  ];

  useEffect(() => {
    let data = [];
    if (generalData?.data?.length > 0 && !!generalData) {
      setTotalRowsTable(generalData.totalRows);
      generalData.data.forEach((item) => {
        let dataObj = {
          id: `${item.id + Math.floor(Math.random() * Date.now())}`,
          idCustom: `${item.id}`,
          title: `${item.title}`,
          code: `${item.code}`,
        };

        data.push(dataObj);
      });
      setDataArray(data);
    }
    setCurrPagData({
      page: 1,
    });
  }, []);

  const handleOnPageChange = (page, filters) => {
    // GetProductsAPI({ offset: 0, limit: 10 }, page, filters)
    GetProductsAPI({ offset: 0, limit: 10 }, page, filters)
      .then((res) => {
        let data = [];

        if (res.data.length > 0) {
          setTotalRowsTable(res.totalRows);
          res.data.forEach((item) => {
            let dataObj = {
              id: `${item.id + Math.floor(Math.random() * Date.now())}`,
              idCustom: `${item.id}`,
              title: `${item.title}`,
              code: `${item.code}`,
            };

            data.push(dataObj);
          });
        }
        setDataArray(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <HStack width="100%">
        <Tabs variant="unstyled" width="100%">
          <TabPanels>
            <TabPanel w="98%" px="0">
              <TablePagination2
                data={dataArray}
                columns={columns}
                isSearchable={false}
                title="المنتجات"
                hasDelete={true}
                totalRows={totalRowsTable <= 10 ? totalRowsTable : 10}
                pageSize={generalData.limit}
                GetDataAPI={GetProductsAPI}
                handleOnPageChange={handleOnPageChange}
                onSort={handleSort}
                setCurrPagData={setCurrPagData}
                currPagData={currPagData}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </HStack>
    </>
  );
};
