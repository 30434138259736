import React, { useState, useEffect, useCallback } from "react";

import { Box, Text, Center, Spinner, useDisclosure } from "@chakra-ui/react";

import { GetOrdersAPI, DeleteOrderByIdAPI } from "../../../api/ProductsAPI";

import { MainTitle } from "../../../components/mainTitle/MainTitle";
import { MainBox } from "../../../components/mainBox/MainBox";
import { MainButton } from "../../../components/button/MainButton";
import { OrdersTable } from "./OrdersTable";
import { ModalAlert } from "../../../components/modalAlert/ModalAlert";

import doc from "../../../assets/images/iconsSvg/doc.svg";
import plus from "../../../assets/images/iconsSvg/plus.svg";
import infoCircle from "../../../assets/images/iconsSvg/infoCircle.svg";

export const OrdersHistory = () => {
  const {
    isOpen: isDelModalOpen,
    onOpen: onDelModalOpen,
    onClose: onDelModalClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();
  const [generalData, setGeneralData] = useState([]);
  const [selectedID, setSelectedID] = useState("-1");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const loadTableData = useCallback(() => {
    document.getElementById("main_content").scrollTo(0, 0);

    // setIsLoading(false);
    // setGeneralData({
    //   currentPage: 1,
    //   totalRows: 3,
    //   limit: 10,
    //   data: [
    //     {
    //       id: 1,
    //       product_id: 1,
    //       color_id: 1,
    //       product_thikness_id: 1,
    //       product_standardSize_id: null,
    //       title: "Mrs",
    //       firstName: "Nawal",
    //       mobile: "0788211936",
    //       lastName: "Owaidah",
    //       type: "user",
    //       company: null,
    //       companyActivity: null,
    //       email: "nawal.oweda@exotox.com",
    //       website: null,
    //       area: null,
    //       address: "Amman - Jordan",
    //       constructionType: null,
    //       uses: null,
    //       details: null,
    //       attachment: null,
    //     },
    //     {
    //       id: 3,
    //       product_id: 2,
    //       color_id: 1,
    //       product_thikness_id: 1,
    //       product_standardSize_id: null,
    //       title: "Mrs",
    //       firstName: "Nawal",
    //       mobile: "0788211936",
    //       lastName: "Owaidah",
    //       type: "company",
    //       company: "XOTOX company",
    //       companyActivity: "Branding Agency",
    //       email: "nawal.oweda@gmail.com",
    //       website: "exotox.com",
    //       area: "Gardans",
    //       address: "Amman - Jordan",
    //       constructionType: "comercial",
    //       uses: "wall",
    //       details: "details details details details",
    //       attachment: 1,
    //     },
    //     {
    //       id: 4,
    //       product_id: 2,
    //       color_id: 1,
    //       product_thikness_id: 1,
    //       product_standardSize_id: 1,
    //       title: "Mrs",
    //       firstName: "Nawal",
    //       mobile: "0788211936",
    //       lastName: "Owaidah",
    //       type: "company",
    //       company: "XOTOX company",
    //       companyActivity: "Branding Agency",
    //       email: "nawal.oweda@gmail.com",
    //       website: "exotox.com",
    //       area: "Gardans",
    //       address: "Amman - Jordan",
    //       constructionType: "comercial",
    //       uses: "wall",
    //       details: "details details details details",
    //       attachment: 1,
    //     },
    //   ],
    // });

    GetOrdersAPI({ offset: 0, limit: 10 }, 1)
      .then((res) => {
        setGeneralData(res);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    loadTableData();
  }, [loadTableData]);

  const renderRowsAfterDeletion = (id) => {
    if (!id.startsWith("delete-")) {
      return;
    }

    DeleteOrderByIdAPI(id.split("-")[1])
      .then((res) => {
        setErrorMessage("");
        setIsLoading(true);
        loadTableData();
        /* if (res.success === true) {
          loadTableData();
        } */
        return res;
      })
      .catch((err) => {
        setErrorMessage(err?.response?.data?.message || "هناك خطأ ما");
        onErrorOpen();
      });
  };

  return (
    <>
      {isLoading ? (
        <>
          <MainTitle title="طلبات المنتجات" />
          <MainBox>
            <Box paddingTop={12} paddingBottom={12}>
              <Center>
                <Spinner />
              </Center>
            </Box>
          </MainBox>
        </>
      ) : (
        <>
          {generalData?.data?.length <= 0 ? (
            <>
              <MainTitle title="طلبات المنتجات" />
              <MainBox>
                <Box className="table_content" pt={6} pb={6}>
                  <Center flexDirection="column">
                    <img src={doc} alt="icon" />
                    <Box mt={3} mb={5}>
                      <Text className="title_sm">لا يوجد إدخالات حالياً</Text>
                    </Box>
                    <MainButton
                      title="إضافة"
                      marginRight="1"
                      icon={plus}
                      navLink={true}
                      path="/Order"
                    />
                  </Center>
                </Box>
              </MainBox>
            </>
          ) : (
            <>
              <OrdersTable
                generalData={generalData}
                setSelectedID={setSelectedID}
                onDelModalOpen={onDelModalOpen}
              />
            </>
          )}
        </>
      )}

      <ModalAlert
        isOpen={isDelModalOpen}
        onClose={onDelModalClose}
        title="هل أنت متأكد أنك تريد الحذف؟"
        icon={infoCircle}
        hasDesc={false}
        confirmationModal={true}
        haveSaveDraft={true}
        isDelete={true}
        selectedID={selectedID}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        renderRowsAfterDeletion={renderRowsAfterDeletion}
      />

      <ModalAlert
        isOpen={isErrorOpen}
        onClose={onErrorClose}
        title={errorMessage}
        icon={infoCircle}
        hasDesc={false}
        confirmationModal={false}
      />
    </>
  );
};
