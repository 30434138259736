import axiosClient from "./index";
import { AxiosClientMultipart, AxiosClientPost } from "./index";
import Cookies from "js-cookie";

//JOURNEY
export const GetJourneysAPI = async (limit = 10, offset = 0, page = 1) => {
  offset = (page - 1) * limit;
  const URL = `/journey/lang?lang=ar&limit=${limit}&offset=${offset}`;
  // const URL = `/journey`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetJourneyByIdAPI = async (id) => {
  const URL = `/journey/${id}`;
  try {
    const response = await axiosClient.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddJourneyAPI = async (payload) => {
  const URL = "/journey";
  try {
    const response = await AxiosClientPost.post(URL, {
      ...payload,
      //auth: Cookies.get("token"),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const UpdateJourneyAPI = async (id, payload) => {
  const URL = `/journey/${id}`;
  try {
    const response = await AxiosClientPost.put(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DeleteJourneyByIdAPI = async (id) => {
  const URL = `/journey/${id}`;
  try {
    const response = await AxiosClientPost.delete(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//SERVICES
export const GetServicesAPI = async (limit = 10, offset = 0, page = 1) => {
  offset = (page - 1) * limit;
  const URL = `/services/lang?lang=ar&limit=${limit}&offset=${offset}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetServicesByIdAPI = async (id) => {
  const URL = `/services/${id}`;
  try {
    const response = await axiosClient.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddServicesAPI = async (payload) => {
  const URL = "/services";
  try {
    const response = await AxiosClientPost.post(URL, {
      ...payload,
      //auth: Cookies.get("token"),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const UpdateServicesAPI = async (id, payload) => {
  const URL = `/services/${id}`;
  try {
    const response = await AxiosClientPost.put(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DeleteServicesByIdAPI = async (id) => {
  const URL = `/services/${id}`;
  try {
    const response = await AxiosClientPost.delete(URL, {
      /* params: {
          token: Cookies.get("token"),
        }, */
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//ABOUT US
export const GetAboutUs = async () => {
  const URL = `/about/1`;
  try {
    const response = await axiosClient.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const UpdateAboutUsAPI = async (payload) => {
  const URL = `/about/1`;
  try {
    const response = await AxiosClientPost.put(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//UNIQUE ROCK
export const GetUniqueRocksAPI = async (limit = 10, offset = 0, page = 1) => {
  offset = (page - 1) * limit;
  const URL = `/uniqueRock/lang?lang=ar&limit=${limit}&offset=${offset}`;
  try {
    const response = await axiosClient.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetUniqueRockByIdAPI = async (id) => {
  const URL = `/uniqueRock/${id}`;
  try {
    const response = await axiosClient.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddUniqueRockAPI = async (payload) => {
  const URL = "/uniqueRock";
  try {
    const response = await AxiosClientPost.post(URL, {
      ...payload,
      //auth: Cookies.get("token"),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const UpdateUniqueRockAPI = async (id, payload) => {
  const URL = `/uniqueRock/${id}`;
  try {
    const response = await AxiosClientPost.put(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DeleteUniqueRockByIdAPI = async (id) => {
  const URL = `/uniqueRock/${id}`;
  try {
    const response = await AxiosClientPost.delete(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//FACTORY
export const GetFactoryAPI = async (limit = 10, offset = 0, page = 1) => {
  offset = (page - 1) * limit;
  const URL = `/factory/lang?lang=ar&limit=${limit}&offset=${offset}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetFactoryByIdAPI = async (id) => {
  const URL = `/factory/${id}`;
  try {
    const response = await axiosClient.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddFactoryAPI = async (payload) => {
  const URL = "/factory";
  try {
    const response = await AxiosClientPost.post(URL, {
      ...payload,
      //auth: Cookies.get("token"),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const UpdateFactoryAPI = async (id, payload) => {
  const URL = `/factory/${id}`;
  try {
    const response = await AxiosClientPost.put(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DeleteFactoryByIdAPI = async (id) => {
  const URL = `/factory/${id}`;
  try {
    const response = await AxiosClientPost.delete(URL, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

//CONTACT FORM
export const GetContactFormAPI = async (limit = 10, offset = 0, page = 1) => {
  offset = (page - 1) * limit;
  const URL = `/contactForm/lang?limit=${limit}&offset=${offset}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetContactFormByIdAPI = async (id) => {
  const URL = `/contactForm/${id}`;
  try {
    const response = await axiosClient.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddContactFormAPI = async (payload) => {
  const URL = "/contactForm";
  try {
    const response = await AxiosClientPost.post(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const UpdateContactFormAPI = async (id, payload) => {
  const URL = `/contactForm/${id}`;
  try {
    const response = await AxiosClientPost.put(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DeleteContactFormByIdAPI = async (id) => {
  const URL = `/contactForm/${id}`;
  try {
    const response = await AxiosClientPost.delete(URL, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};
