import React, { useState, useEffect } from "react";

import {
  GetProductCountryByIdAPI,
  UpdateProductCountryAPI,
  AddProductCountryAPI,
  GetAllCountryAPI,
} from "../../../api/ProductsAPI";

import { useLocation, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { Box, useDisclosure } from "@chakra-ui/react";

import { activeMenuItem } from "../../../utils/General";
import { ModalAlert } from "../../../components/modalAlert/ModalAlert";
import { MainTitle } from "../../../components/mainTitle/MainTitle";
import { MainBox } from "../../../components/mainBox/MainBox";
import { ProductsCountryForm } from "./ProductsCountryForm";
import { validationProductsCountryForm } from "./validationProductsCountryForm";
import history from "../../../history";

import checked from "../../../assets/images/iconsSvg/checked.svg";
import infoCircle from "../../../assets/images/iconsSvg/infoCircle.svg";

export const ProductsCountry = () => {
  const activeMenu = activeMenuItem();
  const { id } = useParams();
  let location = useLocation();
  //const [isRestore, setIsRestore] = useState(false);
  const [initFormVal, setInitFormVal] = useState({});
  const [generalData, setGeneralData] = useState({});
  const [allCountry, setAllCountry] = useState([]);
  const [allCountryInfo, setAllCountryInfo] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [disableSave, setDisableSave] = useState(true);
  const [dismissSave, setDismissSave] = useState(true);
  const [imageObj, setImageObj] = useState({});

  let submitModalTitle = id ? "تم التحديث بنجاح" : "تم الإضافة بنجاح";

  const {
    isOpen: isCancelOpen,
    onOpen: onCancelOpen,
    onClose: onCancelClose,
  } = useDisclosure();
  const {
    isOpen: isSubmitOpen,
    onOpen: onSubmitOpen,
    onClose: onSubmitClose,
  } = useDisclosure();

  const {
    isOpen: isUploadOpen,
    onOpen: onUploadOpen,
    onClose: onUploadClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();

  const handleOnSubmit = (formikValue) => {
    if (!!id) {
      const submitValues = {
        // ...formikValue,
        id: parseInt(id),
        countryID: formikValue.countryID,
        description: formikValue.description,
        descriptionEn: formikValue.descriptionEn,
        title: formikValue.title,
        titleEn: formikValue.titleEn,
        code: formikValue.titleEn,
      };

      UpdateProductCountryAPI(id, submitValues)
        .then((res) => {
          setDisableSave(true);
          setErrorMessage("");
          onSubmitOpen();
          if (res.success === true) {
            setDisableSave(true);
            setErrorMessage("");
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(err?.response?.data?.message || "هناك خطأ ما");
          onErrorOpen();
        });
    } else {
      const submitValues = {
        // ...formikValue,

        countryID: formikValue.countryID,
        description: formikValue.description,
        descriptionEn: formikValue.descriptionEn,
        title: formikValue.title,
        titleEn: formikValue.titleEn,
      };
      AddProductCountryAPI(submitValues)
        .then((res) => {
          setDisableSave(true);
          setErrorMessage("");
          onSubmitOpen();
          if (res.success === true) {
            setDisableSave(true);
            setErrorMessage("");
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(err?.response?.data?.message || "هناك خطأ ما");
          console.log("err", err);
          onErrorOpen();
        });
    }
  };

  useEffect(() => {
    GetAllCountryAPI()
      .then((response) => {
        let countryArr = [];
        response.map((el) => {
          countryArr.push({ value: el.id, label: el.country });
        });
        setAllCountryInfo(response);
        setAllCountry(countryArr);

        if (!!id) {
          setErrorMessage("");
          GetProductCountryByIdAPI(id)
            .then((res) => {
              const filterCountry = response.find(
                (el) => el.id == res.countryID
              );
              // console.log("filterCountry", filterCountry, countryArr);
              setInitFormVal({
                countryID: res.countryID,
                description: res.description,
                descriptionEn: res?.descriptionEn,
                title: filterCountry?.country,
                titleEn: filterCountry?.countryEn,
              });
              setGeneralData(res);
            })
            .catch((err) => {
              setErrorMessage("هناك خطأ ما");
              onErrorOpen();
            });
        } else {
          setInitFormVal({
            countryID: "",
            description: "",
            descriptionEn: "",
            title: "",
            titleEn: "",
          });
        }
      })
      .catch((err) => {
        setErrorMessage("هناك خطأ ما");
        onErrorOpen();
      });
  }, []);
  useEffect(() => {
    let unblock;
    if (!disableSave && dismissSave) {
      unblock = history.block((tx) => {
        let url = tx.location.pathname;
        if (window.confirm(`هل أنت متأكد أنك تريد تجاهل التغييرات؟`)) {
          unblock();

          tx.retry();
        }
      });
    }

    return () => {
      if (typeof unblock === "function") {
        unblock();
      }
    };
  }, [disableSave, dismissSave]);

  return (
    <div className="Assumptions">
      <>
        <MainBox px={0}>
          {id === undefined ? (
            <MainTitle title="إضافة دولة" bBottom={true} px={7} />
          ) : (
            <MainTitle title="تعديل دولة" bBottom={true} px={7} />
          )}
          <Box className="table_content" pt={6} pb={6}>
            <Formik
              initialValues={initFormVal}
              enableReinitialize={true}
              validationSchema={validationProductsCountryForm}
              validate={(values) => {
                if (disableSave) {
                  setDisableSave(false);
                }
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  alert(JSON.stringify(values, null, 2));
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({
                errors,
                touched,
                values,
                handleChange,
                handleBlur,
                setFieldValue,
                isValid,
                dirty,
              }) => (
                <Form className="form_wrapper">
                  <ProductsCountryForm
                    onCancelOpen={onCancelOpen}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    formikValues={values}
                    isValid={isValid}
                    dirty={dirty}
                    disableSave={disableSave}
                    setDisableSave={setDisableSave}
                    dismissSave={dismissSave}
                    setDismissSave={setDismissSave}
                    onSubmitOpen={onSubmitOpen}
                    onUploadOpen={onUploadOpen}
                    handleOnSubmit={handleOnSubmit}
                    setInitFormVal={setInitFormVal}
                    generalData={generalData}
                    isUploadOpen={isUploadOpen}
                    onUploadClose={onUploadClose}
                    onErrorOpen={onErrorOpen}
                    setErrorMessage={setErrorMessage}
                    setImageObj={setImageObj}
                    allCountry={allCountry}
                    allCountryInfo={allCountryInfo}
                  />

                  <ModalAlert
                    isOpen={isCancelOpen}
                    onClose={onCancelClose}
                    title="هل أنت متأكد أنك تريد تجاهل التغييرات؟"
                    icon={infoCircle}
                    hasDesc={false}
                    confirmationModal={true}
                    path={`/ProductsCountryHistory`}
                    setDismissSave={() => setDismissSave(true)}
                  />
                </Form>
              )}
            </Formik>
          </Box>
        </MainBox>

        <ModalAlert
          isOpen={isErrorOpen}
          onClose={onErrorClose}
          title={errorMessage}
          icon={infoCircle}
          hasDesc={false}
          confirmationModal={false}
        />

        <ModalAlert
          isOpen={isSubmitOpen}
          onClose={onSubmitClose}
          title={submitModalTitle}
          btnWidthFull={true}
          icon={checked}
          hasDesc={false}
          disableClose={true}
          path={`/ProductsCountryHistory`}
        />
      </>
    </div>
  );
};
