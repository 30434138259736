import React, { useState, useEffect } from "react";

import {
  GetProjectByIdAPI,
  UpdateProjectAPI,
  AddProjectAPI,
} from "../../api/ProjectsAPI";

import { GetProductsAPI } from "../../api/ProductsAPI";

import { useLocation, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { Box, useDisclosure } from "@chakra-ui/react";

import { activeMenuItem } from "../../utils/General";
import { ModalAlert } from "../../components/modalAlert/ModalAlert";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { MainBox } from "../../components/mainBox/MainBox";
import { ProjectsForm } from "./ProjectsForm";
import { validationProjectsForm } from "./validationProjectsForm";
import history from "../../history";

import checked from "../../assets/images/iconsSvg/checked.svg";
import infoCircle from "../../assets/images/iconsSvg/infoCircle.svg";

export const Projects = () => {
  const activeMenu = activeMenuItem();
  const { id } = useParams();
  let location = useLocation();
  const [initFormVal, setInitFormVal] = useState({});
  const [generalData, setGeneralData] = useState({});
  const [allProducts, setAllProducts] = useState([]);
  const [allProducts2, setAllProducts2] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [disableSave, setDisableSave] = useState(true);
  const [dismissSave, setDismissSave] = useState(true);
  const [imageObj, setImageObj] = useState({});

  let submitModalTitle = id ? "تم التحديث بنجاح" : "تم الإضافة بنجاح";

  const {
    isOpen: isCancelOpen,
    onOpen: onCancelOpen,
    onClose: onCancelClose,
  } = useDisclosure();
  const {
    isOpen: isSubmitOpen,
    onOpen: onSubmitOpen,
    onClose: onSubmitClose,
  } = useDisclosure();

  const {
    isOpen: isUploadOpen,
    onOpen: onUploadOpen,
    onClose: onUploadClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();

  const handleOnSubmit = (formikValue) => {
    if (!!id) {
      let year = parseInt(new Date(formikValue.yearOfSupply).getFullYear());
      // let productFull = allProducts.filter((el) => {
      //   if (el.clicked) {
      //     return el;
      //   }
      // });

      // productFull = productFull.map((el) => el.id);
      const submitValues = {
        // ...formikValue,
        title: formikValue.title,
        titleEn: formikValue.titleEn,
        addressEn: formikValue.addressEn,
        metaDescEn: formikValue.metaDescEn,
        metaTitleEn: formikValue.metaTitleEn,
        metaKeys: formikValue.metaKeys,
        metaKeysEn: formikValue.metaKeysEn,
        detailsEn: formikValue.detailsEn,
        // addformikValues: formikValue.addformikValues,
        details: formikValue.details,
        address: formikValue.address,
        contractor: formikValue.contractor,
        contractorEn: formikValue.contractorEn,
        owner: formikValue.owner,
        ownerEn: formikValue.ownerEn,
        yearOfSupply: year,
        image1: formikValue.image1,
        image2: formikValue.image2,
        image3: formikValue.image3,
        metaTitle: formikValue.metaTitle,
        metaDesc: formikValue.metaDesc,
        alt1: formikValue.alt1,
        alt2: formikValue.alt2,
        alt3: formikValue.alt3,

        // products: productFull,

        products: formikValue.products,
      };
      //console.log("submit values", submitValues);

      UpdateProjectAPI(id, submitValues)
        .then((res) => {
         // console.log("res", res);
          setDisableSave(true);
          setErrorMessage("");
          onSubmitOpen();
          if (res.success === true) {
            setDisableSave(true);
            setErrorMessage("");
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(err?.response?.data?.message || "هناك خطأ ما");
          onErrorOpen();
        });
    } else {
      let year = parseInt(new Date(formikValue.yearOfSupply).getFullYear());
      // let productFull = allProducts.filter((el) => {
      //   if (el.clicked) {
      //     return el;
      //   }
      // });

      // productFull = productFull.map((el) => el.id);
      const submitValues = {
        // ...formikValue,
        title: formikValue.title,
        titleEn: formikValue.titleEn,
        address: formikValue.address,
        addressEn: formikValue.addressEn,
        metaDescEn: formikValue.metaDescEn,
        metaTitleEn: formikValue.metaTitleEn,
        metaKeys: formikValue.metaKeys,
        metaKeysEn: formikValue.metaKeysEn,
        detailsEn: formikValue.detailsEn,
        // addformikValues: formikValue.addformikValues,
        details: formikValue.details,
        contractorEn: formikValue.contractorEn,
        contractor: formikValue.contractor,
        owner: formikValue.owner,
        ownerEn: formikValue.ownerEn,
        yearOfSupply: year,
        image1: formikValue.image1,
        image2: formikValue.image2,
        image3: formikValue.image3,
        metaTitle: formikValue.metaTitle,
        metaDesc: formikValue.metaDesc,
        alt1: formikValue.alt1,
        alt2: formikValue.alt2,
        alt3: formikValue.alt3,
        // products: productFull,
        products: formikValue.products,
      };
      //console.log(JSON.stringify(submitValues));
      AddProjectAPI(submitValues)
        .then((res) => {
          //console.log("Success");
          setDisableSave(true);
          setErrorMessage("");
          onSubmitOpen();
          if (res.success === true) {
            setDisableSave(true);
            setErrorMessage("");
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(err?.response?.data?.message || "هناك خطأ ما");
          console.log("err", err);
          onErrorOpen();
        });
    }
  };

  useEffect(() => {
    GetProductsAPI({ offset: 0, limit: 100 }, 1)
      .then((res) => {
        //console.log("res products", res);
        let products = res.data.map((el) => el);
        //console.log("productsNew" , products)
        // const products = res.data.map((el) => el.id);
     
        if (!!id) {
       
          setErrorMessage("");
          GetProjectByIdAPI(id)
            .then((res) => {
            
              //console.log("reees", res);

              let productsNew = [...products];
            
            
              // if(res.products !== null || res.products.length  === [] || res.products.length  === undefined){
                
              //     productsNew = products.map((pr) => {
              //       //console.log("pr find -1", pr, res.products);
              //       const prodFind = res.products.find((el) => el == pr.id);

              //       //console.log("pr find 1", prodFind);
              //       return {
              //         ...pr,
              //         clicked: !!prodFind ? true : false,
              //       };
              //     });
              // }
           
              setInitFormVal({
                title: res.title,
                titleEn: res.titleEn,
                addressEn: res.addressEn,
                metaDescEn: res.metaDescEn,
                metaTitleEn: res.metaTitleEn,
                metaKeys: res.metaKeys,
                metaKeysEn: res.metaKeysEn,
                detailsEn: res.detailsEn,
                address: res.address,
                details: res.details,
                contractor: res.contractor,
                contractorEn: res.contractorEn,
                owner: res.owner,
                ownerEn: res.ownerEn,
                yearOfSupply: `${res.yearOfSupply}`,
                image1: res.image1,
                image2: res.image2,
                image3: res.image3,
                metaTitle: res.metaTitle,
                metaDesc: res.metaDesc,
                alt1: res.alt1,
                alt2: res.alt2,
                alt3: res.alt3,
                alt1En2: res?.alt1En,
                alt2En2: res?.alt2En,
                alt3En2: res?.alt3En,

                altEn: "",
                alt: res.altImage,
                file: "",

                products: res.products,
              });
              setGeneralData(res);
              // setAllProducts(productsNew);

              let newProd = productsNew.map((el) => {
                return { value: el.id, label: el.title };
              });
              
              setAllProducts2(newProd);
            })
            .catch((err) => {
              setErrorMessage("هناك خطأ ما");
              onErrorOpen();
            });
        } else {
          let newProducts = products.map((el) => {
            return { value: el.id, label: el.title };
          });
    
          setInitFormVal({
            title: "",
            titleEn: "",
            metaTitleEn: "",
            metaKeys: "",
            metaKeysEn: "",
            detailsEn: "",
            metaDescEn: "",
            addressEn: "",
            address: "",
            details: "",
            contractor: "",
            contractorEn: "",
            owner: "",
            ownerEn: "",
            yearOfSupply: ``,
            image1: "",
            image2: "",
            image3: "",
            metaTitle: "",
            metaDesc: "",
            alt1: "",
            alt2: "",
            alt3: "",
            alt1En2: "",
            alt2En2: "",
            alt3En2: "",

            altEn: "",
            alt: "",
            file: "",

            products: [],
          });

        
          
          
          setAllProducts2(newProducts);
          setAllProducts(newProducts);
        }
        //console.log(res.data);
        //return res;
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    let unblock;
    if (!disableSave && dismissSave) {
      unblock = history.block((tx) => {
        let url = tx.location.pathname;
        if (window.confirm(`هل أنت متأكد أنك تريد تجاهل التغييرات؟`)) {
          unblock();

          tx.retry();
        }
      });
    }

    return () => {
      if (typeof unblock === "function") {
        unblock();
      }
    };
  }, [disableSave, dismissSave]);

  return (
    <div className="Projects">
      <>
        <MainBox px={0} mb={0}>
          {id === undefined ? (
            <MainTitle title="إضافة المشاريع" bBottom={false} px={7} />
          ) : (
            <MainTitle title="تعديل المشاريع" bBottom={false} px={7} />
          )}
        </MainBox>
        <Box pt={0} pb={6}>
          <Formik
            initialValues={initFormVal}
            enableReinitialize={true}
            validationSchema={validationProjectsForm}
            validate={(values) => {
             // console.log("valll", values);
              if (disableSave) {
                setDisableSave(false);
              }
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2));
                setSubmitting(false);
              }, 400);
            }}
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              handleBlur,
              setFieldValue,
              isValid,
              dirty,
            }) => (
              <Form className="form_wrapper">
                <ProjectsForm
                  onCancelOpen={onCancelOpen}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  formikValues={values}
                  isValid={isValid}
                  dirty={dirty}
                  disableSave={disableSave}
                  setDisableSave={setDisableSave}
                  dismissSave={dismissSave}
                  setDismissSave={setDismissSave}
                  onSubmitOpen={onSubmitOpen}
                  onUploadOpen={onUploadOpen}
                  handleOnSubmit={handleOnSubmit}
                  setInitFormVal={setInitFormVal}
                  generalData={generalData}
                  isUploadOpen={isUploadOpen}
                  onUploadClose={onUploadClose}
                  onErrorOpen={onErrorOpen}
                  setErrorMessage={setErrorMessage}
                  setImageObj={setImageObj}
                  allProducts={allProducts}
                  setAllProducts={setAllProducts}
                  allProducts2={allProducts2}
                  setAllProducts2={setAllProducts2}
                />

                <ModalAlert
                  isOpen={isCancelOpen}
                  onClose={onCancelClose}
                  title="هل أنت متأكد أنك تريد تجاهل التغييرات؟"
                  icon={infoCircle}
                  hasDesc={false}
                  confirmationModal={true}
                  path={`/ProjectsHistory`}
                  setDismissSave={() => setDismissSave(true)}
                />
              </Form>
            )}
          </Formik>
        </Box>

        <ModalAlert
          isOpen={isErrorOpen}
          onClose={onErrorClose}
          title={errorMessage}
          icon={infoCircle}
          hasDesc={false}
          confirmationModal={false}
        />

        <ModalAlert
          isOpen={isSubmitOpen}
          onClose={onSubmitClose}
          title={submitModalTitle}
          btnWidthFull={true}
          icon={checked}
          hasDesc={false}
          disableClose={true}
          path={`/ProjectsHistory`}
        />
      </>
    </div>
  );
};
