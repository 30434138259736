import axiosClient from "./index";
import { AxiosClientMultipart, AxiosClientPost } from "./index";
import Cookies from "js-cookie";

//PORJECTS
export const GetProjectsAPI = async (limit = 10, offset = 0, page = 1) => {
  offset = (page - 1) * limit;
  const URL = `/projects/lang?limit=${limit}&offset=${offset}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetProjectByIdAPI = async (id) => {
  const URL = `/projects/${id}`;
  try {
    const response = await axiosClient.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddProjectAPI = async (payload) => {
  const URL = "/projects";
  try {
    const response = await AxiosClientPost.post(URL, {
      ...payload,
      //auth: Cookies.get("token"),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const UpdateProjectAPI = async (id, payload) => {
  const URL = `/projects/${id}`;
  try {
    const response = await AxiosClientPost.put(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DeleteProjectByIdAPI = async (id) => {
  const URL = `/projects/${id}`;
  try {
    const response = await AxiosClientPost.delete(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
