import React from "react";

import {
  Box,
  Divider,
  Flex,
  SimpleGrid,
  GridItem,
  Text,
  Image,
  Button,
} from "@chakra-ui/react";

import { InputFieldValidation } from "../../components/inputField/InputFieldValidation";
import { MainButton } from "../../components/button/MainButton";
import { TextArea } from "../../components/textArea/TextArea";

export const ConsultaionFormForm = ({
  errors,
  touched,
  setFieldValue,
  handleBlur,
  onCancelOpen,
  formikValues,
  disableSave,
  generalData,
  setDismissSave,
}) => {



  const download = (e, url) => {
    e.preventDefault();
    console.log(url);
    fetch(url, {
      method: "GET",
      mode: "no-cors",
  credentials: "include",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
         // const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("target", "_blank"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };


  return (
    <>
      <Box
        maxW={["100%", "100%", "60%"]}
        width={["100%", "100%", "60%"]}
        alignSelf="flex-start"
      >
        <SimpleGrid columns={{ sm: 4, md: 4 }} spacingX="15px" width="100%">
          <GridItem colSpan={4}>
            <SimpleGrid columns={{ sm: 4, md: 4 }} spacing="15px" width="100%">
              <GridItem colSpan={2}>
                <InputFieldValidation
                  label="الإسم الأول"
                  inputType="text"
                  inputId="firstName"
                  inputName="firstName"
                  errors={errors}
                  touched={touched}
                  isRequired
                  onChange={setFieldValue}
                  values={formikValues}
                  isDisabled={true}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <InputFieldValidation
                  label="الإسم الأخير"
                  inputType="text"
                  inputId="lastName"
                  inputName="lastName"
                  errors={errors}
                  touched={touched}
                  isRequired
                  placeholder=""
                  onChange={setFieldValue}
                  values={formikValues}
                  isDisabled={true}
                />
              </GridItem>
            </SimpleGrid>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid columns={{ sm: 4, md: 4 }} spacingX="15px" width="100%">
          <GridItem colSpan={4}>
            <SimpleGrid columns={{ sm: 4, md: 4 }} spacing="15px" width="100%">
              <GridItem colSpan={2}>
                <InputFieldValidation
                  label="الإيميل"
                  inputType="text"
                  inputId="email"
                  inputName="email"
                  errors={errors}
                  touched={touched}
                  isRequired
                  onChange={setFieldValue}
                  values={formikValues}
                  isDisabled={true}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <InputFieldValidation
                  label="الهاتف"
                  inputType="text"
                  inputId="mobile"
                  inputName="mobile"
                  errors={errors}
                  touched={touched}
                  isRequired
                  placeholder=""
                  onChange={setFieldValue}
                  values={formikValues}
                  isDisabled={true}
                />
              </GridItem>
            </SimpleGrid>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid columns={{ sm: 4, md: 4 }} spacingX="15px" width="100%">
          <GridItem colSpan={4}>
            <SimpleGrid columns={{ sm: 4, md: 4 }} spacing="15px" width="100%">
              <GridItem colSpan={2}>
                <InputFieldValidation
                  label="العنوان"
                  inputType="text"
                  inputId="address"
                  inputName="address"
                  errors={errors}
                  touched={touched}
                  isRequired
                  onChange={setFieldValue}
                  values={formikValues}
                  isDisabled={true}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <InputFieldValidation
                  label="المنطقة"
                  inputType="text"
                  inputId="area"
                  inputName="area"
                  errors={errors}
                  touched={touched}
                  isRequired
                  placeholder=""
                  onChange={setFieldValue}
                  values={formikValues}
                  isDisabled={true}
                />
              </GridItem>
            </SimpleGrid>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid columns={{ sm: 4, md: 4 }} spacingX="15px" width="100%">
          <GridItem colSpan={4}>
            <SimpleGrid columns={{ sm: 4, md: 4 }} spacing="15px" width="100%">
              <GridItem colSpan={2}>
                <InputFieldValidation
                  label="المنتج المطلوب"
                  inputType="text"
                  inputId="product"
                  inputName="product"
                  errors={errors}
                  touched={touched}
                  isRequired
                  onChange={setFieldValue}
                  values={formikValues}
                  isDisabled={true}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <InputFieldValidation
                  label="أنواع أخرى"
                  inputType="text"
                  inputId="otherProduct"
                  inputName="otherProduct"
                  errors={errors}
                  touched={touched}
                  isRequired
                  placeholder=""
                  onChange={setFieldValue}
                  values={formikValues}
                  isDisabled={true}
                />
              </GridItem>
            </SimpleGrid>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid columns={{ sm: 4, md: 4 }} spacingX="15px" width="100%">
          <GridItem colSpan={4}>
            <SimpleGrid columns={{ sm: 4, md: 4 }} spacing="15px" width="100%">
              <GridItem colSpan={2}>
                <InputFieldValidation
                  label="مقدم الطلب"
                  inputType="text"
                  inputId="type"
                  inputName="type"
                  errors={errors}
                  touched={touched}
                  isRequired
                  onChange={setFieldValue}
                  values={formikValues}
                  isDisabled={true}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <InputFieldValidation
                  label="الإستخدامات"
                  inputType="text"
                  inputId="uses"
                  inputName="uses"
                  errors={errors}
                  touched={touched}
                  isRequired
                  placeholder=""
                  onChange={setFieldValue}
                  values={formikValues}
                  isDisabled={true}
                />
              </GridItem>
            </SimpleGrid>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid columns={{ sm: 4, md: 4 }} spacingX="15px" width="100%">
          <GridItem colSpan={4}>
            <SimpleGrid columns={{ sm: 4, md: 4 }} spacing="15px" width="100%">
              <GridItem colSpan={2}>
                <InputFieldValidation
                  label="إسم الشركة"
                  inputType="text"
                  inputId="company"
                  inputName="company"
                  errors={errors}
                  touched={touched}
                  isRequired
                  onChange={setFieldValue}
                  values={formikValues}
                  isDisabled={true}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <InputFieldValidation
                  label="نشاط الشركة"
                  inputType="text"
                  inputId="companyActivity"
                  inputName="companyActivity"
                  errors={errors}
                  touched={touched}
                  isRequired
                  placeholder=""
                  onChange={setFieldValue}
                  values={formikValues}
                  isDisabled={true}
                />
              </GridItem>
            </SimpleGrid>
          </GridItem>
        </SimpleGrid>
        <InputFieldValidation
          label="نوع البناء"
          inputType="text"
          inputId="constructionType"
          inputName="constructionType"
          errors={errors}
          touched={touched}
          isRequired
          onChange={setFieldValue}
          values={formikValues}
          isDisabled={true}
        />
        <InputFieldValidation
          label="الموقع الإلكتروني"
          inputType="text"
          inputId="website"
          inputName="website"
          errors={errors}
          touched={touched}
          isRequired
          onChange={setFieldValue}
          values={formikValues}
          isDisabled={true}
        />
        <TextArea
          label="التفاصيل"
          inputId="details"
          inputName="details"
          placeholder="تفاصيل متطلبات العميل"
          errors={errors}
          touched={touched}
          isRequired
          values={formikValues}
          onChange={setFieldValue}
          onBlur={handleBlur}
          isDisabled={true}
        />
      </Box>

      <Box paddingY="10px" width="100%">
        {!!generalData?.attachmentURL && (
          <>
            <Text
              className="title_sm"
              display="flex"
              mb={2}
              mt={0}
              component="div"
              textTransform="uppercase"
              fontSize="12px"
            >
              الصورة الحالية
            </Text>
            <Box>
              {/* <a
                href={generalData.attachmentURL}
                 download
              >
                <Button>Download</Button>
              </a> */}

              <Button
                onClick={(e) => download(e, generalData.attachmentURL)}
              >
                 عرض الملف
              </Button>


              {/* <Image
                src={generalData.attachmentURL}
                width="200px"
                height="200px"
                mb="15px"
              /> */}
            </Box>
          </>
        )}
      </Box>

      <Divider orientation="horizontal" mt="20px" mb="30px" />
      <Flex justifyContent="flex-end" alignItems="center" width="100%">
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="إلغاء"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="إلغاء"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={`/ConsultaionFormHistory`}
            />
          )}

          {/* <MainButton title="Submit" reverseRow={true} disabledBtn={true} /> */}
        </Box>
      </Flex>
    </>
  );
};
