import * as Yup from "yup";

export const validationConsultaionFormForm = Yup.object().shape({
  firstName: Yup.string().required("هذه الخانة مطلوبة"),
  lastName: Yup.string().required("هذه الخانة مطلوبة"),
  mobile: Yup.string().required("هذه الخانة مطلوبة"),
  uses: Yup.string().required("هذه الخانة مطلوبة"),
  otherProduct: Yup.string().required("هذه الخانة مطلوبة"),
  product: Yup.string().required("هذه الخانة مطلوبة"),
  constructionType: Yup.string().required("هذه الخانة مطلوبة"),
  address: Yup.string().required("هذه الخانة مطلوبة"),
  area: Yup.string().required("هذه الخانة مطلوبة"),
  website: Yup.string().required("هذه الخانة مطلوبة"),
  email: Yup.string().required("هذه الخانة مطلوبة"),
  companyActivity: Yup.string().required("هذه الخانة مطلوبة"),
  company: Yup.string().required("هذه الخانة مطلوبة"),
  type: Yup.string().required("هذه الخانة مطلوبة"),
  title: Yup.string().required("هذه الخانة مطلوبة"),
  details: Yup.string().required("هذه الخانة مطلوبة"),
  // type: Yup.string().required("هذه الخانة مطلوبة"),
});
