import { useState, useEffect } from "react";
import {
  Box,
  Flex,
  HStack,
  TabPanel,
  TabPanels,
  Tabs,
  Image,
} from "@chakra-ui/react";

import { GetProductsAPI } from "../../../api/ProductsAPI";
import {
  DeleteProductByIdAPI,
  GetProductByIdAPI,
  UpdateProductAPI,
  AddProductAPI,
  GetProductsStandardSizeAPI,
  GetProductsThicknessAPI,
  GetProductsColorAPI,
  GetAllCountryAPI,
  GetProductsCountryAPI,
  GetProductCountryByIdAPI,
  GetProductTypeAPI,
} from "../../../api/ProductsAPI";
import { Formik, Form } from "formik";

import { ProductTableForm } from "./ProductTableForm";
import { MainButton } from "../../../components/button/MainButton";
import { TablePagination2 } from "../../../components/tablePagination/TablePagination2";

export const ProductsTable = ({
  onDelModalOpen,
  generalData,
  setSelectedID,
  seoRole,
}) => {
  let [currPagData, setCurrPagData] = useState({
    page: 1,
  });
  const [totalRowsTable, setTotalRowsTable] = useState(generalData.totalRows);
  const [initFormVal, setInitFormVal] = useState({});
  const [filterObj, setFilterObj] = useState({});

  const [dropdownObj, setDropdownObj] = useState([]);

  const handleSort = async () => {
    handleOnPageChange(currPagData.page);
    setCurrPagData({ ...currPagData });
  };
  //console.log("currPagData", currPagData);
  const [dataArray, setDataArray] = useState([]);
  const columns = [
    {
      name: "إسم المنتج",
      selector: (row) => row.title,
    },

    {
      name: "كود المنتج",
      selector: (row) => row.code,
    },

    {
      name: "الإجراء",
      selector: (row) => row.action,
      cell: (row) => (
        <Flex
          justifyContent="space-between"
          gap="15px"
          alignItems="center"
          flexDirection={["column", "column", "row"]}
        >
          <MainButton
            title="تعديل"
            btnStyle="transparent"
            fontColor="dark"
            marginRight="5"
            navLink={true}
            path={`/Product/${row.idCustom}`}
          />
          {!seoRole && (
            <MainButton
              title="حذف"
              btnStyle="transparent"
              fontColor="danger"
              marginRight="5"
              onPress={() => {
                setSelectedID(`delete-${row.idCustom}`);
                onDelModalOpen();
              }}
            />
          )}
        </Flex>
      ),
    },
  ];

  const handleOnSubmit = (formikValue) => {
    let newFormikValues = { ...formikValue };
    // console.log("submit values", newFormikValues);
    if (
      newFormikValues.most_selling == "" ||
      newFormikValues.most_selling == 0
    ) {
      delete newFormikValues.most_selling;
    } else {
      if (newFormikValues.most_selling) {
        newFormikValues.most_selling = 1;
      }
    }
    if (newFormikValues.new_arrival == "" || newFormikValues.new_arrival == 0) {
      delete newFormikValues.new_arrival;
    } else {
      if (newFormikValues.new_arrival) {
        newFormikValues.new_arrival = 1;
      }
    }
    if (newFormikValues.type_id === "") {
      delete newFormikValues.type_id;
    }
    if (newFormikValues.color_id === "") {
      delete newFormikValues.color_id;
    }
    if (newFormikValues.country_id === "") {
      delete newFormikValues.country_id;
    }
    // console.log("submit values after", newFormikValues);
    setCurrPagData({ ...currPagData, ...newFormikValues });

    handleOnPageChange(currPagData.page, newFormikValues);
    setFilterObj(newFormikValues);
  };

  const getDropdowns = () => {
    //colors
    let getColors = new Promise((resolve, reject) => {
      GetProductsColorAPI(100, 0, 1)
        .then((res) => {
          // console.log("res data dropdown", res);
          if (res.data.length > 0) {
            let colorOptions = [];
            res.data.forEach((item) => {
              colorOptions.push({
                value: item.id,
                label: item.color,
              });
            });

            resolve(colorOptions);
          }
          // console.log("operatorOptions2", operatorOptions2);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
          // setErrorMessage("Something went wrong");
        });
    });

    let getCountrys = new Promise((resolve, reject) => {
      GetProductsCountryAPI(300, 0, 1)
        .then((res) => {
          // console.log("res data dropdown", res);
          if (res.data.length > 0) {
            let countryOptions = [];
            let countryAllOptions = [];
            res.data.forEach((item) => {
              countryOptions.push({
                value: item.id,
                label: item.title,
              });
              countryAllOptions.push({
                value: item.id,
                label: item.title,
              });
            });

            resolve({ countryOp: countryOptions, countryAllOp: res.data });
          }
          // console.log("operatorOptions2", operatorOptions2);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
          // setErrorMessage("Something went wrong");
        });
    });
    let getProdType = new Promise((resolve, reject) => {
      GetProductTypeAPI()
        .then((res) => {
          // console.log("res data dropdown", res);
          if (res.length > 0) {
            let pTypeOptions = [];
            res.forEach((item) => {
              pTypeOptions.push({
                value: item.id,
                label: item.title,
              });
            });

            resolve({ pType: pTypeOptions, pTypeAll: res });
          }
          // console.log("operatorOptions2", operatorOptions2);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
          // setErrorMessage("Something went wrong");
        });
    });

    return Promise.all([getColors, getCountrys, getProdType]).then((values) => {
      setDropdownObj({
        colors: values[0],
        prodCountry: values[1].countryOp,
        Country: values[1].countryAllOp,
        prodType: values[2].pType,
        prodTypeAll: values[2].pTypeAll,
      });
      return {
        colors: values[0],
        prodCountry: values[1].countryOp,
        Country: values[1].countryAllOp,
        prodType: values[2].pType,
      };
    });
  };

  useEffect(() => {
    let data = [];
    if (generalData?.data?.length > 0 && !!generalData) {
      setTotalRowsTable(generalData.totalRows);
      generalData.data.forEach((item) => {
        let dataObj = {
          id: `${item.id + Math.floor(Math.random() * Date.now())}`,
          idCustom: `${item.id}`,
          title: `${item.title}`,
          code: `${item.code}`,
        };

        data.push(dataObj);
      });
      setDataArray(data);
    }
    setCurrPagData({
      page: 1,
    });

    getDropdowns();

    setInitFormVal({
      type_id: "",

      most_selling: "",
      new_arrival: "",
      country_id: "",
      color_id: "",
      // sizes: [""],
      // standardSize_id: [""],
      // thickness_id: [""],
    });
  }, []);

  const handleOnPageChange = (page, filters) => {
    //GetProductsAPI(page, sort, sortOrder)
    // GetProductsAPI({ offset: 0, limit: 10000000 }, 1, filters)
    //   .then((response) => {
    // setTotalRowsTable(response.data.length);
    GetProductsAPI({ offset: 0, limit: 10 }, page, filters)
      .then((res) => {
        let data = [];

        setTotalRowsTable(res.totalRows);
        // console.log("res.totalRows", res.totalRows);
        if (res.data.length > 0) {
          res.data.forEach((item) => {
            let dataObj = {
              id: `${item.id + Math.floor(Math.random() * Date.now())}`,
              idCustom: `${item.id}`,
              title: `${item.title}`,
              code: `${item.code}`,
            };

            data.push(dataObj);
          });
        }
        setDataArray(data);
      })
      .catch((err) => {
        console.log(err);
      });
    // })
    // .catch((err) => {
    //   console.log(err);
    // });
  };

  return (
    <>
      <HStack width="100%">
        <Tabs variant="unstyled" width="100%">
          <TabPanels>
            <TabPanel w="98%" px="0">
              <Box>
                <Box className="" pb={3}>
                  <Formik
                    initialValues={initFormVal}
                    enableReinitialize={true}
                    // validationSchema={validationProductForm}
                    validate={(values) => {
                      // if (disableSave) {
                      //   setDisableSave(false);
                      // }
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      setTimeout(() => {
                        alert(JSON.stringify(values, null, 2));
                        setSubmitting(false);
                      }, 400);
                    }}
                  >
                    {({
                      errors,
                      touched,
                      values,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                      isValid,
                      dirty,
                    }) => (
                      <Form className="form_wrapper">
                        <ProductTableForm
                          errors={errors}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          formikValues={values}
                          isValid={isValid}
                          dirty={dirty}
                          handleOnSubmit={handleOnSubmit}
                          setInitFormVal={setInitFormVal}
                          generalData={generalData}
                          dropdownObj={dropdownObj}
                        />
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Box>
              <TablePagination2
                data={dataArray}
                columns={columns}
                isSearchable={false}
                title="المنتجات"
                hasDelete={true}
                totalRows={totalRowsTable}
                pageSize={generalData.limit}
                GetDataAPI={GetProductsAPI}
                handleOnPageChange={handleOnPageChange}
                onSort={handleSort}
                setCurrPagData={setCurrPagData}
                currPagData={currPagData}
                haveCreateButton={!seoRole}
                path={`/Product`}
                buttonTitle="إضافة منتج"
                filterObj={filterObj}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </HStack>
    </>
  );
};
