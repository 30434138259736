import { useState, useEffect } from "react";
import {
  Box,
  Flex,
  HStack,
  TabPanel,
  TabPanels,
  Tabs,
  Image,
} from "@chakra-ui/react";

import { GetOrdersAPI } from "../../../api/ProductsAPI";
import {
  GetProductsStandardSizeAPI,
  GetProductsThicknessAPI,
  GetProductsColorAPI,
  GetProductsAPI,
} from "../../../api/ProductsAPI";
import { Formik, Form } from "formik";

import { OrderTableForm } from "./OrderTableForm";

import { MainButton } from "../../../components/button/MainButton";
import { TablePagination2 } from "../../../components/tablePagination/TablePagination2";

export const OrdersTable = ({ onDelModalOpen, generalData, setSelectedID }) => {
  let [currPagData, setCurrPagData] = useState({
    page: 1,
    // sortOrder: "DESC",
    // sort: "lastModifiedAt",
  });
  const [totalRowsTable, setTotalRowsTable] = useState(generalData.totalRows);
  const [initFormVal, setInitFormVal] = useState({});
  const [filterObj, setFilterObj] = useState({});

  const [dropdownObj, setDropdownObj] = useState([]);
  const handleSort = async () => {
    //console.log("handleSort", sort, sortOrder, currPagData);
    handleOnPageChange(currPagData.page /*,  sort, sortOrder */);
    setCurrPagData({ ...currPagData /* , sort: sort, sortOrder: sortOrder */ });
  };
  //console.log("currPagData", currPagData);
  const [dataArray, setDataArray] = useState([]);
  const columns = [
    {
      name: "الإسم",
      selector: (row) => row.name,
    },
    {
      name: "تاريخ الطلب",
      selector: (row) => row.orderDate,
    },

    {
      name: "الإجراء",
      selector: (row) => row.action,
      cell: (row) => (
        <Flex
          justifyContent="space-between"
          gap="15px"
          alignItems="center"
          flexDirection={["column", "column", "row"]}
        >
          <MainButton
            title="تفاصيل"
            btnStyle="transparent"
            fontColor="dark"
            marginRight="5"
            navLink={true}
            path={`/Order/${row.idCustom}`}
          />
          <MainButton
            title="حذف"
            btnStyle="transparent"
            fontColor="danger"
            marginRight="5"
            onPress={() => {
              setSelectedID(`delete-${row.idCustom}`);
              onDelModalOpen();
            }}
          />
        </Flex>
      ),
    },
  ];

  const handleOnSubmit = (formikValue) => {
    let newFormikValues = { ...formikValue };

    if (newFormikValues.order_id === "") {
      delete newFormikValues.order_id;
    }
    if (newFormikValues.product_id === "") {
      delete newFormikValues.product_id;
    }
    if (newFormikValues.order_date === "" || !!!newFormikValues.order_date) {
      delete newFormikValues.order_date;
    } else {
      let newOrderDate = `${newFormikValues.order_date}`.split("/");
      if (newOrderDate[1] < 10) newOrderDate[1] = "0" + newOrderDate[1];
      if (newOrderDate[0] < 10) newOrderDate[0] = "0" + newOrderDate[0];
      newFormikValues.order_date =
        newOrderDate[2] + "-" + newOrderDate[1] + "-" + newOrderDate[0];
    }

    setCurrPagData({ ...currPagData, ...newFormikValues });

    handleOnPageChange(currPagData.page, newFormikValues);
    setFilterObj(newFormikValues);
  };

  const getDropdowns = () => {
    //colors

    let getProducts = new Promise((resolve, reject) => {
      GetProductsAPI({ offset: 0, limit: 10000000 }, 1)
        .then((res) => {
          // console.log("res data dropdown", res);
          if (res.data.length > 0) {
            let productsOptions = [];
            res.data.forEach((item) => {
              productsOptions.push({
                value: item.id,
                label: item.title,
              });
            });

            resolve(productsOptions);
          }
          // console.log("operatorOptions2", operatorOptions2);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
          // setErrorMessage("هناك خطأ ما");
        });
    });
    let getOrder = new Promise((resolve, reject) => {
      GetOrdersAPI({ offset: 0, limit: 10000000 }, 1)
        .then((res) => {
          // console.log("res data dropdown", res);
          if (res.data.length > 0) {
            let OrderOptions = [];
            res.data.forEach((item) => {
              OrderOptions.push({
                value: item.id,
                label: `${item.firstName} ${item.lastName}`,
              });
            });

            resolve(OrderOptions);
          }
          // console.log("operatorOptions2", operatorOptions2);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
          // setErrorMessage("هناك خطأ ما");
        });
    });

    return Promise.all([getProducts, getOrder]).then((values) => {
      // console.log("vaaaaaal drop", values);
      setDropdownObj({
        products: values[0],
        orders: values[1],
      });
      return {
        products: values[0],
        orders: values[1],
      };
    });
  };

  useEffect(() => {
    let data = [];
    if (generalData?.data?.length > 0 && !!generalData) {
      setTotalRowsTable(generalData.totalRows);
      generalData.data.forEach((item) => {
        let dataObj = {
          id: `${item.id + Math.floor(Math.random() * Date.now())}`,
          idCustom: `${item.id}`,
          orderDate: `${item.order_date}`.split("T")[0],
          name: `${item.firstName} ${item.lastName}`,
        };

        data.push(dataObj);
      });
      setDataArray(data);
    }
    setCurrPagData({
      page: 1,
    });

    getDropdowns();

    setInitFormVal({
      order_id: "",
      product_id: "",
      order_date: "",
    });
  }, []);

  const handleOnPageChange = (
    page,
    filters /* , sort = "lastModifiedAt", sortOrder */
  ) => {
    //GetOrdersAPI(page, sort, sortOrder)
    GetOrdersAPI({ offset: 0, limit: 10 }, page, filters)
      .then((res) => {
        let data = [];

        setTotalRowsTable(res.totalRows);
        if (res.data.length > 0) {
          res.data.forEach((item) => {
            let dataObj = {
              id: `${item.id + Math.floor(Math.random() * Date.now())}`,
              idCustom: `${item.id}`,
              orderDate: `${item.order_date}`.split("T")[0],
              name: `${item.firstName} ${item.lastName}`,
            };

            data.push(dataObj);
          });
        }
        setDataArray(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <HStack width="100%">
        <Tabs variant="unstyled" width="100%">
          <TabPanels>
            <TabPanel w="98%" px="0">
              <Box>
                <Box className="" pb={3}>
                  <Formik
                    initialValues={initFormVal}
                    enableReinitialize={true}
                    // validationSchema={validationProductForm}
                    validate={(values) => {
                      // if (disableSave) {
                      //   setDisableSave(false);
                      // }
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      setTimeout(() => {
                        alert(JSON.stringify(values, null, 2));
                        setSubmitting(false);
                      }, 400);
                    }}
                  >
                    {({
                      errors,
                      touched,
                      values,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                      isValid,
                      dirty,
                    }) => (
                      <Form className="form_wrapper">
                        <OrderTableForm
                          errors={errors}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          formikValues={values}
                          isValid={isValid}
                          dirty={dirty}
                          handleOnSubmit={handleOnSubmit}
                          setInitFormVal={setInitFormVal}
                          generalData={generalData}
                          dropdownObj={dropdownObj}
                        />
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Box>
              <TablePagination2
                data={dataArray}
                columns={columns}
                isSearchable={false}
                title="طلبات المنتجات"
                hasDelete={true}
                totalRows={totalRowsTable}
                pageSize={generalData.limit}
                GetDataAPI={GetOrdersAPI}
                handleOnPageChange={handleOnPageChange}
                onSort={handleSort}
                setCurrPagData={setCurrPagData}
                currPagData={currPagData}
                filterObj={filterObj}
                // haveCreateButton={true}
                // path={`/Order`}
                // buttonTitle="Create Order"
              />
              {/* <TablePagination
                data={dataArray}
                columns={columns}
                isSearchable={false}
                title="Order"
                hasDelete={true}
                totalRows={generalData.totalRows}
                pageSize={generalData.pageSize}
                GetDataAPI={GetOrdersAPI}
                handleOnPageChange={handleOnPageChange}
                onSort={handleSort}
                setCurrPagData={setCurrPagData}
                currPagData={currPagData}
                haveCreateButton={true}
                path={`/Order`}
                buttonTitle="Create Order"
              /> */}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </HStack>
    </>
  );
};
