import axiosClient from "./index";
import { AxiosClientMultipart, AxiosClientPost } from "./index";
import Cookies from "js-cookie";

//MEDIA NEWS & BLOG
export const GetMediaAPI = async (id, limit = 10, offset = 0, page = 1) => {
  offset = (page - 1) * limit;
  const URL = `/mediaCenter/lang?lang=ar&limit=${limit}&offset=${offset}&mediaCategoryID=${id}`;
  // const URL = `/mediaCenter?mediaCategoryID=${id}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetMediaByIdAPI = async (id) => {
  const URL = `/mediaCenter/${id}`;
  try {
    const response = await axiosClient.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddMediaAPI = async (payload) => {
  const URL = "/mediaCenter";
  try {
    const response = await AxiosClientPost.post(URL, {
      ...payload,
      //auth: Cookies.get("token"),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const UpdateMediaAPI = async (id, payload) => {
  const URL = `/mediaCenter/${id}`;
  try {
    const response = await AxiosClientPost.put(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DeleteMediaByIdAPI = async (id) => {
  const URL = `/mediaCenter/${id}`;
  try {
    const response = await AxiosClientPost.delete(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//PHOTO GALLERY
export const GetPhotoGalleryAPI = async (limit = 10, offset = 0, page = 1) => {
  offset = (page - 1) * limit;
  const URL = `/photoGallery/lang?lang=ar&limit=${limit}&offset=${offset}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetPhotoGalleryByIdAPI = async (id) => {
  const URL = `/photoGallery/${id}`;
  try {
    const response = await axiosClient.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddPhotoGalleryAPI = async (payload) => {
  const URL = "/photoGallery";
  try {
    const response = await AxiosClientPost.post(URL, {
      ...payload,
      //auth: Cookies.get("token"),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const UpdatePhotoGalleryAPI = async (id, payload) => {
  const URL = `/photoGallery/${id}`;
  try {
    const response = await AxiosClientPost.put(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DeletePhotoGalleryByIdAPI = async (id) => {
  const URL = `/photoGallery/${id}`;
  try {
    const response = await AxiosClientPost.delete(URL, {
      /* params: {
          token: Cookies.get("token"),
        }, */
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
