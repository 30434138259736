import * as Yup from "yup";

export const validationContactDetailsForm = Yup.object().shape({
  address: Yup.string().required("هذه الخانة مطلوبة"),
  tel: Yup.string().required("هذه الخانة مطلوبة"),
  email: Yup.string().email("البريد الإلكتروني غير صالح"),
  // mapLink: Yup.string().required("This field is required"),
  // facebook: Yup.string()
  //   .matches(
  //     /\b(?:(?:https?|ftp):\/\/|www\.)[-a-z0-9+&@#\/%?=~_|!:,.;]*[-a-z0-9+&@#\/%=~_|]/i,
  //     "Enter correct url!"
  //   )
  //   .required("Please enter website"),
  // twitter: Yup.string()
  //   .matches(
  //     /\b(?:(?:https?|ftp):\/\/|www\.)[-a-z0-9+&@#\/%?=~_|!:,.;]*[-a-z0-9+&@#\/%=~_|]/i,
  //     "Enter correct url!"
  //   )
  //   .required("Please enter website"),
  // instagram: Yup.string()
  //   .matches(
  //     /\b(?:(?:https?|ftp):\/\/|www\.)[-a-z0-9+&@#\/%?=~_|!:,.;]*[-a-z0-9+&@#\/%=~_|]/i,
  //     "Enter correct url!"
  //   )
  //   .required("Please enter website"),
  // linkedin: Yup.string()
  //   .matches(
  //     /\b(?:(?:https?|ftp):\/\/|www\.)[-a-z0-9+&@#\/%?=~_|!:,.;]*[-a-z0-9+&@#\/%=~_|]/i,
  //     "Enter correct url!"
  //   )
  //   .required("Please enter website"),
});
