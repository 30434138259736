import React, { useState, useEffect } from "react";

import {
  GetPhotoGalleryByIdAPI,
  UpdatePhotoGalleryAPI,
  AddPhotoGalleryAPI,
} from "../../../api/MediaAPI";

import { useLocation, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { Box, useDisclosure } from "@chakra-ui/react";

import { activeMenuItem } from "../../../utils/General";
import { ModalAlert } from "../../../components/modalAlert/ModalAlert";
import { MainTitle } from "../../../components/mainTitle/MainTitle";
import { MainBox } from "../../../components/mainBox/MainBox";
import { PhotoGalleryForm } from "./PhotoGalleryForm";
import { validationPhotoGalleryForm } from "./validationPhotoGalleryForm";
import history from "../../../history";

import checked from "../../../assets/images/iconsSvg/checked.svg";
import infoCircle from "../../../assets/images/iconsSvg/infoCircle.svg";

export const PhotoGallery = () => {
  const activeMenu = activeMenuItem();
  const { id } = useParams();
  let location = useLocation();
  const [initFormVal, setInitFormVal] = useState({});
  const [generalData, setGeneralData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [disableSave, setDisableSave] = useState(true);
  const [dismissSave, setDismissSave] = useState(true);
  const [imageObj, setImageObj] = useState({});

  let submitModalTitle = id ? "تم التحديث بنجاح" : "تم الإضافة بنجاح";

  const {
    isOpen: isCancelOpen,
    onOpen: onCancelOpen,
    onClose: onCancelClose,
  } = useDisclosure();
  const {
    isOpen: isSubmitOpen,
    onOpen: onSubmitOpen,
    onClose: onSubmitClose,
  } = useDisclosure();

  const {
    isOpen: isUploadOpen,
    onOpen: onUploadOpen,
    onClose: onUploadClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();

  const handleOnSubmit = (formikValue) => {
    if (!!id) {
      const submitValues = {
        // ...formikValue,
        title: formikValue.title,
        titleEn: formikValue.titleEn,
        date: formikValue.date,
        // alt: formikValue.alt,
        image: formikValue.image,
      };
      UpdatePhotoGalleryAPI(id, submitValues)
        .then((res) => {
          setDisableSave(true);
          setErrorMessage("");
          onSubmitOpen();
          if (res.success === true) {
            setDisableSave(true);
            setErrorMessage("");
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(err?.response?.data?.message || "هناك خطأ ما");
          onErrorOpen();
        });
    } else {
      const submitValues = {
        // ...formikValue,
        title: formikValue.title,
        titleEn: formikValue.titleEn,
        date: formikValue.date,
        // alt: formikValue.alt,
        image: formikValue.image,
      };
      AddPhotoGalleryAPI(submitValues)
        .then((res) => {
          setDisableSave(true);
          setErrorMessage("");
          onSubmitOpen();
          if (res.success === true) {
            setDisableSave(true);
            setErrorMessage("");
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(err?.response?.data?.message || "هناك خطأ ما");
          console.log("err", err);
          onErrorOpen();
        });
    }
  };

  useEffect(() => {
    if (!!id) {
      setErrorMessage("");
      GetPhotoGalleryByIdAPI(id)
        .then((res) => {
          setInitFormVal({
            title: res.title,
            titleEn: res.titleEn,
            date: res.date,
            alt: res.alt,
            altEn: "",
            altAr: "",
            altEn2: res?.altEn,
            image: res.image,
            file: "",
          });
          setGeneralData(res);
        })
        .catch((err) => {
          setErrorMessage("هناك خطأ ما");
          onErrorOpen();
        });
    } else {
      setInitFormVal({
        title: "",
        titleEn: "",
        date: "",
        altEn: "",
        altAr: "",
        altEn2: "",
        image: "",
        alt: "",
        file: "",
      });
    }
  }, []);
  useEffect(() => {
    let unblock;
    if (!disableSave && dismissSave) {
      unblock = history.block((tx) => {
        let url = tx.location.pathname;
        if (window.confirm(`هل أنت متأكد أنك تريد تجاهل التغييرات؟`)) {
          unblock();

          tx.retry();
        }
      });
    }

    return () => {
      if (typeof unblock === "function") {
        unblock();
      }
    };
  }, [disableSave, dismissSave]);

  return (
    <div className="Assumptions">
      <>
        <MainBox px={0}>
          {id === undefined ? (
            <MainTitle title="إضافة الى معرض الصور" bBottom={true} px={7} />
          ) : (
            <MainTitle title="تعديل معرض الصور" bBottom={true} px={7} />
          )}
          <Box className="table_content" pt={6} pb={6}>
            <Formik
              initialValues={initFormVal}
              enableReinitialize={true}
              validationSchema={validationPhotoGalleryForm}
              validate={(values) => {
                if (disableSave) {
                  setDisableSave(false);
                }
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  alert(JSON.stringify(values, null, 2));
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({
                errors,
                touched,
                values,
                handleChange,
                handleBlur,
                setFieldValue,
                isValid,
                dirty,
              }) => (
                <Form className="form_wrapper">
                  <PhotoGalleryForm
                    onCancelOpen={onCancelOpen}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    formikValues={values}
                    isValid={isValid}
                    dirty={dirty}
                    disableSave={disableSave}
                    setDisableSave={setDisableSave}
                    dismissSave={dismissSave}
                    setDismissSave={setDismissSave}
                    onSubmitOpen={onSubmitOpen}
                    onUploadOpen={onUploadOpen}
                    handleOnSubmit={handleOnSubmit}
                    setInitFormVal={setInitFormVal}
                    generalData={generalData}
                    isUploadOpen={isUploadOpen}
                    onUploadClose={onUploadClose}
                    onErrorOpen={onErrorOpen}
                    setErrorMessage={setErrorMessage}
                    setImageObj={setImageObj}
                  />

                  <ModalAlert
                    isOpen={isCancelOpen}
                    onClose={onCancelClose}
                    title="هل أنت متأكد أنك تريد تجاهل التغييرات؟"
                    icon={infoCircle}
                    hasDesc={false}
                    confirmationModal={true}
                    path={`/PhotoGalleryHistory`}
                    setDismissSave={() => setDismissSave(true)}
                  />
                </Form>
              )}
            </Formik>
          </Box>
        </MainBox>

        <ModalAlert
          isOpen={isErrorOpen}
          onClose={onErrorClose}
          title={errorMessage}
          icon={infoCircle}
          hasDesc={false}
          confirmationModal={false}
        />

        <ModalAlert
          isOpen={isSubmitOpen}
          onClose={onSubmitClose}
          title={submitModalTitle}
          btnWidthFull={true}
          icon={checked}
          hasDesc={false}
          disableClose={true}
          path={`/PhotoGalleryHistory`}
        />
      </>
    </div>
  );
};
