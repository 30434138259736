import axiosClient from "./index";
import { AxiosClientMultipart, AxiosClientPost, AxiosClientGet } from "./index";
import Cookies from "js-cookie";

//BANNERS
export const GetBannersAPI = async (limit = 10, offset = 0) => {
  // const URL = `/banners`;
  const URL = `/banners/lang?lang=ar&limit=${limit}&offset=${offset}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetBannerByIdAPI = async (id) => {
  const URL = `/banners/${id}`;
  try {
    const response = await axiosClient.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddBannerAPI = async (payload) => {
  const URL = "/banners";
  try {
    const response = await AxiosClientPost.post(URL, {
      ...payload,
      //auth: Cookies.get("token"),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const UpdateBannerAPI = async (id, payload) => {
  const URL = `/banners/${id}`;
  try {
    const response = await AxiosClientPost.put(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DeleteBannerByIdAPI = async (id) => {
  const URL = `/banners/${id}`;
  try {
    const response = await AxiosClientPost.delete(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//UNIQUE ROCK
export const GetUniqueRocksAPI = async (limit = 10, offset = 0, page = 1) => {
  offset = (page - 1) * limit;
  const URL = `/uniqueRock/lang?lang=ar&limit=${limit}&offset=${offset}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetUniqueRockByIdAPI = async (id) => {
  const URL = `/uniqueRock/${id}`;
  try {
    const response = await axiosClient.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddUniqueRockAPI = async (payload) => {
  const URL = "/uniqueRock";
  try {
    const response = await AxiosClientPost.post(URL, {
      ...payload,
      //auth: Cookies.get("token"),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const UpdateUniqueRockAPI = async (id, payload) => {
  const URL = `/uniqueRock/${id}`;
  try {
    const response = await AxiosClientPost.put(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DeleteUniqueRockByIdAPI = async (id) => {
  const URL = `/uniqueRock/${id}`;
  try {
    const response = await AxiosClientPost.delete(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//UNIQUE ROCK ORDER
export const GetUniqueRockOrdersAPI = async (
  limit = 10,
  offset = 0,
  page = 1
) => {
  offset = (page - 1) * limit;
  const URL = `/uniqueRockOrder/lang?lang=ar&limit=${limit}&offset=${offset}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetUniqueRockOrderByIdAPI = async (id) => {
  const URL = `/uniqueRockOrder/${id}`;
  try {
    const response = await AxiosClientGet.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DeleteUniqueRockOrderByIdAPI = async (id) => {
  const URL = `/uniqueRockOrder/${id}`;
  try {
    const response = await AxiosClientPost.delete(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddUniqueRockOrderAPI = async (payload) => {
  const URL = "/uniqueRockOrder";
  try {
    const response = await AxiosClientPost.post(URL, {
      ...payload,
      //auth: Cookies.get("token"),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//CONSULTATION HOME BANNER
export const GetConsultationHomeBanner = async () => {
  const URL = `/consultationHomeBanner/lang?offset=0&limit=10`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const UpdateConsultationHomeBannerAPI = async (payload) => {
  const URL = `/consultationHomeBanner/1`;
  try {
    const response = await AxiosClientPost.put(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//UPLOAD
export const UploadAPI = async (payload) => {
  const URL = "/images";
  try {
    const response = await AxiosClientMultipart.post(URL, {
      /* headers: {
        "Content-Type": "multipart/form-data",
      }, */
      ...payload,
      token: Cookies.get("token"),
      //"Content-Type": "multipart/form-data",
      /* headers: {
        "   -Type": "multipart/form-data",
      }, */
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
