import React, { useState, useEffect, useCallback } from "react";

import { Box, Text, Center, Spinner, useDisclosure } from "@chakra-ui/react";

import {
  GetUniqueRockOrdersAPI,
  DeleteUniqueRockOrderByIdAPI,
} from "../../../api/HomeAPI";

import { MainTitle } from "../../../components/mainTitle/MainTitle";
import { MainBox } from "../../../components/mainBox/MainBox";
import { MainButton } from "../../../components/button/MainButton";
import { UniqueRockOrderTable } from "./UniqueRockOrderTable";
import { ModalAlert } from "../../../components/modalAlert/ModalAlert";

import doc from "../../../assets/images/iconsSvg/doc.svg";
import plus from "../../../assets/images/iconsSvg/plus.svg";
import infoCircle from "../../../assets/images/iconsSvg/infoCircle.svg";

export const UniqueRockOrderHistory = () => {
  const {
    isOpen: isDelModalOpen,
    onOpen: onDelModalOpen,
    onClose: onDelModalClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();
  //const { succ } = useParams();
  const [formData, setFormData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [selectedID, setSelectedID] = useState("-1");

  const loadTableData = useCallback(() => {
    document.getElementById("main_content").scrollTo(0, 0);

    GetUniqueRockOrdersAPI()
      .then((res) => {
        setFormData(res);
        setIsLoading(false);
        //return res;
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const renderRowsAfterDeletion = (id) => {
    if (!id.startsWith("delete-")) {
      return;
    }

    DeleteUniqueRockOrderByIdAPI(id.split("-")[1])
      .then((res) => {
        setErrorMessage("");
        setIsLoading(true);
        loadTableData();

        return res;
      })
      .catch((err) => {
        setErrorMessage(err?.response?.data?.message || "هناك خطأ ما");
        onErrorOpen();
      });
  };

  useEffect(() => {
    loadTableData();
  }, [loadTableData]);

  return (
    <>
      {isLoading ? (
        <>
          <MainTitle title="طلبات الصخرة النادرة" />
          <MainBox>
            <Box paddingTop={12} paddingBottom={12}>
              <Center>
                <Spinner />
              </Center>
            </Box>
          </MainBox>
        </>
      ) : (
        <>
          {formData.data?.length <= 0 ? (
            <>
              <MainTitle title="طلبات الصخرة النادرة" />
              <MainBox>
                <Box className="table_content" pt={6} pb={6}>
                  <Center flexDirection="column">
                    <img src={doc} alt="icon" />
                    <Box mt={3} mb={5}>
                      <Text className="title_sm">
                      لا يوجد إدخالات حالياً 
                      </Text>
                    </Box>
                    <MainButton
                      title="إضافة"
                      marginRight="1"
                      icon={plus}
                      navLink={true}
                      path="/UniqueRockOrder"
                    />
                  </Center>
                </Box>
              </MainBox>
            </>
          ) : (
            <>
              <UniqueRockOrderTable
                formData={formData}
                setSelectedID={setSelectedID}
                onDelModalOpen={onDelModalOpen}
              />
            </>
          )}
        </>
      )}

      <ModalAlert
        isOpen={isDelModalOpen}
        onClose={onDelModalClose}
        title="هل أنت متأكد أنك تريد الحذف؟"
        icon={infoCircle}
        hasDesc={false}
        confirmationModal={true}
        haveSaveDraft={true}
        isDelete={true}
        selectedID={selectedID}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        renderRowsAfterDeletion={renderRowsAfterDeletion}
      />
      <ModalAlert
        isOpen={isErrorOpen}
        onClose={onErrorClose}
        title={errorMessage}
        icon={infoCircle}
        hasDesc={false}
        confirmationModal={false}
      />
    </>
  );
};
