import React from "react";

import { Box, Divider, Flex, Text, Image } from "@chakra-ui/react";

import { InputFieldValidation } from "../../../components/inputField/InputFieldValidation";
import { MainButton } from "../../../components/button/MainButton";
import { TextArea } from "../../../components/textArea/TextArea";

export const AvailableJobForm = ({
  errors,
  touched,
  setFieldValue,
  handleBlur,
  onCancelOpen,
  formikValues,
  dirty,
  setDisableSave,
  disableSave,
  dismissSave,
  setDismissSave,
  handleOnSubmit,
  onUploadOpen,
  generalData,
  isValid,
}) => {
  return (
    <>
      <Box
        maxW={["100%", "100%", "60%"]}
        width={["100%", "100%", "60%"]}
        alignSelf="flex-start"
      >
        <InputFieldValidation
          label="العنوان"
          inputType="text"
          inputId="title"
          inputName="title"
          errors={errors}
          touched={touched}
          isRequired
          onChange={setFieldValue}
          values={formikValues}
        />
        <InputFieldValidation
          label="العنوان الإنجليزي"
          inputType="text"
          inputId="titleEn"
          inputName="titleEn"
          errors={errors}
          touched={touched}
          isRequired
          onChange={setFieldValue}
          values={formikValues}
        />
        <TextArea
          label="النص"
          inputId="text"
          inputName="text"
          placeholder="Add a text"
          errors={errors}
          touched={touched}
          isRequired
          values={formikValues}
          onChange={setFieldValue}
          onBlur={handleBlur}
        />
        <TextArea
          label="النص الإنجليزي"
          inputId="textEn"
          inputName="textEn"
          placeholder="Add a text en"
          errors={errors}
          touched={touched}
          isRequired
          values={formikValues}
          onChange={setFieldValue}
          onBlur={handleBlur}
        />
      </Box>

      <Divider orientation="horizontal" mt="20px" mb="30px" />
      <Flex justifyContent="flex-end" alignItems="center" width="100%">
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="إلغاء"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="إلغاء"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={`/AvailableJobHistory`}
            />
          )}

          <MainButton
            title="حفظ"
            reverseRow={true}
            disabledBtn={isValid && dirty ? false : true}
            onPress={() => {
              handleOnSubmit(formikValues);
            }}
          />
        </Box>
      </Flex>
    </>
  );
};
