import React, { useState, useRef, useEffect } from "react";

import { NavLink } from "react-router-dom";
import { Accordion, Box, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";

import { DropdownList } from "../../components/dropdownList/DropdownList";
import { activeMenuItem } from "../../utils/General";

import styles from "./sidebar.module.scss";
import { parseToken } from "../../utils/Auth";

export const Sidebar = ({ user, isOpen, setIsOpen }) => {
  const activeMenu = activeMenuItem();
  //let userRole = user.roles?.[0] || "";
  // console.log("uss", user, parseToken());
  // let userRole =
  //     user?.roles?.[0] === "ROLE_MODERATOR"
  //     ? "ROLE_MODERATOR"
  //     : "admin";
  // let userRole = "admin";
  let userRole =
    parseToken()?.role === "ROLE_MODERATOR" ? "ROLE_MODERATOR" : "admin";

  //const [isOpen, setIsOpen] = useState(true);
  const ref = useRef(null);
  const sideRef = useRef(null);

  const rotateTransition = {
    type: "spring",
    delay: isOpen ? 0 : 0.1,
    bounce: 0.1,
    duration: 0.3,
  };

  const yTransition = {
    type: "spring",
    delay: !isOpen ? 0 : 0.1,
    bounce: 0.1,
    duration: 0.3,
  };

  // close sidebar on browser previous button
  // window.addEventListener('popstate', (event) => {
  //   setIsOpen(true)
  // });

  const handleSidebar = () => {
    //console.log("open");
    setIsOpen(!isOpen);
  };
  const handleAccordBtn = (event) => {
    if (isOpen) {
      event.preventDefault();
      setIsOpen(!isOpen);
    }
  };

  const handleClickOutside = (event) => {
    if (sideRef.current && !sideRef.current.contains(event.target)) {
      setIsOpen(true);
    }
  };

  //on click outside the sidebar this will handle close the side bar
  /* useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []); */

  /* useEffect(() => {
    console.log(
      "mas1",
      window.location.href.toLowerCase().includes("masterdata"),
      !document.querySelector(".masterData").classList.contains("masterActive")
    );
    if (window.location.href.toLowerCase().includes("masterdata")) {
      document
        .querySelector(".masterData")
        .classList.remove(`${styles.active}`);
    }
  }); */

  return (
    <div ref={sideRef}>
      <Box
        bg="#162640"
        px="24px"
        pt="28px"
        pb="60px"
        className={
          isOpen
            ? `${styles.wrapper} ${styles.closed} closed`
            : `${styles.wrapper} ${styles.open}`
        }
        //sidebar open on hover
        /*  onMouseEnter={(e) => {
          //console.log(e.target.classList.contains("btn_burger"));
          if (!e.target.classList.contains("btn_burger")) {
            if (isOpen) {
              setTimeout(() => {
                setIsOpen(!isOpen);
              }, 50);
            }
          }
        }}
        onMouseLeave={(e) => {
          if (!e.target.classList.contains("btn_burger")) {
            if (!isOpen) {
              setTimeout(() => {
                setIsOpen(!isOpen);
              }, 50);
            }
          }
        }} */
      >
        <Box className={`${styles.btn_burger_wrapper} btn_burger`}>
          <button
            onClick={() => handleSidebar()}
            className={`${styles.centerBurger}`}
          >
            <Box className={styles.content}>
              {/*  <button className="container" onClick={isOpen ? closeMenu : openMenu}> */}
              <motion.div
                className={`${styles.topWrapper}`}
                animate={{ rotate: isOpen ? 0 : 45 }}
                transition={rotateTransition}
              >
                <motion.div
                  className={`${styles.topBread}`}
                  animate={{ y: isOpen ? 0 : 5 }}
                  transition={yTransition}
                />
              </motion.div>
              <motion.div
                className={`${styles.centerWrapper}`}
                animate={{ rotate: isOpen ? 0 : -45 }}
                transition={rotateTransition}
              >
                <motion.div
                  className={`${styles.centerBread}`}
                  transition={yTransition}
                />
              </motion.div>
              <motion.div
                className={`${styles.bottomWrapper}`}
                animate={{ rotate: isOpen ? 0 : -45 }}
                transition={rotateTransition}
              >
                <motion.div
                  className={`${styles.bottomBread}`}
                  animate={{ y: isOpen ? 0 : -5 }}
                  transition={yTransition}
                />
              </motion.div>
            </Box>
            {/*  </button> */}
            {/* <img src={burgerMenu} width="32px" alt="burger menu icon" /> */}
          </button>
        </Box>

        <Box className={styles.content} mt="80px">
          <Box my="5px">
            <NavLink
              onClick={(event) => {
                handleAccordBtn(event);
                //close side bar on render another component
                // handleSidebar();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
              className={({ isActive }) =>
                `${styles.nav_link}` + (!isActive ? "" : ` ${styles.active}`)
              }
              end
              to="/"
            >
              <Box
                p="12px"
                py="13px"
                ref={ref}
                className={`${styles.list_items}`}
              >
                <Box height="25px">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={`${styles.iconPos}`}
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8 5H6C5.44772 5 5 5.44772 5 6V8C5 8.55228 5.44772 9 6 9H8C8.55228 9 9 8.55228 9 8V6C9 5.44772 8.55228 5 8 5ZM6 3C4.34315 3 3 4.34315 3 6V8C3 9.65685 4.34315 11 6 11H8C9.65685 11 11 9.65685 11 8V6C11 4.34315 9.65685 3 8 3H6Z"
                      fill="#6F767E"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8 15H6C5.44772 15 5 15.4477 5 16V18C5 18.5523 5.44772 19 6 19H8C8.55228 19 9 18.5523 9 18V16C9 15.4477 8.55228 15 8 15ZM6 13C4.34315 13 3 14.3431 3 16V18C3 19.6569 4.34315 21 6 21H8C9.65685 21 11 19.6569 11 18V16C11 14.3431 9.65685 13 8 13H6Z"
                      fill="#6F767E"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M18 5H16C15.4477 5 15 5.44772 15 6V8C15 8.55228 15.4477 9 16 9H18C18.5523 9 19 8.55228 19 8V6C19 5.44772 18.5523 5 18 5ZM16 3C14.3431 3 13 4.34315 13 6V8C13 9.65685 14.3431 11 16 11H18C19.6569 11 21 9.65685 21 8V6C21 4.34315 19.6569 3 18 3H16Z"
                      fill="#6F767E"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M18 15H16C15.4477 15 15 15.4477 15 16V18C15 18.5523 15.4477 19 16 19H18C18.5523 19 19 18.5523 19 18V16C19 15.4477 18.5523 15 18 15ZM16 13C14.3431 13 13 14.3431 13 16V18C13 19.6569 14.3431 21 16 21H18C19.6569 21 21 19.6569 21 18V16C21 14.3431 19.6569 13 18 13H16Z"
                      fill="#6F767E"
                    />
                  </svg>
                </Box>

                <Text fontSize="15px" color="#1D1F22" pr="15px">
                  لوحة التحكم
                </Text>
              </Box>
            </NavLink>
          </Box>
          <Box my="5px">
            <NavLink
              onClick={(event) => {
                handleAccordBtn(event);
                //close side bar on render another component
                // handleSidebar();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
              className={({ isActive }) =>
                `${styles.nav_link}` +
                (!isActive ? " meta " : ` meta metaActive ${styles.active}`)
              }
              end
              to="/MetaHistory"
            >
              <Box
                p="12px"
                py="13px"
                ref={ref}
                className={`${styles.list_items}`}
              >
                <Box height="25px">
                  <svg
                    className={`${styles.iconPos}`}
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                    fill="none"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M4.95312427,14.3025791 L3.04687573,13.6974209 C4.65100965,8.64439903 7.67317997,6 12,6 C16.32682,6 19.3489903,8.64439903 20.9531243,13.6974209 L19.0468757,14.3025791 C17.6880467,10.0222676 15.3768837,8 12,8 C8.62311633,8 6.31195331,10.0222676 4.95312427,14.3025791 Z M12,8 C12.5522847,8 13,7.55228475 13,7 C13,6.44771525 12.5522847,6 12,6 C11.4477153,6 11,6.44771525 11,7 C11,7.55228475 11.4477153,8 12,8 Z"
                        fill="#000000"
                        fillRule="nonzero"
                        opacity="0.3"
                      />
                      <path
                        d="M5.73243561,6 L9.17070571,6 C9.58254212,4.83480763 10.6937812,4 12,4 C13.3062188,4 14.4174579,4.83480763 14.8292943,6 L18.2675644,6 C18.6133738,5.40219863 19.2597176,5 20,5 C21.1045695,5 22,5.8954305 22,7 C22,8.1045695 21.1045695,9 20,9 C19.2597176,9 18.6133738,8.59780137 18.2675644,8 L14.8292943,8 C14.4174579,9.16519237 13.3062188,10 12,10 C10.6937812,10 9.58254212,9.16519237 9.17070571,8 L5.73243561,8 C5.38662619,8.59780137 4.74028236,9 4,9 C2.8954305,9 2,8.1045695 2,7 C2,5.8954305 2.8954305,5 4,5 C4.74028236,5 5.38662619,5.40219863 5.73243561,6 Z M12,8 C12.5522847,8 13,7.55228475 13,7 C13,6.44771525 12.5522847,6 12,6 C11.4477153,6 11,6.44771525 11,7 C11,7.55228475 11.4477153,8 12,8 Z M4,19 C2.34314575,19 1,17.6568542 1,16 C1,14.3431458 2.34314575,13 4,13 C5.65685425,13 7,14.3431458 7,16 C7,17.6568542 5.65685425,19 4,19 Z M4,17 C4.55228475,17 5,16.5522847 5,16 C5,15.4477153 4.55228475,15 4,15 C3.44771525,15 3,15.4477153 3,16 C3,16.5522847 3.44771525,17 4,17 Z M20,19 C18.3431458,19 17,17.6568542 17,16 C17,14.3431458 18.3431458,13 20,13 C21.6568542,13 23,14.3431458 23,16 C23,17.6568542 21.6568542,19 20,19 Z M20,17 C20.5522847,17 21,16.5522847 21,16 C21,15.4477153 20.5522847,15 20,15 C19.4477153,15 19,15.4477153 19,16 C19,16.5522847 19.4477153,17 20,17 Z"
                        fill="#000000"
                      />
                    </g>
                  </svg>
                </Box>

                <Text fontSize="15px" color="#1D1F22" pr="15px">
                  Meta
                </Text>
              </Box>
            </NavLink>
          </Box>
          <div>
            <Accordion /* defaultIndex={[0]} */ allowToggle>
              {/* {userRole === "admin" && ( */}
              <>
                <DropdownList
                  handleAccordBtn={handleAccordBtn}
                  //close side bar on render another component
                  // handleSidebar();

                  isOpen={isOpen}
                  title="الرئيسية"
                  id="Home"
                  icon="home"
                  listItem={[
                    {
                      title: "البانر الرئيسي",
                      url: "BannersHistory",
                      classNameKey: "Home",
                    },
                    {
                      title: "بانر الاستشارات",
                      url: "consultationHomeBanner/1",
                      classNameKey: "Home",
                    },
                    {
                      title: "صخرة نادرة",
                      url: "uniqueRockHistory",
                      classNameKey: "Home",
                    },
                    ...(userRole !== "ROLE_MODERATOR"
                      ? [
                          {
                            title: "طلبات الصخرة النادرة",
                            url: "uniqueRockOrderHistory",
                            classNameKey: "Home",
                          },
                        ]
                      : []),
                  ]}
                />
                <DropdownList
                  handleAccordBtn={handleAccordBtn}
                  //close side bar on render another component
                  // handleSidebar();
                  isOpen={isOpen}
                  title="من نحن"
                  id="AboutUs"
                  icon="aboutUs"
                  listItem={[
                    {
                      title: "من نحن",
                      url: "AboutUs/1",
                      classNameKey: "AboutUs",
                    },
                    {
                      title: "رحلتنا",
                      url: "JourneysHistory",
                      classNameKey: "AboutUs",
                    },
                    {
                      title: "المصنع",
                      url: "FactoryHistory",
                      classNameKey: "AboutUs",
                    },
                    {
                      title: "الخدمات",
                      url: "ServicesHistory",
                      classNameKey: "AboutUs",
                    },
                  ]}
                />
              </>
              {/* )} */}
            </Accordion>
          </div>
          <Box my="5px">
            {userRole !== "ROLE_MODERATOR" && (
              <NavLink
                onClick={(event) => {
                  handleAccordBtn(event);
                  //close side bar on render another component
                  // handleSidebar();
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
                className={({ isActive }) =>
                  `${styles.nav_link}` +
                  (!isActive
                    ? " consultationForm "
                    : ` consultationForm consultationFormActive ${styles.active}`)
                }
                end
                to="/ConsultaionFormHistory"
              >
                <Box
                  p="12px"
                  py="13px"
                  ref={ref}
                  className={`${styles.list_items}`}
                >
                  <Box height="25px">
                    <svg
                      className={`${styles.iconPos}`}
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                      fill="none"
                    >
                      <g
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <polygon opacity="0" points="0 0 24 0 24 24 0 24" />
                        <path
                          d="M4.85714286,1 L11.7364114,1 C12.0910962,1 12.4343066,1.12568431 12.7051108,1.35473959 L17.4686994,5.3839416 C17.8056532,5.66894833 18,6.08787823 18,6.52920201 L18,19.0833333 C18,20.8738751 17.9795521,21 16.1428571,21 L4.85714286,21 C3.02044787,21 3,20.8738751 3,19.0833333 L3,2.91666667 C3,1.12612489 3.02044787,1 4.85714286,1 Z M8,12 C7.44771525,12 7,12.4477153 7,13 C7,13.5522847 7.44771525,14 8,14 L15,14 C15.5522847,14 16,13.5522847 16,13 C16,12.4477153 15.5522847,12 15,12 L8,12 Z M8,16 C7.44771525,16 7,16.4477153 7,17 C7,17.5522847 7.44771525,18 8,18 L11,18 C11.5522847,18 12,17.5522847 12,17 C12,16.4477153 11.5522847,16 11,16 L8,16 Z"
                          fill="#000000"
                          fillRule="nonzero"
                          opacity="0.3"
                        />
                        <path
                          d="M6.85714286,3 L14.7364114,3 C15.0910962,3 15.4343066,3.12568431 15.7051108,3.35473959 L20.4686994,7.3839416 C20.8056532,7.66894833 21,8.08787823 21,8.52920201 L21,21.0833333 C21,22.8738751 20.9795521,23 19.1428571,23 L6.85714286,23 C5.02044787,23 5,22.8738751 5,21.0833333 L5,4.91666667 C5,3.12612489 5.02044787,3 6.85714286,3 Z M8,12 C7.44771525,12 7,12.4477153 7,13 C7,13.5522847 7.44771525,14 8,14 L15,14 C15.5522847,14 16,13.5522847 16,13 C16,12.4477153 15.5522847,12 15,12 L8,12 Z M8,16 C7.44771525,16 7,16.4477153 7,17 C7,17.5522847 7.44771525,18 8,18 L11,18 C11.5522847,18 12,17.5522847 12,17 C12,16.4477153 11.5522847,16 11,16 L8,16 Z"
                          fill="#000000"
                          fillRule="nonzero"
                        />
                      </g>
                    </svg>
                  </Box>

                  <Text fontSize="15px" color="#1D1F22" pr="15px">
                    الاستشارات
                  </Text>
                </Box>
              </NavLink>
            )}
          </Box>
          <Box my="5px">
            <NavLink
              onClick={(event) => {
                handleAccordBtn(event);
                //close side bar on render another component
                // handleSidebar();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
              className={({ isActive }) =>
                `${styles.nav_link}` +
                (!isActive
                  ? " projects "
                  : ` projects projectsActive ${styles.active}`)
              }
              end
              to="/ProjectsHistory"
            >
              <Box
                p="12px"
                py="13px"
                ref={ref}
                className={`${styles.list_items}`}
              >
                <Box height="25px">
                  <svg
                    className={`${styles.iconPos}`}
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                    fill="none"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <polygon points="0 0 24 0 24 24 0 24" opacity="0" />
                      <path
                        d="M22,15 L22,19 C22,20.1045695 21.1045695,21 20,21 L8,21 C5.790861,21 4,19.209139 4,17 C4,14.790861 5.790861,13 8,13 L20,13 C21.1045695,13 22,13.8954305 22,15 Z M7,19 C8.1045695,19 9,18.1045695 9,17 C9,15.8954305 8.1045695,15 7,15 C5.8954305,15 5,15.8954305 5,17 C5,18.1045695 5.8954305,19 7,19 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                      <path
                        d="M15.5421357,5.69999981 L18.3705628,8.52842693 C19.1516114,9.30947552 19.1516114,10.5758055 18.3705628,11.3568541 L9.88528147,19.8421354 C8.3231843,21.4042326 5.79052439,21.4042326 4.22842722,19.8421354 C2.66633005,18.2800383 2.66633005,15.7473784 4.22842722,14.1852812 L12.7137086,5.69999981 C13.4947572,4.91895123 14.7610871,4.91895123 15.5421357,5.69999981 Z M7,19 C8.1045695,19 9,18.1045695 9,17 C9,15.8954305 8.1045695,15 7,15 C5.8954305,15 5,15.8954305 5,17 C5,18.1045695 5.8954305,19 7,19 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                      <path
                        d="M5,3 L9,3 C10.1045695,3 11,3.8954305 11,5 L11,17 C11,19.209139 9.209139,21 7,21 C4.790861,21 3,19.209139 3,17 L3,5 C3,3.8954305 3.8954305,3 5,3 Z M7,19 C8.1045695,19 9,18.1045695 9,17 C9,15.8954305 8.1045695,15 7,15 C5.8954305,15 5,15.8954305 5,17 C5,18.1045695 5.8954305,19 7,19 Z"
                        fill="#000000"
                      />
                    </g>
                  </svg>
                </Box>

                <Text fontSize="15px" color="#1D1F22" pr="15px">
                  المشاريع
                </Text>
              </Box>
            </NavLink>
          </Box>

          {/* <Box my="5px">
            <NavLink
              onClick={(event) => {
                handleAccordBtn(event);
                //close side bar on render another component
                // handleSidebar();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
              className={({ isActive }) =>
                `${styles.nav_link}` +
                (!isActive
                  ? " upload "
                  : ` upload uploadActive ${styles.active}`)
              }
              end
              to="/UploadHistory"
            >
              <Box
                p="12px"
                py="13px"
                ref={ref}
                className={`${styles.list_items}`}
              >
                <Box height="25px">
                  <svg
                    className={`${styles.iconPos}`}
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                    fill="none"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M2,13 C2,12.5 2.5,12 3,12 C3.5,12 4,12.5 4,13 C4,13.3333333 4,15 4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 C2,15 2,13.3333333 2,13 Z"
                        fill="#000000"
                        fillRule="nonzero"
                        opacity="0.3"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="11"
                        y="2"
                        width="2"
                        height="14"
                        rx="1"
                      />
                      <path
                        d="M12.0362375,3.37797611 L7.70710678,7.70710678 C7.31658249,8.09763107 6.68341751,8.09763107 6.29289322,7.70710678 C5.90236893,7.31658249 5.90236893,6.68341751 6.29289322,6.29289322 L11.2928932,1.29289322 C11.6689749,0.916811528 12.2736364,0.900910387 12.6689647,1.25670585 L17.6689647,5.75670585 C18.0794748,6.12616487 18.1127532,6.75845471 17.7432941,7.16896473 C17.3738351,7.57947475 16.7415453,7.61275317 16.3310353,7.24329415 L12.0362375,3.37797611 Z"
                        fill="#000000"
                        fillRule="nonzero"
                      />
                    </g>
                  </svg>
                </Box>

                <Text fontSize="15px" color="#1D1F22" pl="8px">
                  Upload
                </Text>
              </Box>
            </NavLink>
          </Box> */}

          <div>
            <Accordion /* defaultIndex={[0]} */ allowToggle>
              {/* {userRole === "admin" && ( */}
              <>
                <DropdownList
                  handleAccordBtn={handleAccordBtn}
                  //close side bar on render another component
                  // handleSidebar();
                  isOpen={isOpen}
                  title="المركز الإعلامي"
                  id="MediaCenter"
                  icon="mediaCenter"
                  listItem={[
                    {
                      title: "الأخبار",
                      url: "MediaHistory/1",
                      classNameKey: "MediaCenter",
                    },
                    {
                      title: "المقالات",
                      url: "MediaHistory/2",
                      classNameKey: "MediaCenter",
                    },
                    {
                      title: "معرض الصور",
                      url: "PhotoGalleryHistory",
                      classNameKey: "MediaCenter",
                    },
                  ]}
                />

                <DropdownList
                  handleAccordBtn={handleAccordBtn}
                  //close side bar on render another component
                  // handleSidebar();
                  isOpen={isOpen}
                  title="المنتجات وطلبات المنتجات"
                  id="Products"
                  icon="product"
                  listItem={[
                    {
                      title: "المنتجات",
                      url: "ProductsHistory",
                      classNameKey: "Products",
                    },
                    ...(userRole !== "ROLE_MODERATOR"
                      ? [
                          {
                            title: "طلبات المنتجات",
                            url: "OrdersHistory",
                            classNameKey: "Products",
                          },
                        ]
                      : []),
                    ...(userRole !== "ROLE_MODERATOR"
                      ? [
                          {
                            title: "اطلب من المصدر مباشرة",
                            url: "DirectReqFormHistory",
                            classNameKey: "Products",
                          },
                        ]
                      : []),
                  ]}
                />
                {userRole !== "ROLE_MODERATOR" && (
                  <>
                    <DropdownList
                      handleAccordBtn={handleAccordBtn}
                      //close side bar on render another component
                      // handleSidebar();
                      isOpen={isOpen}
                      title="تصانيف المنتجات"
                      id="Products"
                      icon="product"
                      listItem={[
                        {
                          title: "الوان المنتجات",
                          url: "ProductsColorHistory",
                          classNameKey: "Products",
                        },

                        {
                          title: "دول المنتجات",
                          url: "ProductsCountryHistory",
                          classNameKey: "Products",
                        },

                        {
                          title: "سمك المنتجات",
                          url: "ProductsThicknessHistory",
                          classNameKey: "Products",
                        },
                        {
                          title: "الحجم القياسي للمنتجات",
                          url: "ProductsStandardSizeHistory",
                          classNameKey: "Products",
                        },
                      ]}
                    />

                    <DropdownList
                      handleAccordBtn={handleAccordBtn}
                      //close side bar on render another component
                      // handleSidebar();
                      isOpen={isOpen}
                      title="تواصل معنا"
                      id="Contacts"
                      icon="contacts"
                      listItem={[
                        ...(userRole !== "ROLE_MODERATOR"
                          ? [
                              {
                                title: "معلومات التواصل",
                                url: "ContactDetails/1",
                                classNameKey: "Contacts",
                              },
                            ]
                          : []),
                        ...(userRole !== "ROLE_MODERATOR"
                          ? [
                              {
                                title: "طلبات التواصل",
                                url: "ContactFormHistory",
                                classNameKey: "Contacts",
                              },
                            ]
                          : []),
                        ...(userRole !== "ROLE_MODERATOR"
                          ? [
                              {
                                title: "طلبات التوظيف",
                                url: "CareersHistory",
                                classNameKey: "Contacts",
                              },
                            ]
                          : []),
                        {
                          title: "الوظائف المتاحة",
                          url: "AvailableJobHistory",
                          classNameKey: "Contacts",
                        },
                      ]}
                    />
                  </>
                )}
              </>
              {/* )} */}
            </Accordion>
          </div>
        </Box>
      </Box>
    </div>
  );
};
