import React, { useState } from "react";
import { UploadAPI } from "../../../api/HomeAPI";

import {
  Box,
  Divider,
  Flex,
  RadioGroup,
  Radio,
  SimpleGrid,
  GridItem,
  Text,
  Stack,
  Image,
  Input,
  Center,
  Spinner,
} from "@chakra-ui/react";

import { InputFieldValidation } from "../../../components/inputField/InputFieldValidation";
import { MainButton } from "../../../components/button/MainButton";
import { TextArea } from "../../../components/textArea/TextArea";
import { Thumb } from "../../../components/thumb/Thumb";
import { ModalAlert2 } from "../../../components/modalAlert/ModalAlert2";

export const ProductsStandardSizeForm = ({
  errors,
  touched,
  setFieldValue,
  handleBlur,
  onCancelOpen,
  formikValues,
  dirty,
  isValid,
  setDisableSave,
  disableSave,
  dismissSave,
  setDismissSave,
  handleOnSubmit,
  onUploadOpen,
  generalData,
  onUploadClose,
  isUploadOpen,
  setErrorMessage,
  setImageObj,
  onErrorOpen,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeRadio = (event, name) => {
    setFieldValue(name, event);
  };
  return (
    <>
      <Box
        maxW={["100%", "100%", "40%"]}
        width={["100%", "100%", "40%"]}
        alignSelf="flex-start"
      >
        <InputFieldValidation
          label="العنوان"
          inputType="text"
          inputId="title"
          inputName="title"
          errors={errors}
          touched={touched}
          isRequired
          onChange={setFieldValue}
          values={formikValues}
        />
        <InputFieldValidation
          label="العنوان الإنجليزي"
          inputType="text"
          inputId="titleEn"
          inputName="titleEn"
          errors={errors}
          touched={touched}
          isRequired
          onChange={setFieldValue}
          values={formikValues}
        />
      </Box>

      <Divider orientation="horizontal" mt="20px" mb="30px" />
      <Flex justifyContent="flex-end" alignItems="center" width="100%">
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="إلغاء"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="إلغاء"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={`/ProductsStandardSizeHistory`}
            />
          )}

          <MainButton
            title="حفظ"
            reverseRow={true}
            disabledBtn={isValid && dirty ? false : true}
            onPress={() => {
              handleOnSubmit(formikValues);
            }}
          />
        </Box>
      </Flex>
    </>
  );
};
