import * as Yup from "yup";

export const validationFactoryForm = Yup.object().shape({
  title: Yup.string().required("هذه الخانة مطلوبة"),
  img1: Yup.string().required("هذه الخانة مطلوبة"),
  image1Alt: Yup.string().required("هذه الخانة مطلوبة"),
  img2: Yup.string().required("هذه الخانة مطلوبة"),
  image2Alt: Yup.string().required("هذه الخانة مطلوبة"),
  // img3: Yup.string().required("هذه الخانة مطلوبة"),
  // image3ALT: Yup.string().required("هذه الخانة مطلوبة"),
});
