import React from "react";
import history from "../../history";

import {
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  GridItem,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

import { MainButton } from "../button/MainButton";

import leftArrow from "../../assets/images/icons/leftArrow2.svg";

export const ModalAlert = ({
  isOpen,
  onClose,
  title,
  desc,
  icon,
  hasDesc,
  confirmationModal = false,
  path,
  pathDraft,
  btnWidthFull,
  haveSaveDraft = true,
  isColorsSwitched,
  disableClose = false,
  isDelete = false,
  onPressSaveDraft,
  selectedID,
  errorMessage,
  setErrorMessage,
  renderRowsAfterDeletion,
  setDismissSave,
  isAssetModal = false,
  navLinkSubmit = false,
  assetVersionModal = false,
}) => {
  let modalProps;
  let hideCloseBtn = !disableClose || !confirmationModal ? false : true;

  if (!disableClose) {
    modalProps = {
      onClose: onClose,
    };
  }

  if (isAssetModal) {
    var lastItem = desc[desc.length - 1] || "";
    desc[desc.length - 1] = lastItem.replace(",", ".");
  }

  const handleDeleteItem = () => {
    renderRowsAfterDeletion(selectedID);
    onClose();
    if (assetVersionModal) {
      history.push("/AssetsHistory");
    }
  };

  const renderButtons = (confirmModal) => {
    return confirmModal ? (
      <>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <SimpleGrid columns={{ sm: 2, md: 4 }} spacingX="15px" width="90%">
            <GridItem colSpan={2}>
              {selectedID &&
              (Number(selectedID) >= 0 ||
                selectedID?.startsWith("delete-") ||
                selectedID?.startsWith("delete|")) ? (
                <MainButton
                  btnWidthFull={true}
                  title="نعم"
                  onPress={() => handleDeleteItem()}
                  btnHeight="45px"
                  btnStyle={isColorsSwitched ? "" : "transparent"}
                  border={true}
                  borderColor={isColorsSwitched ? "" : "gray"}
                />
              ) : (
                <NavLink to={path}>
                  <MainButton
                    btnWidthFull={true}
                    title="نعم"
                    onPress={onClose}
                    btnHeight="45px"
                    btnStyle={isColorsSwitched ? "" : "transparent"}
                    border={true}
                    borderColor={isColorsSwitched ? "" : "gray"}
                  />
                </NavLink>
              )}
            </GridItem>
            <GridItem colSpan={2}>
              <MainButton
                title="لا"
                btnStyle={isColorsSwitched ? "transparent" : "secondary"}
                borderColor={isColorsSwitched ? "gray" : ""}
                border={isColorsSwitched ? true : false}
                onPress={() => {
                  onClose();
                  if (setDismissSave) {
                    setDismissSave();
                  }
                }}
                btnHeight="45px"
                btnWidthFull={true}
              />
            </GridItem>
          </SimpleGrid>
        </Flex>
      </>
    ) : (
      <>
        <Center width="100%">
          {navLinkSubmit ? (
            <MainButton
              onPress={onClose}
              title="تم"
              btnWidthFull={btnWidthFull}
              btnStyle="secondary"
            />
          ) : (
            <NavLink to={path}>
              <MainButton
                onPress={onClose}
                title="تم"
                btnWidthFull={btnWidthFull}
                btnStyle="secondary"
              />
            </NavLink>
          )}
        </Center>
      </>
    );
  };

  return (
    <div className={disableClose ? "modal_disableClose" : ""}>
      <Modal isOpen={isOpen} {...modalProps}>
        <ModalOverlay
          background="rgba(29, 31, 34, 0.35)"
          backdropFilter="blur(2px)"
        />
        <ModalContent
          py={12}
          px={4}
          alignSelf="center"
          maxWidth="520px"
          className="modal_content"
        >
          <Center flexDirection="column">
            <img
              className="info_icon"
              src={icon}
              width="72px"
              height="72px"
              alt="checked icon"
            />
            <ModalHeader
              textAlign="center"
              color="#1D1F22"
              fontSize="20px"
              fontWeight="400"
              letterSpacing="-0.02em"
              px={5}
              mt={2}
              className="modal_header"
            >
              {title}
            </ModalHeader>
          </Center>
          {hideCloseBtn && <ModalCloseButton />}
          {hasDesc && (
            <ModalBody textAlign="center">
              <Center>
                <Text fontSize="15px">{desc}</Text>
              </Center>
            </ModalBody>
          )}

          <ModalFooter>{renderButtons(confirmationModal)}</ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};
