import React, { useEffect, useState } from "react";
import { UploadAPI } from "../../../api/HomeAPI";

import {
  Box,
  Divider,
  Flex,
  SimpleGrid,
  GridItem,
  Text,
  Image,
  Input,
  Center,
  Spinner,
  Button,
} from "@chakra-ui/react";

import { InputFieldValidation } from "../../../components/inputField/InputFieldValidation";
import { MainButton } from "../../../components/button/MainButton";
import { TextArea } from "../../../components/textArea/TextArea";
import { Thumb } from "../../../components/thumb/Thumb";
import { ModalAlert2 } from "../../../components/modalAlert/ModalAlert2";

export const FactoryForm = ({
  errors,
  touched,
  setFieldValue,
  handleBlur,
  onCancelOpen,
  formikValues,
  dirty,
  isValid,
  setDisableSave,
  disableSave,
  setDismissSave,
  handleOnSubmit,
  onUploadOpen,
  generalData,
  onUploadClose,
  isUploadOpen,
  setErrorMessage,
  setImageObj,
  onErrorOpen,
  id,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const download = (e, url) => {
    e.preventDefault();
    fetch(url, {
      method: "GET",
      mode: "no-cors",
      credentials: "include",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          // const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("target", "_blank"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUploadImage = (values) => {
    //alert(JSON.stringify(values, null, 2));
    setIsLoading(true);
    let submitValues = {
      file: values.file,
      alt: values.altAr,
      altEn: values.altEn,
    };
    setErrorMessage("");
    UploadAPI(submitValues)
      .then((res) => {
        setImageObj(res);

        setFieldValue(`img${selectedImage}`, res.id);
        setTimeout(() => {
          setFieldValue(`alt${selectedImage}`, res.alt);

          setFieldValue(`file`, "");
          setFieldValue(`altAr`, "");
          setFieldValue(`altEn`, "");
        }, 300);
        setIsLoading(false);
        onUploadClose();
        // if (res.success === true) {
        //   setDisableSave(true);
        //   setErrorMessage("");
        //   onSubmitOpen();
        // }
        return res;
      })
      .catch((err) => {
        setErrorMessage(err?.response?.data?.message || "هناك خطأ ما");
        onErrorOpen();
      });
  };

  return (
    <>
      <Box
        maxW={["100%", "100%", "80%"]}
        width={["100%", "100%", "80%"]}
        alignSelf="flex-start"
      >
        <InputFieldValidation
          label="العنوان"
          inputType="text"
          inputId="title"
          inputName="title"
          errors={errors}
          touched={touched}
          isRequired
          //placeholder="3.75"
          onChange={setFieldValue}
          values={formikValues}
          // isDisabled={id ? true : false}
        />
        <InputFieldValidation
          label="العنوان الإنجليزي"
          inputType="text"
          inputId="titleEn"
          inputName="titleEn"
          errors={errors}
          touched={touched}
          isRequired
          //placeholder="3.75"
          onChange={setFieldValue}
          values={formikValues}
          // isDisabled={id ? true : false}
        />

        <TextArea
          label="النص"
          inputId="text"
          inputName="text"
          placeholder="أضف النص"
          errors={errors}
          touched={touched}
          isRequired
          values={formikValues}
          onChange={setFieldValue}
          onBlur={handleBlur}
        />
        <TextArea
          label="النص الإنجليزي"
          inputId="textEn"
          inputName="textEn"
          placeholder="أضف النص الإنجليزي"
          errors={errors}
          touched={touched}
          isRequired
          values={formikValues}
          onChange={setFieldValue}
          onBlur={handleBlur}
        />
      </Box>
      <Box width="100%">
        <SimpleGrid columns={{ sm: 6, md: 6 }} spacingX="15px" width="100%">
          <GridItem colSpan={6}>
            <SimpleGrid columns={{ sm: 6, md: 6 }} spacing="15px" width="100%">
              <GridItem colSpan={2}>
                <Box paddingY="10px">
                  {!!generalData?.image1URL && (
                    <>
                      <Text
                        className="title_sm"
                        display="flex"
                        mb={2}
                        mt={0}
                        component="div"
                        textTransform="uppercase"
                        fontSize="12px"
                      >
                        الصورة الحالية
                      </Text>
                      <Box>
                        <Button
                          marginBottom="10px"
                          onClick={(e) => download(e, generalData.image1URL)}
                        >
                          عرض الملف
                        </Button>
                        <Image
                          src={generalData.image1URL}
                          width="200px"
                          height="200px"
                          mb="15px"
                        />
                        <Box display="none">
                          <InputFieldValidation
                            label="img1"
                            inputType="text"
                            inputId="img1"
                            inputName="img1"
                            errors={errors}
                            touched={touched}
                            isRequired
                            onChange={setFieldValue}
                            values={formikValues}
                            isDisabled={true}
                          />
                        </Box>
                        <InputFieldValidation
                          label="image1Alt"
                          inputType="text"
                          inputId="image1Alt"
                          inputName="image1Alt"
                          errors={errors}
                          touched={touched}
                          isRequired
                          onChange={setFieldValue}
                          values={formikValues}
                          isDisabled={true}
                        />
                        <InputFieldValidation
                          label="image1AltEn"
                          inputType="text"
                          inputId="image1AltEn"
                          inputName="image1AltEn"
                          errors={errors}
                          touched={touched}
                          isRequired
                          onChange={setFieldValue}
                          values={formikValues}
                          isDisabled={true}
                        />
                      </Box>
                    </>
                  )}
                  <MainButton
                    title="رفع صورة"
                    reverseRow={true}
                    // marginLeft="2"
                    //icon={rightArrow}
                    onPress={() => {
                      //setInitFormVal({ ...formikValues });
                      setSelectedImage("1");
                      onUploadOpen();
                    }}
                  />
                </Box>
              </GridItem>
              <GridItem colSpan={2}>
                <Box paddingY="10px">
                  {!!generalData?.image2URL && (
                    <>
                      <Text
                        className="title_sm"
                        display="flex"
                        mb={2}
                        mt={0}
                        component="div"
                        textTransform="uppercase"
                        fontSize="12px"
                      >
                        الصورة الحالية
                      </Text>
                      <Box>
                        {/* <a href="#" marginBottom="10px" onClick={(e) => download(e)}>
                          download
                        </a> */}
                        <Button
                          marginBottom="10px"
                          onClick={(e) => download(e, generalData.image2URL)}
                        >
                          عرض الملف
                        </Button>
                        <Image
                          src={generalData.image2URL}
                          width="200px"
                          height="200px"
                          mb="15px"
                        />
                        <Box display="none">
                          <InputFieldValidation
                            label="img2"
                            inputType="text"
                            inputId="img2"
                            inputName="img2"
                            errors={errors}
                            touched={touched}
                            isRequired
                            //placeholder="3.75"
                            onChange={setFieldValue}
                            values={formikValues}
                            isDisabled={true}
                          />
                        </Box>
                        <InputFieldValidation
                          label="image2Alt"
                          inputType="text"
                          inputId="image2Alt"
                          inputName="image2Alt"
                          errors={errors}
                          touched={touched}
                          isRequired
                          onChange={setFieldValue}
                          values={formikValues}
                          isDisabled={true}
                        />
                        <InputFieldValidation
                          label="image2AltEn"
                          inputType="text"
                          inputId="image2AltEn"
                          inputName="image2AltEn"
                          errors={errors}
                          touched={touched}
                          isRequired
                          onChange={setFieldValue}
                          values={formikValues}
                          isDisabled={true}
                        />
                      </Box>
                    </>
                  )}
                  <MainButton
                    title="رفع صورة"
                    reverseRow={true}
                    // marginLeft="2"
                    //icon={rightArrow}
                    onPress={() => {
                      //setInitFormVal({ ...formikValues });
                      setSelectedImage("2");
                      onUploadOpen();
                    }}
                  />
                </Box>
              </GridItem>
              <GridItem colSpan={2}>
                <Box paddingY="10px">
                  {!!generalData?.image3URL && (
                    <>
                      <Text
                        className="title_sm"
                        display="flex"
                        mb={2}
                        mt={0}
                        component="div"
                        textTransform="uppercase"
                        fontSize="12px"
                      >
                        الصورة الحالية
                      </Text>
                      <Box>
                        <Button
                          marginBottom="10px"
                          onClick={(e) => download(e, generalData.image3URL)}
                        >
                          عرض الملف
                        </Button>
                        <Image
                          src={generalData.image3URL}
                          width="200px"
                          height="200px"
                          mb="15px"
                        />
                        <Box display="none">
                          <InputFieldValidation
                            label="img3"
                            inputType="text"
                            inputId="img3"
                            inputName="img3"
                            errors={errors}
                            touched={touched}
                            isRequired
                            //placeholder="3.75"
                            onChange={setFieldValue}
                            values={formikValues}
                            isDisabled={true}
                          />
                        </Box>
                        <InputFieldValidation
                          label="image3ALT"
                          inputType="text"
                          inputId="image3ALT"
                          inputName="image3ALT"
                          errors={errors}
                          touched={touched}
                          isRequired
                          onChange={setFieldValue}
                          values={formikValues}
                          isDisabled={true}
                        />
                        <InputFieldValidation
                          label="image3AltEn"
                          inputType="text"
                          inputId="image3AltEn"
                          inputName="image3AltEn"
                          errors={errors}
                          touched={touched}
                          isRequired
                          onChange={setFieldValue}
                          values={formikValues}
                          isDisabled={true}
                        />
                      </Box>
                    </>
                  )}
                  <MainButton
                    title="رفع صورة"
                    reverseRow={true}
                    // marginLeft="2"
                    //icon={rightArrow}
                    onPress={() => {
                      //setInitFormVal({ ...formikValues });
                      setSelectedImage("3");
                      onUploadOpen();
                    }}
                  />
                </Box>
              </GridItem>
            </SimpleGrid>
          </GridItem>
        </SimpleGrid>
      </Box>

      <Divider orientation="horizontal" mt="20px" mb="30px" />
      <Flex justifyContent="flex-end" alignItems="center" width="100%">
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="إلغاء"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="إلغاء"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={`/FactoryHistory`}
            />
          )}

          <MainButton
            title="حفظ"
            reverseRow={true}
            // marginLeft="2"
            //icon={rightArrow}
            disabledBtn={isValid && dirty ? false : true}
            onPress={() => {
              handleOnSubmit(formikValues);
              //document.getElementById("main_content").scrollTo(0, 0);
            }}
          />
        </Box>
      </Flex>

      <ModalAlert2
        isOpen={isUploadOpen}
        onClose={onUploadClose}
        title="رفع صورة"
        //icon={infoCircle}
        //onPressSaveDraft={() => handleSaveDraft(values)}
      >
        <Box className="form_wrapper">
          {isLoading ? (
            <Box paddingTop={12} paddingBottom={12}>
              <Center>
                <Spinner />
              </Center>
            </Box>
          ) : (
            <>
              <Input
                paddingTop="5px"
                marginBottom="15px"
                // placeholder="Select Date and Time"
                size="md"
                type="file"
                name="file"
                errors={errors}
                touched={touched}
                values={formikValues}
                accept="image/*"
                onChange={(event) => {
                  setFieldValue("file", event.currentTarget.files[0]);
                }}
              />

              <InputFieldValidation
                label="alt"
                inputType="text"
                inputId="altAr"
                inputName="altAr"
                errors={errors}
                touched={touched}
                isRequired
                //placeholder="#000000"
                onChange={setFieldValue}
                values={formikValues}
                //isDisabled={true}
              />
              <InputFieldValidation
                label="altEn"
                inputType="text"
                inputId="altEn"
                inputName="altEn"
                errors={errors}
                touched={touched}
                isRequired
                //placeholder="#000000"
                onChange={setFieldValue}
                values={formikValues}
                //isDisabled={true}
              />

              <Box width="200px" height="200px">
                <Thumb file={formikValues?.file} />
              </Box>

              <MainButton
                title="رفع"
                type="submit"
                reverseRow={true}
                // marginLeft="2"
                //icon={rightArrow}
                //disabledBtn={isDisabledForm ? true : false}
                onPress={() => {
                  handleUploadImage(formikValues);
                }}
              />
            </>
          )}
        </Box>
      </ModalAlert2>
    </>
  );
};
