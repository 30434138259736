import axiosClient from "./index";
import { AxiosClientMultipart, AxiosClientPost } from "./index";
import Cookies from "js-cookie";

//Products
export const GetProductsAPI = async (payload, page, filters) => {
  let newOffset = (parseInt(page) - 1) * parseInt(payload.limit);
  let newPayload = { ...payload, offset: parseInt(newOffset) };
  if (filters) {
    newPayload = { ...newPayload, ...filters };
  }
  const URL = `/products/filter`;
  try {
    const response = await AxiosClientPost.post(URL, {
      ...newPayload,
      //auth: Cookies.get("token"),
    });
    /*  const response = await axiosClient.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
      body: {
        limit: 10,
        offset: 0,
      },
    }); */
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetProductByIdAPI = async (id, payload) => {
  const URL = `/products/filter`;
  //   const URL = `/products/${id}`;
  try {
    const response = await AxiosClientPost.post(URL, {
      ...payload,
      //auth: Cookies.get("token"),
    });
    // const response = await axiosClient.get(URL, {
    //   params: {
    //     token: Cookies.get("token"),
    //   },
    // });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddProductAPI = async (payload) => {
  const URL = "/products/create";
  try {
    const response = await AxiosClientPost.post(URL, {
      ...payload,
      //auth: Cookies.get("token"),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const UpdateProductAPI = async (payload) => {
  const URL = `/products/update`;
  try {
    const response = await AxiosClientPost.put(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DeleteProductByIdAPI = async (id) => {
  const URL = `/products/${id}`;
  try {
    const response = await AxiosClientPost.delete(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//Products Color
export const GetProductsColorAPI = async (limit = 10, offset = 0, page = 1) => {
  offset = (page - 1) * limit;
  const URL = `/colors/lang?lang=ar&limit=${limit}&offset=${offset}`;
  try {
    const response = await axiosClient.get(URL, {
      //   params: {
      //     token: Cookies.get("token"),
      //   },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetProductColorByIdAPI = async (id) => {
  const URL = `/colors/${id}`;
  try {
    const response = await axiosClient.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddProductColorAPI = async (payload) => {
  const URL = "/colors";
  try {
    const response = await AxiosClientPost.post(URL, {
      ...payload,
      //auth: Cookies.get("token"),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const UpdateProductColorAPI = async (id, payload) => {
  const URL = `/colors/${id}`;
  try {
    const response = await AxiosClientPost.put(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DeleteProductColorByIdAPI = async (id) => {
  const URL = `/colors/${id}`;
  try {
    const response = await AxiosClientPost.delete(URL, {
      /* params: {
          token: Cookies.get("token"),
        }, */
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//Products TYPE
export const GetProductTypeAPI = async () => {
  const URL = `/productTypes`;
  try {
    const response = await axiosClient.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
//Products COUNTRY
export const GetAllCountryAPI = async () => {
  const URL = `/allCountries`;
  try {
    const response = await axiosClient.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetProductsCountryAPI = async (
  limit = 10,
  offset = 0,
  page = 1
) => {
  offset = (page - 1) * limit;
  const URL = `/productsCountry/lang?lang=ar&limit=${limit}&offset=${offset}`;
  try {
    const response = await axiosClient.get(URL, {
      //   params: {
      //     token: Cookies.get("token"),
      //   },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetProductCountryByIdAPI = async (id) => {
  const URL = `/productsCountry/${id}`;
  try {
    const response = await axiosClient.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddProductCountryAPI = async (payload) => {
  const URL = "/productsCountry";
  try {
    const response = await AxiosClientPost.post(URL, {
      ...payload,
      //auth: Cookies.get("token"),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const UpdateProductCountryAPI = async (id, payload) => {
  const URL = `/productsCountry/${id}`;
  try {
    const response = await AxiosClientPost.put(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DeleteProductCountryByIdAPI = async (id) => {
  const URL = `/productsCountry/${id}`;
  try {
    const response = await AxiosClientPost.delete(URL, {
      /* params: {
          token: Cookies.get("token"),
        }, */
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//Products THICKNESS
export const GetProductsThicknessAPI = async (
  limit = 10,
  offset = 0,
  page = 1
) => {
  offset = (page - 1) * limit;
  const URL = `/thickness/lang?lang=ar&limit=${limit}&offset=${offset}`;
  try {
    const response = await axiosClient.get(URL, {
      //   params: {
      //     token: Cookies.get("token"),
      //   },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetProductThicknessByIdAPI = async (id) => {
  const URL = `/thickness/${id}`;
  try {
    const response = await axiosClient.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddProductThicknessAPI = async (payload) => {
  const URL = "/thickness";
  try {
    const response = await AxiosClientPost.post(URL, {
      ...payload,
      //auth: Cookies.get("token"),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const UpdateProductThicknessAPI = async (id, payload) => {
  const URL = `/thickness/${id}`;
  try {
    const response = await AxiosClientPost.put(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DeleteProductThicknessByIdAPI = async (id) => {
  const URL = `/thickness/${id}`;
  try {
    const response = await AxiosClientPost.delete(URL, {
      /* params: {
            token: Cookies.get("token"),
          }, */
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
//Products STANDARD SIZE
export const GetProductsStandardSizeAPI = async (
  limit = 10,
  offset = 0,
  page = 1
) => {
  offset = (page - 1) * limit;
  const URL = `/standardSize/lang?lang=ar&limit=${limit}&offset=${offset}`;
  try {
    const response = await axiosClient.get(URL, {
      //   params: {
      //     token: Cookies.get("token"),
      //   },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetProductStandardSizeByIdAPI = async (id) => {
  const URL = `/standardSize/${id}`;
  try {
    const response = await axiosClient.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddProductStandardSizeAPI = async (payload) => {
  const URL = "/standardSize";
  try {
    const response = await AxiosClientPost.post(URL, {
      ...payload,
      //auth: Cookies.get("token"),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const UpdateProductStandardSizeAPI = async (id, payload) => {
  const URL = `/standardSize/${id}`;
  try {
    const response = await AxiosClientPost.put(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DeleteProductStandardSizeByIdAPI = async (id) => {
  const URL = `/standardSize/${id}`;
  try {
    const response = await AxiosClientPost.delete(URL, {
      /* params: {
            token: Cookies.get("token"),
          }, */
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//Orders
export const GetOrdersAPI = async (payload, page = 1, filters) => {
  let newOffset = (parseInt(page) - 1) * parseInt(payload.limit);
  let newPayload = { ...payload, offset: parseInt(newOffset) };
  if (filters) {
    newPayload = { ...newPayload, ...filters };
  }
  // console.log("filter api", filters, page, newOffset, newPayload);
  const URL = `/orders/filter`;
  try {
    const response = await AxiosClientPost.post(URL, {
      ...newPayload,
      //auth: Cookies.get("token"),
    });
    // const response = await axiosClient.get(URL, {
    //   body: {
    //     limit: 10,
    //     offset: 0,
    //   },
    //   //   params: {
    //   //     token: Cookies.get("token"),
    //   //     _limit: `10`,
    //   //     _offset: `0`,
    //   //     // ...payload,
    //   //   },
    //   //   body: { ...payload },
    // });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetOrderByIdAPI = async (payload) => {
  const URL = `/orders/filter`;
  try {
    const response = await AxiosClientPost.post(URL, {
      ...payload,
      //auth: Cookies.get("token"),
    });
    // const response = await axiosClient.get(URL, {
    //   params: {
    //     token: Cookies.get("token"),
    //   },
    // });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddOrderAPI = async (payload) => {
  const URL = "/orders";
  try {
    const response = await AxiosClientPost.post(URL, {
      ...payload,
      //auth: Cookies.get("token"),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const UpdateOrderAPI = async (id, payload) => {
  const URL = `/orders/${id}`;
  try {
    const response = await AxiosClientPost.put(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DeleteOrderByIdAPI = async (id) => {
  const URL = `/orders/${id}`;
  try {
    const response = await AxiosClientPost.delete(URL, {
      /* params: {
          token: Cookies.get("token"),
        }, */
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//DirectReqForm
export const GetDirectReqFormAPI = async (limit = 10, offset = 0, page = 1) => {
  offset = (page - 1) * limit;
  const URL = `/directRequestForm/lang?limit=${limit}&offset=${offset}`;
  try {
    const response = await axiosClient.get(URL, {
      //   params: {
      //     token: Cookies.get("token"),
      //   },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetDirectReqFormByIdAPI = async (id) => {
  const URL = `/directRequestForm/${id}`;
  try {
    const response = await axiosClient.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DeleteDirectReqFormByIdAPI = async (id) => {
  const URL = `/directRequestForm/${id}`;
  try {
    const response = await AxiosClientPost.delete(URL, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};
