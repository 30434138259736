import * as Yup from "yup";

export const validationBannerForm = Yup.object().shape({
  title: Yup.string().required("هذه الخانة مطلوبة"),
  subtitle: Yup.string().required("هذه الخانة مطلوبة"),
  // btn1Text: Yup.string().required("This field is required"),
  // btn1Link: Yup.string().required("This field is required"),
  // btn2Text: Yup.string().required("This field is required"),
  // btn2Link: Yup.string().required("This field is required"),
  fontColor: Yup.string().required("هذه الخانة مطلوبة"),
  image: Yup.string().required("هذه الخانة مطلوبة"),
  // titleFontSize: Yup.string().required("This field is required"),
  // subtitleFontSize: Yup.string().required("This field is required"),
});
