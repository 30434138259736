import React, { useState, useEffect, useCallback } from "react";

import { Box, Text, Center, Spinner, useDisclosure } from "@chakra-ui/react";

import { GetProjectsAPI, DeleteProjectByIdAPI } from "../../api/ProjectsAPI";

import { MainTitle } from "../../components/mainTitle/MainTitle";
import { MainBox } from "../../components/mainBox/MainBox";
import { MainButton } from "../../components/button/MainButton";
import { ProjectsTable } from "./ProjectsTable";
import { ModalAlert } from "../../components/modalAlert/ModalAlert";
import { parseToken } from "../../utils/Auth";

import doc from "../../assets/images/iconsSvg/doc.svg";
import plus from "../../assets/images/iconsSvg/plus.svg";
import infoCircle from "../../assets/images/iconsSvg/infoCircle.svg";

export const ProjectsHistory = () => {
  const {
    isOpen: isDelModalOpen,
    onOpen: onDelModalOpen,
    onClose: onDelModalClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();
  //const { succ } = useParams();
  const [generalData, setGeneralData] = useState([]);
  const [selectedID, setSelectedID] = useState("-1");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  // const [userRole, setUserRole] = useState(parseToken().roles[0]);
  const [seoRole, setSeoRole] = useState(
    parseToken().role === "ROLE_MODERATOR"
  );

  const loadTableData = useCallback(() => {
    document.getElementById("main_content").scrollTo(0, 0);

    GetProjectsAPI()
      .then((res) => {
        setGeneralData(res);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    loadTableData();
  }, [loadTableData]);

  const renderRowsAfterDeletion = (id) => {
    if (!id.startsWith("delete-")) {
      return;
    }

    DeleteProjectByIdAPI(id.split("-")[1])
      .then((res) => {
        setErrorMessage("");
        setIsLoading(true);
        loadTableData();
        /* if (res.success === true) {
          loadTableData();
        } */
        return res;
      })
      .catch((err) => {
        setErrorMessage(err?.response?.data?.message || "هناك خطأ ما");
        onErrorOpen();
      });
  };

  return (
    <>
      {isLoading ? (
        <>
          <MainTitle title="المشاريع" />
          <MainBox>
            <Box paddingTop={12} paddingBottom={12}>
              <Center>
                <Spinner />
              </Center>
            </Box>
          </MainBox>
        </>
      ) : (
        <>
          {generalData?.data?.length <= 0 ? (
            <>
              <MainTitle title="المشاريع" />
              <MainBox>
                <Box className="table_content" pt={6} pb={6}>
                  <Center flexDirection="column">
                    <img src={doc} alt="icon" />
                    <Box mt={3} mb={5}>
                      <Text className="title_sm">لا يوجد إدخالات حالياً</Text>
                    </Box>
                    {!seoRole && (
                      <MainButton
                        title="إضافة"
                        marginRight="1"
                        icon={plus}
                        navLink={true}
                        path="/Projects"
                      />
                    )}
                  </Center>
                </Box>
              </MainBox>
            </>
          ) : (
            <>
              <ProjectsTable
                generalData={generalData}
                setSelectedID={setSelectedID}
                onDelModalOpen={onDelModalOpen}
                seoRole={seoRole}
              />
            </>
          )}
        </>
      )}

      <ModalAlert
        isOpen={isDelModalOpen}
        onClose={onDelModalClose}
        title="هل أنت متأكد أنك تريد الحذف؟"
        icon={infoCircle}
        hasDesc={false}
        confirmationModal={true}
        haveSaveDraft={true}
        isDelete={true}
        selectedID={selectedID}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        renderRowsAfterDeletion={renderRowsAfterDeletion}
      />

      <ModalAlert
        isOpen={isErrorOpen}
        onClose={onErrorClose}
        title={errorMessage}
        icon={infoCircle}
        hasDesc={false}
        confirmationModal={false}
      />
    </>
  );
};
