import axiosClient from "./index";
import { AxiosClientMultipart, AxiosClientPost } from "./index";
import Cookies from "js-cookie";

//CONTACTS DETAILS
export const GetContactDetails = async () => {
  const URL = `/contacts`;
  try {
    const response = await axiosClient.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const UpdateContactDetailsAPI = async (payload) => {
  const URL = `/contacts/1`;
  try {
    const response = await AxiosClientPost.put(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//CAREERS
export const GetCareersAPI = async (limit = 10, offset = 0, page = 1) => {
  offset = (page - 1) * limit;
  const URL = `/careers/lang?limit=${limit}&offset=${offset}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetCareerByIdAPI = async (id) => {
  const URL = `/careers/${id}`;
  try {
    const response = await axiosClient.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddCareerAPI = async (payload) => {
  const URL = "/careers";
  try {
    const response = await AxiosClientPost.post(URL, {
      ...payload,
      //auth: Cookies.get("token"),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const UpdateCareerAPI = async (id, payload) => {
  const URL = `/careers/${id}`;
  try {
    const response = await AxiosClientPost.put(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DeleteCareerByIdAPI = async (id) => {
  const URL = `/careers/${id}`;
  try {
    const response = await AxiosClientPost.delete(URL, {
      /* params: {
          token: Cookies.get("token"),
        }, */
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//Available Jobs
export const GetAvailableJobsAPI = async (limit = 10, offset = 0, page = 1) => {
  offset = (page - 1) * limit;
  const URL = `/availableJobs/lang?lang=ar&limit=${limit}&offset=${offset}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetAvailableJobByIdAPI = async (id) => {
  const URL = `/availableJobs/${id}`;
  try {
    const response = await axiosClient.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddAvailableJobAPI = async (payload) => {
  const URL = "/availableJobs";
  try {
    const response = await AxiosClientPost.post(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const UpdateAvailableJobAPI = async (id, payload) => {
  const URL = `/availableJobs/${id}`;
  try {
    const response = await AxiosClientPost.put(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DeleteAvailableJobByIdAPI = async (id) => {
  const URL = `/availableJobs/${id}`;
  try {
    const response = await AxiosClientPost.delete(URL, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

//CONSULTAION FORM
export const GetConsultaionFormAPI = async (
  limit = 10,
  offset = 0,
  page = 1
) => {
  offset = (page - 1) * limit;
  const URL = `/consultationForm/lang?limit=${limit}&offset=${offset}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetConsultaionFormByIdAPI = async (id) => {
  const URL = `/consultationForm/${id}`;
  try {
    const response = await axiosClient.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DeleteConsultaionFormByIdAPI = async (id) => {
  const URL = `/consultationForm/${id}`;
  try {
    const response = await AxiosClientPost.delete(URL, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};
