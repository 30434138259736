import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useCallback,
} from "react";

import { ChakraProvider } from "@chakra-ui/react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

import { CkEditor } from "./pages/CkEditor";

import { parseToken } from "./utils/Auth";
import { Sidebar } from "./layouts/sidebar";
import { Main } from "./layouts/main";
import { Login } from "./pages/Login";
import { useSearchParams } from "react-router-dom";

import "./assets/styles/global.scss";

function App() {
  const renderRef = useRef(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(true);
  const [user, setUser] = useState(false);
  const handleUserAuth = (user) => {
    let userRole = user?.roles?.[0] || "none";
    if (true) {
      return (
        <ChakraProvider>
          <div className="alamin_content">
            <Sidebar user={user} isOpen={isOpen} setIsOpen={setIsOpen} />
            {/* <CkEditor /> */}
            <Main user={user} isOpen={isOpen} />
          </div>
        </ChakraProvider>
      );
    } else {
      return <></>;
    }
  };

  useEffect(() => {
    if (renderRef.current) return;
    renderRef.current = true;
    if (Cookies.get("token")) {
      let token = Cookies.get("token");
      setUser(jwt_decode(token));
      // console.log("user==>", user);
    }

    if (searchParams.get("token")) {
      let token = searchParams.get("token");
      setUser(jwt_decode(token));
      Cookies.set("token", token);
      setSearchParams("");
    }
  }, []);

  return user ? handleUserAuth(user) : <Login setUser={setUser} />;
}

export default App;
