import React from "react";

import { Box, Divider, Flex, Text, Image, Input } from "@chakra-ui/react";

import { InputFieldValidation } from "../../components/inputField/InputFieldValidation";
import { MainButton } from "../../components/button/MainButton";
import { Thumb } from "../../components/thumb/Thumb";

export const UploadForm = ({
  errors,
  touched,
  setFieldValue,
  handleBlur,
  onCancelOpen,
  formikValues,
  dirty,
  isValid,
  setDisableSave,
  disableSave,
  dismissSave,
  setDismissSave,
  handleOnSubmit,
  generalData,
  id,
}) => {
  return (
    <>
      <Box
        maxW={["100%", "100%", "40%"]}
        width={["100%", "100%", "40%"]}
        alignSelf="flex-start"
      >
        <Box paddingY="10px">
          <Text
            className="title_sm"
            display="flex"
            mb={2}
            mt={0}
            component="div"
            textTransform="uppercase"
            fontSize="12px"
          >
            Upload an image
          </Text>
          <Input
            paddingTop="5px"
            marginBottom="15px"
            size="md"
            type="file"
            name="file"
            errors={errors}
            touched={touched}
            values={formikValues}
            disabled={!!!id}
            accept="image/*"
            onChange={(event) => {
              setFieldValue("file", event.currentTarget.files[0]);
            }}
          />
          {formikValues.file && (
            <Box width="200px" height="200px">
              <Thumb file={formikValues?.file} />
            </Box>
          )}
          {!!generalData?.url && (
            <>
              <Text
                className="title_sm"
                display="flex"
                mb={2}
                mt={0}
                component="div"
                textTransform="uppercase"
                fontSize="12px"
              >
                CURRENT IMAGE
              </Text>
              <Image
                src={generalData.url}
                width="200px"
                height="200px"
                mb="15px"
              />
            </>
          )}
        </Box>
        <InputFieldValidation
          label="alt"
          inputType="text"
          inputId="alt"
          inputName="alt"
          errors={errors}
          touched={touched}
          isRequired
          onChange={setFieldValue}
          values={formikValues}
          isDisabled={!!!id}
        />
        <InputFieldValidation
          label="alt en"
          inputType="text"
          inputId="altEn"
          inputName="altEn"
          errors={errors}
          touched={touched}
          isRequired
          onChange={setFieldValue}
          values={formikValues}
          isDisabled={!!!id}
        />
        {/* <InputFieldValidation
          label="Image"
          inputType="text"
          inputId="image"
          inputName="image"
          errors={errors}
          touched={touched}
          isRequired
          onChange={setFieldValue}
          values={formikValues}
          isDisabled={true}
        /> */}
      </Box>

      <Divider orientation="horizontal" mt="20px" mb="30px" />
      <Flex justifyContent="flex-end" alignItems="center" width="100%">
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={`/UploadHistory`}
            />
          )}

          {id ? (
            <MainButton title="Submit" reverseRow={true} disabledBtn={true} />
          ) : (
            <MainButton
              title="Submit"
              reverseRow={true}
              disabledBtn={isValid && dirty ? false : true}
              onPress={() => {
                handleOnSubmit(formikValues);
              }}
            />
          )}
        </Box>
      </Flex>
    </>
  );
};
