import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { SigninAPI } from "../api/AuthAPI";

import { Formik, Form } from "formik";
import { Box, Button } from "@chakra-ui/react";

import { InputFieldValidation } from "../components/inputField/InputFieldValidation";
import { MainBox } from "../components/mainBox/MainBox";

import error from "../assets/images/icons/error.svg";
import logo from "../assets/images/logo.png";
export const Login = ({ setUser }) => {
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [passType, setPassType] = useState("password");

  const showPassHandler = () => {
    if (passType === "password") {
      setPassType("text");
    } else {
      setPassType("password");
    }
  };

  return (
    <div className="login_page">
      {!loading ? (
        <MainBox boxSize="md" maxWidth="500px" borderWidth="5">
          <Box
            w="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderBottomWidth={1}
            borderBottomColor="#EFEFEF"
            borderBottomStyle="solid"
            pb={17}
            mb={10}
          >
            <img src={logo} alt="logo" />
          </Box>
          <Box
            w="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            pb={17}
            mb={10}
          >
            <div className="login_title">تسجيل الدخول</div>
          </Box>
          <Box w="100%" pt={5} mb={0}>
            <Formik
              initialValues={{ username: "", password: "" /* , email: "" */ }}
              validate={(values) => {
                const errors = {};
                if (!values.username) {
                  errors.username = "هذه الخانة مطلوبة";
                }
                // if (!values.email) {
                //   errors.email = "Required";
                // }
                if (!values.password) {
                  errors.password = "هذه الخانة مطلوبة";
                }

                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setLoading(true);
                setSubmitting(false);
                SigninAPI(values)
                  .then((res) => {
                    if (res.accessToken) {
                      setLoading(false);
                      Cookies.set("token", res.accessToken);
                      Cookies.set("refreshToken", res.refreshToken);
                      setUser(res);
                      setIsValid(true);
                    } else {
                      setIsValid(false);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            >
              {({ isSubmitting, errors, touched }) => (
                <Form className="form_wrapper" style={{textAlign:"right",direction: "rtl"}}>
                  <InputFieldValidation
                    inputType="text"
                    inputId="username"
                    inputName="username"
                    isRequired
                    labelVisibility={true}
                    label="إسم المستخدم"
                    dColumnControl={true}
                    placeholder=""
                    errors={errors}
                    touched={touched}
                    fullWidth="w-100"
                  />

                  <InputFieldValidation
                    inputType={passType}
                    inputId="password"
                    inputName="password"
                    isRequired
                    labelVisibility={true}
                    label="الباسوورد"
                    dColumnControl={true}
                    placeholder=""
                    errors={errors}
                    touched={touched}
                    fullWidth="w-100"
                    password={true}
                    showPassHandler={showPassHandler}
                  />

                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    width="100%"
                    className="btn_submit"
                  >
                    دخول
                  </Button>
                </Form>
              )}
            </Formik>
          </Box>
        </MainBox>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <img src={logo} alt="large logo" />

          <Box mt={20}>
            <div className="loading_text">جاري فتح لوحة تحكم الأمين ...</div>
          </Box>
        </Box>
      )}
      {!isValid && (
        <Box
          bg="#EBCBCB"
          maxW="500px"
          py={15}
          px={25}
          m={15}
          color="#E0232E"
          w="100%"
          borderRadius={5}
          display="flex"
          alignItems="center"
        >
          <Box pe={10}>
            <img src={error} alt="error icon" />
          </Box>
          <div className="error_text">
            Username or password is incorrect, please contact your administrator
          </div>
        </Box>
      )}
    </div>
  );
};
