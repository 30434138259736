import * as Yup from "yup";

export const validationConsultHomeBannerForm = Yup.object().shape({
  text: Yup.string().required("هذه الخانة مطلوبة"),
  btnTxt: Yup.string().required("هذه الخانة مطلوبة"),
  btnLink: Yup.string().required("هذه الخانة مطلوبة"),
  textEn: Yup.string().required("هذه الخانة مطلوبة"),
  btnTxtEn: Yup.string().required("هذه الخانة مطلوبة"),
  btnLinkEn: Yup.string().required("هذه الخانة مطلوبة"),
  image: Yup.string().required("هذه الخانة مطلوبة"),
  altImag: Yup.string().required("هذه الخانة مطلوبة"),
});
