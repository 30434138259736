import React, { useState, useEffect, useCallback } from "react";

import {
  Box,
  Divider,
  Flex,
  RadioGroup,
  Radio,
  SimpleGrid,
  GridItem,
  Text,
  Stack,
  Image,
  Input,
  Center,
  Spinner,
  Button,
  Checkbox,
} from "@chakra-ui/react";

import { MainButton } from "../../../components/button/MainButton";
import { SelectListMulti } from "../../../components/selectField/SelectListMulti";

export const ProductTableForm = ({
  errors,
  touched,
  setFieldValue,
  handleBlur,
  formikValues,
  dirty,
  isValid,
  handleOnSubmit,
  generalData,
  dropdownObj,
  setInitFormVal,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckBoxSelected, setIsCheckBoxSelected] = useState("");
  const [clearSelect, setClearSelect] = useState(false);

  const handleCheckBox = useCallback((e) => {
    setIsCheckBoxSelected([e.target.checked]);
    setFieldValue(`${e.target.name}`, e.target.checked);
  }, []);

  const handleChangeRadio = (event, name) => {
    setFieldValue(name, event);
  };
  const clearFields = () => {
    setFieldValue("most_selling", "");
    setFieldValue("new_arrival", "");
    setFieldValue("type_id", "");
    setFieldValue("country_id", "");
    setFieldValue("color_id", "");
    setClearSelect(!clearSelect);

    setTimeout(() => {
      handleOnSubmit({});
    }, 500);
  };
  return (
    <>
      <Box
        maxW={["100%", "100%", "100%"]}
        width={["100%", "100%", "100%"]}
        alignSelf="flex-start"
      >
        <SimpleGrid
          columns={{ sm: 4, md: 4 }}
          spacingX="5px"
          width="100%"
          marginBottom={"50px"}
        >
          <GridItem colSpan={4}>
            <SimpleGrid columns={{ sm: 4, md: 4 }} spacingX="5px" width="100%">
              <GridItem colSpan={2}>
                <SelectListMulti
                  label="الدولة"
                  placeholder="إختيار"
                  inputId="country_id"
                  inputName="country_id"
                  errors={errors}
                  touched={touched}
                  isRequired
                  values={formikValues?.country_id}
                  onChange={setFieldValue}
                  onBlur={handleBlur}
                  uppercase={true}
                  isMulti={true}
                  errorHeight0={true}
                  clearMulti={clearSelect}
                  setClearSelect={setClearSelect}
                  dropdownItems={dropdownObj.prodCountry}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <SelectListMulti
                  label="النوع"
                  placeholder="إختيار"
                  inputId="type_id"
                  inputName="type_id"
                  errors={errors}
                  touched={touched}
                  isRequired
                  values={formikValues?.type_id}
                  onChange={setFieldValue}
                  onBlur={handleBlur}
                  uppercase={true}
                  isMulti={true}
                  errorHeight0={true}
                  clearMulti={clearSelect}
                  setClearSelect={setClearSelect}
                  dropdownItems={dropdownObj.prodType}
                />
              </GridItem>
              {/* <GridItem colSpan={2}>
               
                <SelectListMulti
                  label="اللون"
                  placeholder="إختيار"
                  inputId="color_id"
                  inputName="color_id"
                  errors={errors}
                  touched={touched}
                  isRequired
                  values={formikValues?.color_id}
                  onChange={setFieldValue}
                  onBlur={handleBlur}
                  uppercase={true}
                  isMulti={true}
                  errorHeight0={true}
                  clearMulti={clearSelect}
                  setClearSelect={setClearSelect}
                  dropdownItems={dropdownObj.colors}
                />
              </GridItem> */}
              <GridItem
                colSpan={4}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box display="flex" alignItems="center">
                  {formikValues?.most_selling == 1 && (
                    <Checkbox
                      colorScheme="green"
                      marginLeft="30px"
                      name="most_selling"
                      onChange={handleCheckBox}
                      value={formikValues.most_selling}
                      defaultChecked={formikValues.most_selling}
                    >
                      <Text>الأكثر مبيعاً</Text>
                    </Checkbox>
                  )}
                  {(formikValues?.most_selling == 0 ||
                    formikValues?.most_selling == "") && (
                    <Checkbox
                      colorScheme="green"
                      marginLeft="30px"
                      name="most_selling"
                      onChange={handleCheckBox}
                      value={formikValues.most_selling}
                      defaultChecked={formikValues.most_selling}
                    >
                      <Text>الأكثر مبيعاً</Text>
                    </Checkbox>
                  )}

                  {formikValues?.new_arrival == 1 && (
                    <Checkbox
                      colorScheme="green"
                      name="new_arrival"
                      onChange={handleCheckBox}
                      value={formikValues.new_arrival}
                      defaultChecked={formikValues.new_arrival}
                    >
                      <Text>وصل حديثاً</Text>
                    </Checkbox>
                  )}
                  {(formikValues?.new_arrival == 0 ||
                    formikValues?.new_arrival == "") && (
                    <Checkbox
                      colorScheme="green"
                      name="new_arrival"
                      onChange={handleCheckBox}
                      value={formikValues.new_arrival}
                      defaultChecked={formikValues.new_arrival}
                    >
                      <Text>وصل حديثاً</Text>
                    </Checkbox>
                  )}
                </Box>
                <Flex justifyContent="flex-end" alignItems="center">
                  <Box display="flex" gap="10px">
                    <MainButton
                      title="مسح"
                      reverseRow={true}
                      btnStyle="secondary"
                      // disabledBtn={isValid && dirty ? false : true}
                      onPress={() => {
                        clearFields();
                      }}
                    />
                    <MainButton
                      title="تطبيق"
                      reverseRow={true}
                      disabledBtn={isValid && dirty ? false : true}
                      onPress={() => {
                        handleOnSubmit(formikValues);
                      }}
                    />
                  </Box>
                </Flex>
              </GridItem>
            </SimpleGrid>
          </GridItem>
        </SimpleGrid>
      </Box>
    </>
  );
};
