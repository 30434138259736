import React, { useState, useEffect } from "react";

import {
  GetConsultationHomeBanner,
  UpdateConsultationHomeBannerAPI,
} from "../../../api/HomeAPI";

import { useLocation, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { Box, useDisclosure } from "@chakra-ui/react";

import { activeMenuItem } from "../../../utils/General";
import { ModalAlert } from "../../../components/modalAlert/ModalAlert";
import { MainTitle } from "../../../components/mainTitle/MainTitle";
import { MainBox } from "../../../components/mainBox/MainBox";
import { ConsultationHomeBannerForm } from "./ConsultationHomeBannerForm";
import { validationConsultHomeBannerForm } from "./validationConsultHomeBannerForm";
import history from "../../../history";

import checked from "../../../assets/images/iconsSvg/checked.svg";
import infoCircle from "../../../assets/images/iconsSvg/infoCircle.svg";

export const ConsultationHomeBanner = () => {
  const activeMenu = activeMenuItem();
  const { id } = useParams();
  let location = useLocation();
  const [initFormVal, setInitFormVal] = useState({});
  const [generalData, setGeneralData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [disableSave, setDisableSave] = useState(true);
  const [dismissSave, setDismissSave] = useState(true);
  const [imageObj, setImageObj] = useState({});

  let submitModalTitle = id
  ? "تم التحديث بنجاح"
  : "تم الإضافة بنجاح";

  const {
    isOpen: isSubmitOpen,
    onOpen: onSubmitOpen,
    onClose: onSubmitClose,
  } = useDisclosure();

  const {
    isOpen: isUploadOpen,
    onOpen: onUploadOpen,
    onClose: onUploadClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();

  const handleOnSubmit = (formikValue) => {
    if (!!id) {
      const submitValues = {
        //...formikValue,
        id: generalData.id,
        text: formikValue.text,
        textEn: formikValue.textEn,
        btnTxt: formikValue.btnTxt,
        btnTxtEn: formikValue.btnTxtEn,
        btnLink: formikValue.btnLink,
        btnLinkEn: formikValue.btnLinkEn,
        image: formikValue.image,
        Image: {
          id: formikValue.image,
          url: generalData.Image.url,
          alt: formikValue.alt,
          altEn: formikValue.altEn,
        },
      };

      UpdateConsultationHomeBannerAPI(submitValues)
        .then((res) => {
          setDisableSave(true);
          setErrorMessage("");
          onSubmitOpen();
          if (res.success === true) {
            setDisableSave(true);
            setErrorMessage("");
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "هناك خطأ ما"
          );
          onErrorOpen();
        });
    }
  };

  useEffect(() => {
    if (!!id) {
      setErrorMessage("");
      GetConsultationHomeBanner()
        .then((res) => {
          setInitFormVal({
            text: res.data[0].text,
            textEn: res.data[0].textEn,
            btnTxt: res.data[0].btnTxt,
            btnTxtEn: res.data[0].btnTxtEn,
            btnLink: res.data[0].btnLink,
            btnLinkEn: res.data[0].btnLinkEn,
            image: res.data[0].image,
            altImag: res.data[0].Image.alt,
            alt: res.data[0].Image.alt,
            altEn: res.data[0].Image.altEn,
            altAr: "",
            file: "",
          });
          setGeneralData(res.data[0]);
        })
        .catch((err) => {
          setErrorMessage("هناك خطأ ما");
          onErrorOpen();
        });
    }
  }, []);
  useEffect(() => {
    let unblock;
    if (!disableSave && dismissSave) {
      unblock = history.block((tx) => {
        let url = tx.location.pathname;
        if (window.confirm(`هل أنت متأكد أنك تريد تجاهل التغييرات؟`)) {
          unblock();

          tx.retry();
        }
      });
    }

    return () => {
      if (typeof unblock === "function") {
        unblock();
      }
    };
  }, [disableSave, dismissSave]);

  return (
    <div className="ConsultationBanner">
      <>
        <MainBox px={0}>
          {id === undefined ? (
            <MainTitle
              title="إضافة بانر إستشارات"
              bBottom={true}
              px={7}
            />
          ) : (
            <MainTitle
              title="تعديل بانر إستشارات"
              bBottom={true}
              px={7}
            />
          )}
          <Box className="table_content" pt={6} pb={6}>
            <Formik
              initialValues={initFormVal}
              enableReinitialize={true}
              validationSchema={validationConsultHomeBannerForm}
              validate={(values) => {
                if (disableSave) {
                  setDisableSave(false);
                }
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  alert(JSON.stringify(values, null, 2));
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({
                errors,
                touched,
                values,
                handleChange,
                handleBlur,
                setFieldValue,
                isValid,
                dirty,
              }) => (
                <Form className="form_wrapper">
                  <ConsultationHomeBannerForm
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    formikValues={values}
                    isValid={isValid}
                    dirty={dirty}
                    disableSave={disableSave}
                    setDisableSave={setDisableSave}
                    dismissSave={dismissSave}
                    setDismissSave={setDismissSave}
                    onSubmitOpen={onSubmitOpen}
                    onUploadOpen={onUploadOpen}
                    handleOnSubmit={handleOnSubmit}
                    setInitFormVal={setInitFormVal}
                    generalData={generalData}
                    isUploadOpen={isUploadOpen}
                    onUploadClose={onUploadClose}
                    onErrorOpen={onErrorOpen}
                    setErrorMessage={setErrorMessage}
                    setImageObj={setImageObj}
                  />
                </Form>
              )}
            </Formik>
          </Box>
        </MainBox>

        <ModalAlert
          isOpen={isErrorOpen}
          onClose={onErrorClose}
          title={errorMessage}
          icon={infoCircle}
          hasDesc={false}
          confirmationModal={false}
        />

        <ModalAlert
          isOpen={isSubmitOpen}
          onClose={onSubmitClose}
          title={submitModalTitle}
          btnWidthFull={true}
          icon={checked}
          hasDesc={false}
          disableClose={true}
          path={`/consultationHomeBanner/1`}
        />
      </>
    </div>
  );
};
