import React, { useState } from "react";

import { GetProductsCountryAPI } from "../../../api/ProductsAPI";
import { UploadAPI } from "../../../api/HomeAPI";

import {
  Box,
  Divider,
  Flex,
  RadioGroup,
  Radio,
  SimpleGrid,
  GridItem,
  Text,
  Stack,
  Image,
  Input,
  Center,
  Spinner,
} from "@chakra-ui/react";

import { InputFieldValidation } from "../../../components/inputField/InputFieldValidation";
import { MainButton } from "../../../components/button/MainButton";
import { SelectListMulti } from "../../../components/selectField/SelectListMulti";
import { TextArea } from "../../../components/textArea/TextArea";
import { Thumb } from "../../../components/thumb/Thumb";
import { ModalAlert2 } from "../../../components/modalAlert/ModalAlert2";

export const ProductsCountryForm = ({
  errors,
  touched,
  setFieldValue,
  handleBlur,
  onCancelOpen,
  formikValues,
  dirty,
  isValid,
  setDisableSave,
  disableSave,
  dismissSave,
  setDismissSave,
  handleOnSubmit,
  onUploadOpen,
  generalData,
  onUploadClose,
  isUploadOpen,
  setErrorMessage,
  setImageObj,
  onErrorOpen,
  allCountry,
  allCountryInfo,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  // console.log("all", allCountryInfo);
  const handleOnChange = (event, name) => {
    // console.log(name, event);
    const filterCountry = allCountryInfo.find((el) => el.id == name);
    // console.log("filterCountry", filterCountry);
    setFieldValue("title", filterCountry?.country);
    setFieldValue("titleEn", filterCountry?.countryEn);
    /* GetProductsCountryAPI(300, 0, 1)
      .then((res) => {
        console.log("reees cc ProductsCountry", res);
        // let countryArr = [];
        // res.map((el) => {
        //   countryArr.push({ value: el.id, label: el.country });
        // });
        // setAllCountryInfo(res);
        // setAllCountry(countryArr);
      })
      .catch((err) => {
        console.log(err);
      }); */
  };
  const handleChangeRadio = (event, name) => {
    setFieldValue(name, event);
  };
  return (
    <>
      <Box
        maxW={["100%", "100%", "40%"]}
        width={["100%", "100%", "40%"]}
        alignSelf="flex-start"
      >
        <SelectListMulti
          dropdownItems={allCountry}
          label="الدولة ID"
          inputId="countryID"
          inputName="countryID"
          placeholder="Select"
          errors={errors}
          touched={touched}
          isRequired
          values={formikValues?.countryID}
          onChange={setFieldValue}
          onChange2={handleOnChange}
          onBlur={handleBlur}
          uppercase={true}
        />

        <TextArea
          label="تفاصيل الدولة"
          inputId="description"
          inputName="description"
          placeholder="Add a description"
          errors={errors}
          touched={touched}
          isRequired
          values={formikValues}
          onChange={setFieldValue}
          onBlur={handleBlur}
        />
        <TextArea
          label="تفاصيل الدولة الإنجليزية"
          inputId="descriptionEn"
          inputName="descriptionEn"
          placeholder="Add a description en"
          errors={errors}
          touched={touched}
          isRequired
          values={formikValues}
          onChange={setFieldValue}
          onBlur={handleBlur}
        />
        <InputFieldValidation
          label="إسم الدولة"
          inputType="text"
          inputId="title"
          inputName="title"
          errors={errors}
          touched={touched}
          isRequired
          onChange={setFieldValue}
          values={formikValues}
          isDisabled={true}
        />
        <InputFieldValidation
          label="إسم الدولة الإنجليزي"
          inputType="text"
          inputId="titleEn"
          inputName="titleEn"
          errors={errors}
          touched={touched}
          isRequired
          onChange={setFieldValue}
          values={formikValues}
          isDisabled={true}
        />
      </Box>

      <Divider orientation="horizontal" mt="20px" mb="30px" />
      <Flex justifyContent="flex-end" alignItems="center" width="100%">
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="إلغاء"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="إلغاء"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={`/ProductsCountryHistory`}
            />
          )}

          <MainButton
            title="حفظ"
            reverseRow={true}
            disabledBtn={isValid && dirty ? false : true}
            onPress={() => {
              handleOnSubmit(formikValues);
            }}
          />
        </Box>
      </Flex>
    </>
  );
};
