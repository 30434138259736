import React, { useState, useEffect, useCallback } from "react";

import { Box, Text, Center, Spinner, useDisclosure } from "@chakra-ui/react";
//import { useParams } from "react-router-dom";

import { GetFactoryAPI, DeleteFactoryByIdAPI } from "../../../api/AboutUsAPI";

import { MainTitle } from "../../../components/mainTitle/MainTitle";
import { MainBox } from "../../../components/mainBox/MainBox";
import { MainButton } from "../../../components/button/MainButton";
import { FactoryTable } from "./FactoryTable";
import { ModalAlert } from "../../../components/modalAlert/ModalAlert";

import doc from "../../../assets/images/iconsSvg/doc.svg";
import plus from "../../../assets/images/iconsSvg/plus.svg";
import infoCircle from "../../../assets/images/iconsSvg/infoCircle.svg";

export const FactoryHistory = () => {
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();
  //const { succ } = useParams();
  const [generalData, setGeneralData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const loadTableData = useCallback(() => {
    document.getElementById("main_content").scrollTo(0, 0);

    GetFactoryAPI()
      .then((res) => {
        setGeneralData(res);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    loadTableData();
  }, [loadTableData]);

  return (
    <>
      {isLoading ? (
        <>
          <MainTitle title="المصنع" />
          <MainBox>
            <Box paddingTop={12} paddingBottom={12}>
              <Center>
                <Spinner />
              </Center>
            </Box>
          </MainBox>
        </>
      ) : (
        <>
          {generalData?.data?.length <= 0 ? (
            <>
              <MainTitle title="المصنع" />
              <MainBox>
                <Box className="table_content" pt={6} pb={6}>
                  <Center flexDirection="column">
                    <img src={doc} alt="icon" />
                    <Box mt={3} mb={5}>
                      <Text className="title_sm"> لا يوجد إدخالات حالياً </Text>
                    </Box>
                    <MainButton
                      title="إضافة"
                      marginRight="1"
                      icon={plus}
                      navLink={true}
                      path="/Factory"
                    />
                  </Center>
                </Box>
              </MainBox>
            </>
          ) : (
            <>
              <FactoryTable generalData={generalData} />
            </>
          )}
        </>
      )}

      <ModalAlert
        isOpen={isErrorOpen}
        onClose={onErrorClose}
        title={errorMessage}
        icon={infoCircle}
        hasDesc={false}
        confirmationModal={false}
      />
    </>
  );
};
