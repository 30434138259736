import { useState, useEffect } from "react";
import {
  Box,
  Flex,
  HStack,
  TabPanel,
  TabPanels,
  Tabs,
  Image,
} from "@chakra-ui/react";

import { GetProductsStandardSizeAPI } from "../../../api/ProductsAPI";

import { MainButton } from "../../../components/button/MainButton";
import { TablePagination2 } from "../../../components/tablePagination/TablePagination2";

export const ProductsStandardSizeTable = ({
  onDelModalOpen,
  generalData,
  setSelectedID,
  seoRole,
}) => {
  let [currPagData, setCurrPagData] = useState({
    page: 1,
  });
  const handleSort = async () => {
    handleOnPageChange(currPagData.page);
    setCurrPagData({ ...currPagData });
  };
  //console.log("currPagData", currPagData);
  const [dataArray, setDataArray] = useState([]);
  const columns = [
    {
      name: "ID",
      selector: (row) => row.idCustom,
    },
    {
      name: "العنوان",
      selector: (row) => row.title,
    },

    {
      name: "الإجراء",
      selector: (row) => row.action,
      cell: (row) => (
        <Flex
          justifyContent="space-between"
          gap="15px"
          alignItems="center"
          flexDirection={["column", "column", "row"]}
        >
          <MainButton
            title="تعديل"
            btnStyle="transparent"
            fontColor="dark"
            marginRight="5"
            navLink={true}
            path={`/ProductsStandardSize/${row.idCustom}`}
          />
          {!seoRole && (
            <MainButton
              title="حذف"
              btnStyle="transparent"
              fontColor="danger"
              marginRight="5"
              onPress={() => {
                setSelectedID(`delete-${row.idCustom}`);
                onDelModalOpen();
              }}
            />
          )}
        </Flex>
      ),
    },
  ];

  useEffect(() => {
    let data = [];
    if (generalData?.data?.length > 0 && !!generalData) {
      generalData.data.forEach((item) => {
        let dataObj = {
          id: `${item.id + Math.floor(Math.random() * Date.now())}`,
          idCustom: `${item.id}`,
          title: `${item.title}`,
        };

        data.push(dataObj);
      });
      setDataArray(data);
    }
    setCurrPagData({
      page: 1,
    });
  }, []);

  const handleOnPageChange = (page) => {
    GetProductsStandardSizeAPI(10, 0, page)
      .then((res) => {
        let data = [];

        if (res.data.length > 0) {
          res.data.forEach((item) => {
            let dataObj = {
              id: `${item.id + Math.floor(Math.random() * Date.now())}`,
              idCustom: `${item.id}`,
              title: `${item.title}`,
            };

            data.push(dataObj);
          });

          setDataArray(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <HStack width="100%">
        <Tabs variant="unstyled" width="100%">
          <TabPanels>
            <TabPanel w="98%" px="0">
              <TablePagination2
                data={dataArray}
                columns={columns}
                isSearchable={false}
                title="الحجم القياسي للمنتجات"
                hasDelete={true}
                totalRows={generalData.totalRows}
                pageSize={generalData.limit}
                GetDataAPI={GetProductsStandardSizeAPI}
                handleOnPageChange={handleOnPageChange}
                onSort={handleSort}
                setCurrPagData={setCurrPagData}
                currPagData={currPagData}
                haveCreateButton={!seoRole}
                path={`/ProductsStandardSize`}
                buttonTitle="إضافة حجم قياسي"
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </HStack>
    </>
  );
};
