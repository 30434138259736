import React, { useState } from "react";
import { UploadAPI } from "../../../api/HomeAPI";

import {
  Box,
  Divider,
  Flex,
  Text,
  Image,
  Input,
  Center,
  Spinner,
  Button,
} from "@chakra-ui/react";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { InputFieldValidation } from "../../../components/inputField/InputFieldValidation";
import { SelectListMulti } from "../../../components/selectField/SelectListMulti";
import { DatePickerField } from "../../../components/datePickerField/DatePickerField";
import { MainButton } from "../../../components/button/MainButton";
import { TextArea } from "../../../components/textArea/TextArea";
import { Thumb } from "../../../components/thumb/Thumb";
import { MainBox } from "../../../components/mainBox/MainBox";
import { ModalAlert2 } from "../../../components/modalAlert/ModalAlert2";
import clock from "../../../assets/images/iconsSvg/clock.svg";

// import createDOMPurify from "dompurify";
// import { JSDOM } from "jsdom";
export const MediaForm = ({
  errors,
  touched,
  setFieldValue,
  handleBlur,
  onCancelOpen,
  formikValues,
  dirty,
  isValid,
  setDisableSave,
  disableSave,
  dismissSave,
  setDismissSave,
  handleOnSubmit,
  onUploadOpen,
  generalData,
  onUploadClose,
  isUploadOpen,
  setErrorMessage,
  setImageObj,
  onErrorOpen,
  type,
  setCkData,
  ckData,
  setCkDataEn,
  ckDataEn,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  // const window = new JSDOM("").window;
  // const DOMPurify = createDOMPurify(window);

  // console.log("formikValuesssssss", formikValues);

  const download = (e, url) => {
    e.preventDefault();
    // console.log(url);
    fetch(url, {
      method: "GET",
      mode: "no-cors",
      credentials: "include",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          // const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("target", "_blank"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUploadImage = (values) => {
    //alert(JSON.stringify(values, null, 2));
    setIsLoading(true);
    let submitValues = {
      file: values.file,
      alt: values.altAr,
      altEn: values.altEn,
    };
    setErrorMessage("");
    UploadAPI(submitValues)
      .then((res) => {
        setImageObj(res);

        setFieldValue("image", res.id);
        setFieldValue("altImage", res.alt);
        setIsLoading(false);
        onUploadClose();

        setTimeout(() => {
          setFieldValue(`file`, "");
          setFieldValue(`altAr`, "");
          setFieldValue(`altEn`, "");
        }, 300);

        return res;
      })
      .catch((err) => {
        setErrorMessage(err?.response?.data?.message || "هناك خطأ ما");
        onErrorOpen();
      });
  };

  class MyUploadAdapter {
    constructor(loader) {
      // The file loader instance to use during the upload.
      this.loader = loader;
    }

    // Starts the upload process.
    upload() {
      return this.loader.file.then(
        (file) =>
          new Promise((resolve, reject) => {
            this._initRequest();
            this._initListeners(resolve, reject, file);
            this._sendRequest(file);
          })
      );
    }

    // Aborts the upload process.
    abort() {
      if (this.xhr) {
        this.xhr.abort();
      }
    }

    // Initializes the XMLHttpRequest object using the URL passed to the constructor.
    _initRequest() {
      const xhr = (this.xhr = new XMLHttpRequest());

      // Note that your request may look different. It is up to you and your editor
      // integration to choose the right communication channel. This example uses
      // a POST request with JSON as a data structure but your configuration
      // could be different.

      xhr.open("POST", "https://alamin-marble.com:8085/uploads", true);
      xhr.responseType = "json";
    }

    // Initializes XMLHttpRequest listeners.
    _initListeners(resolve, reject, file) {
      const xhr = this.xhr;
      const loader = this.loader;
      const genericErrorText = `Couldn't upload file: ${file.name}.`;

      xhr.addEventListener("error", () => reject(genericErrorText));
      xhr.addEventListener("abort", () => reject());
      xhr.addEventListener("load", () => {
        const response = xhr.response;

        // This example assumes the XHR server's "response" object will come with
        // an "error" which has its own "message" that can be passed to reject()
        // in the upload promise.
        //
        // Your integration may handle upload errors in a different way so make sure
        // it is done properly. The reject() function must be called when the upload fails.
        if (!response || response.error) {
          return reject(
            response && response.error
              ? response.error.message
              : genericErrorText
          );
        }

        // If the upload is successful, resolve the upload promise with an object containing
        // at least the "default" URL, pointing to the image on the server.
        // This URL will be used to display the image in the content. Learn more in the
        // UploadAdapter#upload documentation.
        // console.log("response", response);
        //resolve( response.urls );
        resolve({
          default: response.imageUrl,
        });
      });

      // Upload progress when it is supported. The file loader has the #uploadTotal and #uploaded
      // properties which are used e.g. to display the upload progress bar in the editor
      // user interface.
      if (xhr.upload) {
        xhr.upload.addEventListener("progress", (evt) => {
          if (evt.lengthComputable) {
            loader.uploadTotal = evt.total;
            loader.uploaded = evt.loaded;
          }
        });
      }
    }

    // Prepares the data and sends the request.
    _sendRequest(file) {
      // Prepare the form data.
      const data = new FormData();

      data.append("upload", file);

      // Important note: This is the right place to implement security mechanisms
      // like authentication and CSRF protection. For instance, you can use
      // XMLHttpRequest.setRequestHeader() to set the request headers containing
      // the CSRF token generated earlier by your application.

      // Send the request.
      this.xhr.send(data);
    }
  }

  function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      // Configure the URL to the upload script in your back-end here!
      return new MyUploadAdapter(loader);
    };
  }

  return (
    <>
      <MainBox px={6}>
        <Box
          maxW={["100%", "100%", "80%"]}
          width={["100%", "100%", "80%"]}
          alignSelf="flex-start"
        >
          <InputFieldValidation
            CKEditorSubmitChange="ss"
            label="العنوان"
            inputType="text"
            inputId="title"
            inputName="title"
            errors={errors}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
          />
          <InputFieldValidation
            label="العنوان الإنجليزي"
            inputType="text"
            inputId="titleEn"
            inputName="titleEn"
            errors={errors}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
          />
          {/* 
          <TextArea
            label="النص"
            inputId="description"
            inputName="description"
            placeholder="أضف النص"
            errors={errors}
            touched={touched}
            isRequired
            values={formikValues}
            onChange={setFieldValue}
            onBlur={handleBlur}
          /> */}

          <label className="ckLabel">النص</label>
          <CKEditor
            data={formikValues.description}
            inputId="description"
            inputName="description"
            values={formikValues.description}
            editor={ClassicEditor}
            onReady={(editor) => {}}
            onChange={(event, editor) => {
              setFieldValue("CKEditorSubmitChange", formikValues.title + "");
              const data = editor.getData();
              // console.log("data", data);
              setCkData(`${data}`);
              // console.log({ event, editor, data });
              setFieldValue("description", `${data}`);
            }}
            onBlur={handleBlur}
            onFocus={(event, editor) => {}}
            config={{
              language: {
                ui: "en",
                content: "ar",
                dir: "rtl",
              },
              extraPlugins: [MyCustomUploadAdapterPlugin],
              toolbar: {
                items: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "link",
                  // "bulletedList",
                  // "numberedList",
                  "|",
                  // "blockQuote",
                  "insertTable",
                  "|",
                  "imageUpload",
                  "undo",
                  "redo",
                ],
              },
            }}
          />

          {/* {!!ckData && (
            <div
              // dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(ckData) }}
              dangerouslySetInnerHTML={{ __html: ckData }}
            />
          )} */}
          {/* <TextArea
            label="النص الإنجليزي"
            inputId="descriptionEn"
            inputName="descriptionEn"
            placeholder="أضف النص الإنجليزي"
            errors={errors}
            touched={touched}
            isRequired
            values={formikValues}
            onChange={setFieldValue}
            onBlur={handleBlur}
          /> */}

          <label className="ckLabel">النص الإنجليزي</label>
          <CKEditor
            data={formikValues.descriptionEn}
            inputId="descriptionEn"
            inputName="descriptionEn"
            values={formikValues.descriptionEn}
            editor={ClassicEditor}
            onReady={(editor) => {}}
            onChange={(event, editor) => {
              setFieldValue("CKEditorSubmitChange", formikValues.title + "");
              const data = editor.getData();
              //console.log("data", data);
              setCkDataEn(`${data}`);
              //console.log({ event, editor, data });
              setFieldValue("descriptionEn", `${data}`);
            }}
            onBlur={handleBlur}
            onFocus={(event, editor) => {}}
            config={{
              extraPlugins: [MyCustomUploadAdapterPlugin],
              toolbar: {
                items: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "link",
                  // "bulletedList",
                  // "numberedList",
                  "|",
                  // "blockQuote",
                  "insertTable",
                  "|",
                  "imageUpload",
                  "undo",
                  "redo",
                ],
              },
            }}
          />

          {/* <InputFieldValidation
          label="media Date"
          inputType="date"
          inputId="mediaDate"
          inputName="mediaDate"
          errors={errors}
          touched={touched}
          isRequired
          onChange={setFieldValue}
          values={`2022-08-28`}
          // values={formikValues}
        /> */}
          <DatePickerField
            label="التاريخ"
            inputType="date"
            inputId="mediaDate"
            inputName="mediaDate"
            errors={errors}
            touched={touched}
            format="fullDate"
            isRequired
            values={formikValues.mediaDate}
            onChange={setFieldValue}
            onBlur={handleBlur}
            icon={clock}
          />
          <InputFieldValidation
            label="رابط الفيديو"
            inputType="text"
            inputId="video"
            inputName="video"
            errors={errors}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
          />
          <InputFieldValidation
            label="رابط الخبر"
            inputType="text"
            inputId="mediaLink"
            inputName="mediaLink"
            errors={errors}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
          />
          <InputFieldValidation
            label="رابط slug"
            inputType="text"
            inputId="slug"
            inputName="slug"
            errors={errors}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
          />
          <SelectListMulti
            dropdownItems={[{value: 1, label: 'مفتوح'},{value: 2, label: 'مغلق'}]}
            label="الحالة"
            inputId="flag"
            inputName="flag"
            placeholder="إختيار"
            errors={errors}
            touched={touched}
            isRequired
            values={formikValues?.flag}
            onChange={setFieldValue}
            onBlur={handleBlur}
            uppercase={true}
          />
 

          <Box paddingY="10px">
            {!!generalData?.imageURL && (
              <>
                <Text
                  className="title_sm"
                  display="flex"
                  mb={2}
                  mt={0}
                  component="div"
                  textTransform="uppercase"
                  fontSize="12px"
                >
                  الصورة الحالية
                </Text>
                <Button
                  marginBottom="10px"
                  onClick={(e) => download(e, generalData.imageURL)}
                >
                  عرض الملف
                </Button>
                <Image
                  src={generalData.imageURL}
                  width="200px"
                  height="200px"
                  mb="15px"
                />
              </>
            )}
            <MainButton
              title="رفع الصورة"
              reverseRow={true}
              onPress={() => {
                onUploadOpen();
              }}
            />
          </Box>
          <Box display="none">
            <InputFieldValidation
              label="Image"
              inputType="text"
              inputId="image"
              inputName="image"
              errors={errors}
              touched={touched}
              isRequired
              onChange={setFieldValue}
              values={formikValues}
              isDisabled={true}
            />
          </Box>
          <InputFieldValidation
            label="alt Image"
            inputType="text"
            inputId="altImage"
            inputName="altImage"
            errors={errors}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
            isDisabled={true}
          />
          <InputFieldValidation
            label="alt image En"
            inputType="text"
            inputId="altEn2"
            inputName="altEn2"
            errors={errors}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
            isDisabled={true}
          />
        </Box>
      </MainBox>
      <MainBox px={6}>
        <Box
          maxW={["100%", "100%", "80%"]}
          width={["100%", "100%", "80%"]}
          alignSelf="flex-start"
        >
          <InputFieldValidation
            label="Meta Title"
            inputType="text"
            inputId="metaTitle"
            inputName="metaTitle"
            errors={errors}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
          />
          <InputFieldValidation
            label="Meta Title الإنجليزي"
            inputType="text"
            inputId="metaTitleEn"
            inputName="metaTitleEn"
            errors={errors}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
          />

          <InputFieldValidation
            label="Meta Description"
            inputType="text"
            inputId="metaDesc"
            inputName="metaDesc"
            errors={errors}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
          />
          <InputFieldValidation
            label="Meta Description الإنجليزي"
            inputType="text"
            inputId="metaDescEn"
            inputName="metaDescEn"
            errors={errors}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
          />
          <InputFieldValidation
            label="Meta Keywords"
            inputType="text"
            inputId="metaKeys"
            inputName="metaKeys"
            errors={errors}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
          />
          <InputFieldValidation
            label="الإنجليزية Meta Keywords"
            inputType="text"
            inputId="metaKeysEn"
            inputName="metaKeysEn"
            errors={errors}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
          />
        </Box>
      </MainBox>

      <Flex justifyContent="flex-end" alignItems="center" width="100%">
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="إلغاء"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="إلغاء"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={`/MediaHistory/${type}`}
            />
          )}

          <MainButton
            title="حفظ"
            reverseRow={true}
            disabledBtn={isValid && dirty ? false : true}
            onPress={() => {
              handleOnSubmit(formikValues);
            }}
          />
        </Box>
      </Flex>

      <ModalAlert2
        isOpen={isUploadOpen}
        onClose={onUploadClose}
        title="رفع صورة"
      >
        <Box className="form_wrapper">
          {isLoading ? (
            <Box paddingTop={12} paddingBottom={12}>
              <Center>
                <Spinner />
              </Center>
            </Box>
          ) : (
            <>
              <Input
                paddingTop="5px"
                marginBottom="15px"
                size="md"
                type="file"
                name="file"
                errors={errors}
                touched={touched}
                values={formikValues}
                accept="image/*"
                onChange={(event) => {
                  setFieldValue("file", event.currentTarget.files[0]);
                }}
              />

              <InputFieldValidation
                label="alt"
                inputType="text"
                inputId="altAr"
                inputName="altAr"
                errors={errors}
                touched={touched}
                isRequired
                onChange={setFieldValue}
                values={formikValues}
              />
              <InputFieldValidation
                label="altEn"
                inputType="text"
                inputId="altEn"
                inputName="altEn"
                errors={errors}
                touched={touched}
                isRequired
                onChange={setFieldValue}
                values={formikValues}
              />
              <Box width="200px" height="200px">
                <Thumb file={formikValues?.file} />
              </Box>

              <MainButton
                title="رفع"
                type="submit"
                reverseRow={true}
                onPress={() => {
                  handleUploadImage(formikValues);
                }}
              />
            </>
          )}
        </Box>
      </ModalAlert2>
    </>
  );
};
