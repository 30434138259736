import * as Yup from "yup";

export const validationMediaForm = Yup.object().shape({
  title: Yup.string().required("هذه الخانة مطلوبة"),
  description: Yup.string().required("هذه الخانة مطلوبة"),
  // video: Yup.string().required("هذه الخانة مطلوبة"),
  mediaDate: Yup.string().required("هذه الخانة مطلوبة"),
  // mediaLink: Yup.string().required("هذه الخانة مطلوبة"),
  metaTitle: Yup.string().required("هذه الخانة مطلوبة"),
  metaDesc: Yup.string().required("هذه الخانة مطلوبة"),
  image: Yup.string().required("هذه الخانة مطلوبة"),
  // altImage: Yup.string().required("هذه الخانة مطلوبة"),
});
