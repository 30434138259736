import React from "react";
import {
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

export const ModalAlert2 = ({
  isOpen,
  onClose,
  title,
  desc,
  icon,
  confirmationModal = false,
  disableClose = false,
  isAssetModal = false,
  children,
}) => {
  let modalProps;
  let hideCloseBtn = !disableClose || !confirmationModal ? false : true;

  if (!disableClose) {
    modalProps = {
      onClose: onClose,
    };
  }

  if (isAssetModal) {
    var lastItem = desc[desc.length - 1] || "";
    desc[desc.length - 1] = lastItem.replace(",", ".");
  }

  return (
    <div className={disableClose ? "modal_disableClose" : ""}>
      <Modal isOpen={isOpen} {...modalProps}>
        <ModalOverlay
          background="rgba(29, 31, 34, 0.5)"
          backdropFilter="blur(7px)"
        />
        <ModalContent
          py={12}
          px={4}
          alignSelf="center"
          maxWidth="520px"
          className="modal_content"
        >
          <Center flexDirection="column">
            <ModalHeader
              textAlign="center"
              color="#1D1F22"
              fontSize="20px"
              fontWeight="400"
              letterSpacing="-0.02em"
              px={5}
              mt={2}
              className="modal_header"
            >
              {title}
            </ModalHeader>
          </Center>
          {/* {hideCloseBtn && <ModalCloseButton />} */}
          <ModalCloseButton />
          <ModalBody textAlign="center">
            <Center>{children}</Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};
