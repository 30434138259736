import React, { useState, useEffect, useCallback } from "react";
import { UploadAPI } from "../../../api/HomeAPI";

import {
  Box,
  Divider,
  Flex,
  RadioGroup,
  Radio,
  SimpleGrid,
  GridItem,
  Text,
  Stack,
  Image,
  Input,
  Center,
  Spinner,
  Button,
  Checkbox,
} from "@chakra-ui/react";
import { FieldArray } from "formik";

import { InputFieldValidation } from "../../../components/inputField/InputFieldValidation";
import { MainButton } from "../../../components/button/MainButton";
import { TextArea } from "../../../components/textArea/TextArea";
import { Thumb } from "../../../components/thumb/Thumb";
import { ModalAlert2 } from "../../../components/modalAlert/ModalAlert2";
import { SelectListMulti } from "../../../components/selectField/SelectListMulti";
import { MainBox } from "../../../components/mainBox/MainBox";
import { parseToken } from "../../../utils/Auth";

export const ProductForm = ({
  errors,
  touched,
  setFieldValue,
  handleBlur,
  onCancelOpen,
  formikValues,
  dirty,
  isValid,
  setDisableSave,
  disableSave,
  dismissSave,
  setDismissSave,
  handleOnSubmit,
  onUploadOpen,
  generalData,
  onUploadClose,
  isUploadOpen,
  setErrorMessage,
  setImageObj,
  onErrorOpen,
  dropdownObj,
  setInitFormVal,
  id,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [imageName, setImageName] = useState("");
  const [isCheckBoxSelected, setIsCheckBoxSelected] = useState("");
  const [seoRole, setSeoRole] = useState(
    parseToken().role === "ROLE_MODERATOR"
  );

  const [clearSelect, setClearSelect] = useState(false);
  const detailsInformationGroup = {
    title: "",
    description: "",
    titleEn: "",
    descriptionEn: "",
  };
  const imageGroup = "";
  const colorsGroup = {
    colorID: "",
    images: [imageGroup],
  };
  // console.log("formikValues", formikValues, errors);

  const download = (e, url) => {
    e.preventDefault();
    fetch(url, {
      method: "GET",
      mode: "no-cors",
      credentials: "include",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          // const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("target", "_blank"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /*   useEffect(() => {
    setIsCheckBoxSelected(!isCheckBoxSelected);
  }, [formikValues.mostSelling, formikValues.newArrival]); */
  const handleCheckBox = useCallback((e) => {
    setIsCheckBoxSelected([e.target.checked]);
    setFieldValue(`${e.target.name}`, e.target.checked);
  }, []);

  const handleUploadImage = (values) => {
    // console.log("values", values, values?.file);
    setIsLoading(true);
    let submitValues = {
      file: values.file,
      alt: values.alt,
      altEn: values.altEn,
    };
    setErrorMessage("");
    UploadAPI(submitValues)
      .then((res) => {
        // console.log("upload", res);
        setImageObj(res);

        // console.log("upload 2----", res.id);
        setFieldValue(`${imageName}`, res.id);

        setTimeout(() => {
          setFieldValue(`file`, "");
          setFieldValue(`alt`, "");
          setFieldValue(`altEn`, "");
        }, 300);
        setIsLoading(false);
        onUploadClose();
        // if (res.success === true) {
        //   setDisableSave(true);
        //   setErrorMessage("");
        //   onSubmitOpen();
        // }
        return res;
      })
      .catch((err) => {
        setErrorMessage(err?.response?.data?.message || "هناك خطأ ما");
        onErrorOpen();
      });
  };

  const handleChangeRadio = (event, name) => {
    setFieldValue(name, event);
  };
 
  return (
    <>
      <MainBox px={6}>
        <Box
          maxW={["100%", "100%", "90%"]}
          width={["100%", "100%", "90%"]}
          alignSelf="flex-start"
        >
          <Box>
            <Text
              className="title_sm"
              display="flex"
              mb={5}
              mt={3}
              component="div"
              letterSpacing="0.06em"
              fontSize="18px"
              fontWeight="bold"
            >
              معلومات المنتج
            </Text>
          </Box>
          <InputFieldValidation
            label="إسم المنتج"
            inputType="text"
            inputId="title"
            inputName="title"
            errors={errors}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
          />
          <InputFieldValidation
            label="إسم المنتج بالإنجليزية"
            inputType="text"
            inputId="titleEn"
            inputName="titleEn"
            errors={errors}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
          />
          <InputFieldValidation
            label="رابط slug"
            inputType="text"
            inputId="slug"
            inputName="slug"
            errors={errors}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
          />
          <InputFieldValidation
            label="odoo ID"
            inputType="number"
            inputId="odooID"
            inputName="odooID"
            errors={errors}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
          />

          <TextArea
            label="تفاصيل المنتج"
            inputId="description"
            inputName="description"
            placeholder="إضافة تفاصيل المنتج"
            errors={errors}
            touched={touched}
            isRequired
            values={formikValues}
            onChange={setFieldValue}
            onBlur={handleBlur}
          />
          <TextArea
            label="تفاصيل المنتج بالإنجليزية"
            inputId="descriptionEn"
            inputName="descriptionEn"
            placeholder="إضافة تفاصيل المنتج الإنجليزية"
            errors={errors}
            touched={touched}
            isRequired
            values={formikValues}
            onChange={setFieldValue}
            onBlur={handleBlur}
          />
          <SimpleGrid columns={{ sm: 4, md: 4 }} spacingX="15px" width="100%">
            <GridItem colSpan={4}>
              <SimpleGrid
                columns={{ sm: 4, md: 4 }}
                spacing="15px"
                width="100%"
              >
                <GridItem colSpan={2}>
                  {/* <InputFieldValidation
                  label="countyID"
                  inputType="text"
                  inputId="countyID"
                  inputName="countyID"
                  errors={errors}
                  touched={touched}
                  isRequired
                  onChange={setFieldValue}
                  values={formikValues}
                /> */}
                </GridItem>
                <GridItem colSpan={2}>
                  {/* <InputFieldValidation
                  label="typeID"
                  inputType="text"
                  inputId="typeID"
                  inputName="typeID"
                  errors={errors}
                  touched={touched}
                  isRequired
                  placeholder=""
                  onChange={setFieldValue}
                  values={formikValues}
                /> */}
                </GridItem>
              </SimpleGrid>
            </GridItem>
            <GridItem colSpan={4}>
              <SimpleGrid
                columns={{ sm: 4, md: 4 }}
                spacing="15px"
                width="100%"
              >
                <GridItem colSpan={2}>
                  <InputFieldValidation
                    label="كود المنتج"
                    inputType="text"
                    inputId="code"
                    inputName="code"
                    errors={errors}
                    touched={touched}
                    isRequired
                    onChange={setFieldValue}
                    values={formikValues}
                  />
                </GridItem>
                <GridItem colSpan={2}>
                  <InputFieldValidation
                    label="الوحدة"
                    inputType="text"
                    inputId="unite"
                    inputName="unite"
                    errors={errors}
                    touched={touched}
                    isRequired
                    placeholder=""
                    onChange={setFieldValue}
                    values={formikValues}
                  />
                </GridItem>
              </SimpleGrid>
            </GridItem>
            <GridItem colSpan={4} marginBottom="20px">
              <SimpleGrid
                columns={{ sm: 4, md: 4 }}
                spacing="15px"
                width="100%"
              >
                <GridItem colSpan={2}>
                  {!!formikValues.mostSelling && !!id && (
                    <Checkbox
                      colorScheme="green"
                      name="mostSelling"
                      onChange={handleCheckBox}
                      value={formikValues.mostSelling}
                      defaultChecked={formikValues.mostSelling}
                    >
                      <Text>الأكثر مبيعاً</Text>
                    </Checkbox>
                  )}
                  {!formikValues.mostSelling && !!id && (
                    <Checkbox
                      colorScheme="green"
                      name="mostSelling"
                      onChange={handleCheckBox}
                      value={formikValues.mostSelling}
                      defaultChecked={formikValues.mostSelling}
                    >
                      <Text>الأكثر مبيعاً</Text>
                    </Checkbox>
                  )}
                  {!id && (
                    <Checkbox
                      colorScheme="green"
                      name="mostSelling"
                      onChange={handleCheckBox}
                      value={formikValues.mostSelling}
                      defaultChecked={formikValues.mostSelling}
                    >
                      <Text>الأكثر مبيعاً</Text>
                    </Checkbox>
                  )}

                  {!!formikValues.newArrival && !!id && (
                    <Checkbox
                      colorScheme="green"
                      marginRight="30px"
                      name="newArrival"
                      onChange={handleCheckBox}
                      value={formikValues.newArrival}
                      defaultChecked={formikValues.newArrival}
                    >
                      <Text>وصل حديثاً</Text>
                    </Checkbox>
                  )}
                  {!formikValues.newArrival && !!id && (
                    <Checkbox
                      colorScheme="green"
                      marginRight="30px"
                      name="newArrival"
                      onChange={handleCheckBox}
                      value={formikValues.newArrival}
                      defaultChecked={formikValues.newArrival}
                    >
                      <Text>وصل حديثاً</Text>
                    </Checkbox>
                  )}
                  {!id && (
                    <Checkbox
                      colorScheme="green"
                      marginRight="30px"
                      name="newArrival"
                      onChange={handleCheckBox}
                      value={formikValues.newArrival}
                      defaultChecked={formikValues.newArrival}
                    >
                      <Text>وصل حديثاً</Text>
                    </Checkbox>
                  )}

                  {/* <Checkbox
                  colorScheme="green"
                  name="newArrival"
                  onChange={handleCheckBox}
                  value={formikValues.newArrival}
                  defaultChecked={formikValues.newArrival}
                >
                  <Text>new Arrival</Text>
                </Checkbox> */}
                  {/* <InputFieldValidation
                  label="mostSelling"
                  inputType="text"
                  inputId="mostSelling"
                  inputName="mostSelling"
                  errors={errors}
                  touched={touched}
                  isRequired
                  placeholder=""
                  onChange={setFieldValue}
                  values={formikValues}
                /> */}
                </GridItem>
              </SimpleGrid>
            </GridItem>
          </SimpleGrid>
                
          <SelectListMulti
            dropdownItems={dropdownObj.prodCountry}
            label="الدولة"
            inputId="countyID"
            inputName="countyID"
            placeholder="إختيار"
            errors={errors}
            touched={touched}
            isRequired
            values={formikValues?.countyID}
            onChange={setFieldValue}
            onBlur={handleBlur}
            uppercase={true}
          />
          <SelectListMulti
            dropdownItems={dropdownObj.prodType}
            label="النوع"
            inputId="typeID"
            inputName="typeID"
            placeholder="Select"
            errors={errors}
            touched={touched}
            isRequired
            values={formikValues?.typeID}
            onChange={setFieldValue}
            onBlur={handleBlur}
            uppercase={true}
          />
          <SelectListMulti
            label="الأحجام"
            placeholder="Select"
            inputId="sizes"
            inputName="sizes"
            errors={errors}
            touched={touched}
            isRequired
            values={formikValues?.sizes}
            onChange={setFieldValue}
            onBlur={handleBlur}
            uppercase={true}
            isMulti={true}
            errorHeight0={true}
            clearMulti={clearSelect}
            setClearSelect={setClearSelect}
            dropdownItems={dropdownObj.sizes}
          />

          {formikValues?.sizes?.includes(2) && (
            <SelectListMulti
              label="مقاسات ستاندرد"
              placeholder="Select"
              inputId="standardSize_id"
              inputName="standardSize_id"
              errors={errors}
              touched={touched}
              isRequired
              values={formikValues?.standardSize_id}
              onChange={setFieldValue}
              onBlur={handleBlur}
              uppercase={true}
              isMulti={true}
              errorHeight0={true}
              clearMulti={clearSelect}
              setClearSelect={setClearSelect}
              dropdownItems={dropdownObj?.standardSize}
            />
          )}

          {formikValues?.sizes?.includes(1) && (
            <SelectListMulti
              label="الواح"
              placeholder="Select"
              inputId="thickness_id"
              inputName="thickness_id"
              errors={errors}
              touched={touched}
              isRequired
              values={formikValues?.thickness_id}
              onChange={setFieldValue}
              onBlur={handleBlur}
              uppercase={true}
              isMulti={true}
              errorHeight0={true}
              clearMulti={clearSelect}
              setClearSelect={setClearSelect}
              dropdownItems={dropdownObj?.thickness}
            />
          )}

          {/* <SelectListMulti
          dropdownItems={dropdownObj.colors}
          label="color ID"
          inputId="color_id"
          inputName="color_id"
          placeholder="Select"
          errors={errors}
          touched={touched}
          isRequired
          values={formikValues?.color_id}
          onChange={setFieldValue}
          onBlur={handleBlur}
          uppercase={true}
        /> */}
        </Box>
      </MainBox>
      <MainBox px={6}>
        <Box
          maxW={["100%", "100%", "90%"]}
          width={["100%", "100%", "90%"]}
          alignSelf="flex-start"
        >
          <Box>
            <Text
              className="title_sm"
              display="flex"
              mb={5}
              mt={3}
              component="div"
              letterSpacing="0.06em"
              fontSize="18px"
              fontWeight="bold"
            >
              معلومات إضافية
            </Text>
          </Box>

          <Box
            maxW={["100%", "100%", "100%"]}
            width={["100%", "100%", "100%"]}
            alignSelf="flex-start"
            className="addfield_wrapper"
            marginBottom="25px"
          >
            <FieldArray name="detailsInformation">
              {({ push, remove }) => (
                <Box container spacing={2} sx={{ marginTop: 2, paddingX: 2 }}>
                  {formikValues?.detailsInformation?.map((_, index) => {
                    return (
                      <Box
                        display="flex"
                        alignItems="center"
                        pb={4}
                        className={index == 0 ? "addfieldSec" : ""}
                        key={`detailsInformation_${index}`}
                      >
                        <SimpleGrid
                          columns={{ sm: 4, md: 4 }}
                          spacingX="15px"
                          width="100%"
                        >
                          <GridItem colSpan={4}>
                            <SimpleGrid
                              columns={{ sm: 2, md: 2 }}
                              spacing="15px"
                              width="95%"
                            >
                              <GridItem>
                                <InputFieldValidation
                                  label="عنوان المعلومة"
                                  inputType="text"
                                  inputId={`detailsInformation.${index}.title`}
                                  inputName={`detailsInformation.${index}.title`}
                                  errors={errors}
                                  touched={touched}
                                  isRequired
                                  minNum="0"
                                  placeholder=""
                                  onChange={setFieldValue}
                                  values={
                                    formikValues?.detailsInformation[index]
                                      .title
                                  }
                                  directVal={true}
                                />
                              </GridItem>
                              <GridItem>
                                <TextArea
                                  label="نص المعلومة"
                                  inputId={`detailsInformation.${index}.description`}
                                  inputName={`detailsInformation.${index}.description`}
                                  placeholder="إضافة نص المعلومة"
                                  errors={errors}
                                  touched={touched}
                                  isRequired
                                  values={
                                    formikValues?.detailsInformation[index]
                                      .description
                                  }
                                  onChange={setFieldValue}
                                  onBlur={handleBlur}
                                  directVal={true}
                                />
                                {/*  <InputFieldValidation
                                  label="description"
                                  inputType="text"
                                  inputId={`detailsInformation.${index}.description`}
                                  inputName={`detailsInformation.${index}.description`}
                                  errors={errors}
                                  touched={touched}
                                  isRequired
                                  minNum="0"
                                  placeholder=""
                                  onChange={setFieldValue}
                                  values={
                                    formikValues?.detailsInformation[index]
                                      .description
                                  }
                                  directVal={true}
                                /> */}
                              </GridItem>
                              <GridItem>
                                <InputFieldValidation
                                  label="عنوان المعلومة بالانجليزية"
                                  inputType="text"
                                  inputId={`detailsInformation.${index}.titleEn`}
                                  inputName={`detailsInformation.${index}.titleEn`}
                                  errors={errors}
                                  touched={touched}
                                  isRequired
                                  minNum="0"
                                  placeholder=""
                                  onChange={setFieldValue}
                                  values={
                                    formikValues?.detailsInformation[index]
                                      .titleEn
                                  }
                                  directVal={true}
                                />
                              </GridItem>
                              <GridItem>
                                <TextArea
                                  label="تفاصيل المعلومة بالانجليزية"
                                  inputId={`detailsInformation.${index}.descriptionEn`}
                                  inputName={`detailsInformation.${index}.descriptionEn`}
                                  placeholder="إضافة نص المعلومة بالإنجليزية"
                                  errors={errors}
                                  touched={touched}
                                  isRequired
                                  values={
                                    formikValues?.detailsInformation[index]
                                      .descriptionEn
                                  }
                                  onChange={setFieldValue}
                                  onBlur={handleBlur}
                                />
                                {/* <InputFieldValidation
                                  label="descriptionEn"
                                  inputType="text"
                                  inputId={`detailsInformation.${index}.descriptionEn`}
                                  inputName={`detailsInformation.${index}.descriptionEn`}
                                  errors={errors}
                                  touched={touched}
                                  isRequired
                                  minNum="0"
                                  placeholder=""
                                  onChange={setFieldValue}
                                  values={
                                    formikValues?.detailsInformation[index]
                                      .descriptionEn
                                  }
                                  directVal={true}
                                /> */}
                              </GridItem>
                            </SimpleGrid>
                          </GridItem>
                        </SimpleGrid>
                        <Box>
                          {index > 0 && !seoRole && (
                            <Box>
                              <Button
                                fontColor="red"
                                onClick={() => {
                                  remove(index);
                                }}
                              >
                                حذف
                              </Button>
                              {/*  <MainButton
                              title=""
                              icon={deleteIcon2}
                              btnStyle="transparent"
                              // disabledBtn={isRestore}
                              fontColor="danger"
                              fSize="sm"
                              onPress={() => {
                                remove(index);
                                handleRemoveWarning();
                              }}
                            /> */}
                            </Box>
                          )}
                        </Box>
                      </Box>
                    );
                  })}
                  <Box>
                    {/* <MainButton
                    title="Add room type"
                    btnStyle="transparent"
                    icon={plus}
                    fontColor="dark"
                    disabledBtn={
                      addDisabled || isRestore
                        ? true
                        : formikValues?.detailsInformation?.length >= 10
                        ? true
                        : false
                    }
                    onPress={() => {
                      push(detailsInformationGroup);
                      handleNewlyAdded();
                    }}
                  /> */}
                    <Button
                      fontColor="blue"
                      onClick={() => {
                        push(detailsInformationGroup);
                      }}
                    >
                      أضف معلومات عامة
                    </Button>
                    {/* <Button
                    variant="outlined"
                    onClick={() => push(detailsInformationGroup)}
                  >
                    Add room type
                  </Button> */}
                  </Box>
                </Box>
              )}
            </FieldArray>
          </Box>
        </Box>
      </MainBox>
      <MainBox px={6}>
        <Box
          maxW={["100%", "100%", "90%"]}
          width={["100%", "100%", "90%"]}
          alignSelf="flex-start"
        >
          <Box
            maxW={["100%", "100%", "100%"]}
            width={["100%", "100%", "100%"]}
            alignSelf="flex-start"
            className="addfield_wrapper"
          >
            <Box container spacing={2} sx={{ marginTop: 2, paddingX: 2 }}>
              <Box>
                <Text
                  className="title_sm"
                  display="flex"
                  mb={5}
                  mt={3}
                  component="div"
                  letterSpacing="0.06em"
                  fontSize="18px"
                  fontWeight="bold"
                >
                  إضافة صور و الوان
                </Text>
              </Box>
              {formikValues?.colors?.map((element, index) => {
                let placeHolder = dropdownObj.colors.filter((el) => {
                  // console.log(
                  //   "el.value === formikValues?.roomType[index].type",
                  //   el.value,
                  //   formikValues?.colors[index].colorID
                  // );
                  return el.value === formikValues?.colors[index].colorID;
                });
                return (
                  <Box
                    display="flex"
                    alignItems="center"
                    pb={4}
                    className={index == 0 ? "addfieldSec" : ""}
                    key={`colors_${index}`}
                  >
                    <SimpleGrid
                      columns={{ sm: 4, md: 4 }}
                      spacingX="15px"
                      width="100%"
                    >
                      <GridItem colSpan={4}>
                        <SimpleGrid
                          columns={{ sm: 1, md: 1 }}
                          spacing="15px"
                          width="100%"
                        >
                          <GridItem display="flex" alignItems="center">
                            <Box flex="1">
                              <SelectListMulti
                                key={`myUniqueKeyColors_${index}`}
                                dropdownItems={dropdownObj.colors}
                                label="اللون"
                                inputId={`colors.${index}.colorID`}
                                inputName={`colors.${index}.colorID`}
                                placeH={
                                  formikValues?.colors[index].colorID === ""
                                    ? "إختيار"
                                    : placeHolder[0]?.label
                                }
                                errors={errors}
                                touched={touched}
                                isRequired
                                values={formikValues?.colors[index].colorID}
                                onChange={setFieldValue}
                                onBlur={handleBlur}
                                uppercase={true}
                                isDynamic={true}
                                hideSelected={false}
                                placeHolderValue={false}
                              />
                            </Box>
                            {index >= 0 && !seoRole && (
                              <Box>
                                <Button
                                  fontColor="red"
                                  height="50px"
                                  marginLeft="10px"
                                  marginTop="12px"
                                  onClick={() => {
                                    let newColors = formikValues.colors;
                                    // newColors.push({colorID:""})
                                    newColors.splice(index, 1);
                                    setInitFormVal({
                                      ...formikValues,
                                      colors: newColors,
                                    });

                                    setFieldValue(
                                      "title",
                                      formikValues.title + " "
                                    );
                                    // remove(index);
                                  }}
                                >
                                  حذف
                                </Button>
                              </Box>
                            )}
                            {/*  <InputFieldValidation
                            label="title"
                            inputType="text"
                            inputId={`colors.${index}.colorID`}
                            inputName={`colors.${index}.colorID`}
                            errors={errors}
                            touched={touched}
                            isRequired
                            minNum="0"
                            placeholder=""
                            onChange={setFieldValue}
                            values={formikValues?.colors[index].colorID}
                            directVal={true}
                          /> */}
                          </GridItem>
                          <GridItem>
                            <Box paddingY="10px">
                              <Box>
                                <Button
                                  fontColor="blue"
                                  marginBottom="10px"
                                  onClick={() => {
                                    // let newImage = formikValues.colors[index].imageID
                                    formikValues.colors[index].imageID.push("");
                                    formikValues?.colorsArr[
                                      index
                                    ]?.imageID?.push("");

                                    // let newColors = {...formikValues.colors,imageID:newImage}
                                    setInitFormVal({
                                      ...formikValues,
                                    });
                                    // push(imageGroup);
                                  }}
                                >
                                  إضافة صورة
                                </Button>
                              </Box>
                              <Box
                                container
                                spacing={2}
                                sx={{ marginTop: 2, paddingX: 2 }}
                                display="flex"
                                overflowX="scroll"
                              >
                                {element?.imageID?.map((image, ind) => {
                                  let imageUrl = "";
                                  if (formikValues?.colorsArr) {
                                    imageUrl =
                                      formikValues?.colorsArr[index]?.imageID[
                                        ind
                                      ]?.image?.url;
                                  }
                                  // console.log(
                                  //   "element?.imageID[ind]?.image?.alt",
                                  //   generalData?.colors[index]?.imageID[ind]
                                  //     .image.alt
                                  // );
                                  // console.log("image", image, element, imageUrl);
                                  return (
                                    <Box
                                      display="flex"
                                      alignItems="flex-start"
                                      pb={4}
                                      key={`imageID_${ind}`}
                                      width="150px"
                                      minWidth="150px"
                                      flexWrap="wrap"
                                    >
                                      <SimpleGrid
                                        columns={{ sm: 4, md: 4 }}
                                        spacingX="15px"
                                        width="100%"
                                      >
                                        <GridItem colSpan={4}>
                                          <SimpleGrid
                                            columns={{ sm: 1, md: 1 }}
                                            spacing="15px"
                                            width="95%"
                                          >
                                            <GridItem>
                                              <Box paddingY="10px">
                                                {!!imageUrl && (
                                                  <>
                                                    <Text
                                                      className="title_sm"
                                                      display="flex"
                                                      mb={2}
                                                      mt={0}
                                                      component="div"
                                                      textTransform="uppercase"
                                                      fontSize="12px"
                                                    >
                                                      الصورة الحالية
                                                    </Text>
                                                    <Button
                                                      marginBottom="10px"
                                                      onClick={(e) =>
                                                        download(e, imageUrl)
                                                      }
                                                    >
                                                      عرض الملف
                                                    </Button>
                                                    <Image
                                                      src={imageUrl}
                                                      width="150px"
                                                      height="150px"
                                                      mb="15px"
                                                    />
                                                  </>
                                                )}
                                                <Box display="none">
                                                  <InputFieldValidation
                                                    label="title"
                                                    inputType="text"
                                                    inputId={`colors.${index}.imageID.${ind}`}
                                                    inputName={`colors.${index}.imageID.${ind}`}
                                                    errors={errors}
                                                    touched={touched}
                                                    isRequired
                                                    minNum="0"
                                                    placeholder=""
                                                    onChange={setFieldValue}
                                                    values={
                                                      element?.imageID[ind]
                                                    }
                                                    directVal={true}
                                                    isDisabled={true}
                                                  />
                                                </Box>
                                                {JSON.stringify(generalData) !==
                                                "{}" ? (
                                                  <>
                                                    {!!generalData?.colors[
                                                      index
                                                    ]?.imageID[ind]?.image
                                                      ?.alt ? (
                                                      <>
                                                        <InputFieldValidation
                                                          label="alt"
                                                          inputType="text"
                                                          inputId={`colors.${index}.imageID.${ind}.image.alt`}
                                                          inputName={`colors.${index}.imageID.${ind}.image.alt`}
                                                          errors={errors}
                                                          touched={touched}
                                                          isRequired
                                                          onChange={
                                                            setFieldValue
                                                          }
                                                          values={
                                                            generalData?.colors[
                                                              index
                                                            ]?.imageID[ind]
                                                              ?.image?.alt
                                                          }
                                                          isDisabled={true}
                                                          directVal={true}
                                                        />
                                                        <InputFieldValidation
                                                          label="alt En"
                                                          inputType="text"
                                                          inputId={`colors.${index}.imageID.${ind}.image.altEn`}
                                                          inputName={`colors.${index}.imageID.${ind}.image.altEn`}
                                                          errors={errors}
                                                          touched={touched}
                                                          isRequired
                                                          onChange={
                                                            setFieldValue
                                                          }
                                                          values={
                                                            generalData?.colors[
                                                              index
                                                            ]?.imageID[ind]
                                                              ?.image?.altEn
                                                          }
                                                          isDisabled={true}
                                                          directVal={true}
                                                        />
                                                      </>
                                                    ) : (
                                                      <Box>صورة</Box>
                                                    )}
                                                  </>
                                                ) : (
                                                  <Box>صورة</Box>
                                                )}

                                                {!element?.imageID[ind] && (
                                                  <MainButton
                                                    title="رفع صورة"
                                                    reverseRow={true}
                                                    onPress={() => {
                                                      setImageName(
                                                        `colors.${index}.imageID.${ind}`
                                                      );
                                                      onUploadOpen();
                                                    }}
                                                  />
                                                )}
                                              </Box>
                                            </GridItem>
                                          </SimpleGrid>
                                        </GridItem>
                                      </SimpleGrid>
                                      <Box>
                                        {ind >= 0 && !seoRole && (
                                          <Box>
                                            <Button
                                              fontColor="red"
                                              onClick={() => {
                                                // console.log(
                                                //   "formik",
                                                //   formikValues
                                                // );
                                                let newColors =
                                                  formikValues.colors;
                                                let newColorsArr =
                                                  formikValues.colorsArr;
                                                // newColors.push({colorID:""})
                                                newColors[index].imageID.splice(
                                                  ind,
                                                  1
                                                );
                                                newColorsArr[
                                                  index
                                                ]?.imageID?.splice(ind, 1);
                                                setInitFormVal({
                                                  ...formikValues,
                                                  colors: newColors,
                                                  colorsArr: newColorsArr,
                                                });

                                                setFieldValue(
                                                  "title",
                                                  formikValues.title + " "
                                                );
                                                // console.log(
                                                //   "formikValues.title",
                                                //   formikValues.title,
                                                //   errors
                                                // );
                                                // remove(index);
                                              }}
                                            >
                                              حذف
                                            </Button>
                                          </Box>
                                        )}
                                      </Box>
                                    </Box>
                                  );
                                })}
                              </Box>
                            </Box>
                          </GridItem>
                        </SimpleGrid>
                      </GridItem>
                    </SimpleGrid>
                    <Box></Box>
                  </Box>
                );
              })}
              <Box>
                <Button
                  fontColor="blue"
                  onClick={() => {
                    let newColors = formikValues.colors;
                    newColors.push({ colorID: "", imageID: [] });
                    setInitFormVal({ ...formikValues, colors: newColors });
                    // push(colorsGroup);
                  }}
                >
                  إضافة لون
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </MainBox>
      <MainBox px={6}>
        <Box
          maxW={["100%", "100%", "90%"]}
          width={["100%", "100%", "90%"]}
          alignSelf="flex-start"
        >
          <Box>
            <Text
              className="title_sm"
              display="flex"
              mb={5}
              mt={3}
              component="div"
              letterSpacing="0.06em"
              fontSize="18px"
              fontWeight="bold"
            >
              تفاصيل Meta
            </Text>
          </Box>
          <InputFieldValidation
            label="Meta Title"
            inputType="text"
            inputId="metaTitle"
            inputName="metaTitle"
            errors={errors}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
          />
          <InputFieldValidation
            label="Meta Title الإنجليزي"
            inputType="text"
            inputId="metaTitleEn"
            inputName="metaTitleEn"
            errors={errors}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
          />

          <TextArea
            label="Meta Description"
            inputId="metaDescription"
            inputName="metaDescription"
            placeholder="Add a meta description"
            errors={errors}
            touched={touched}
            isRequired
            values={formikValues}
            onChange={setFieldValue}
            onBlur={handleBlur}
          />
          <TextArea
            label="Meta Description الإنجليزي"
            inputId="metaDescriptionEn"
            inputName="metaDescriptionEn"
            placeholder="Add a meta description En"
            errors={errors}
            touched={touched}
            isRequired
            values={formikValues}
            onChange={setFieldValue}
            onBlur={handleBlur}
          />
          <TextArea
            label="Meta Keywords"
            inputId="metaKeys"
            inputName="metaKeys"
            placeholder="أضف الكلمات المفتاحية"
            errors={errors}
            touched={touched}
            isRequired
            values={formikValues}
            onChange={setFieldValue}
            onBlur={handleBlur}
          />
          <TextArea
            label="الإنجليزية Meta Keywords"
            inputId="metaKeysEn"
            inputName="metaKeysEn"
            placeholder="أضف الكلمات المفتاحية الإنجليزية"
            errors={errors}
            touched={touched}
            isRequired
            values={formikValues}
            onChange={setFieldValue}
            onBlur={handleBlur}
          />

          {/* <Box paddingY="10px">
          {!!generalData?.imageURL && (
            <>
              <Text
                className="title_sm"
                display="flex"
                mb={2}
                mt={0}
                component="div"
                textTransform="uppercase"
                fontSize="12px"
              >
                CURRENT IMAGE
              </Text>
              <Image
                src={generalData.imageURL}
                width="150px"
                height="150px"
                mb="15px"
              />
            </>
          )}
          <MainButton
            title="Upload Image"
            reverseRow={true}
            onPress={() => {
              onUploadOpen();
            }}
          />
        </Box> */}
          {/* <InputFieldValidation
          label="Image"
          inputType="text"
          inputId="image"
          inputName="image"
          errors={errors}
          touched={touched}
          isRequired
          onChange={setFieldValue}
          values={formikValues}
          isDisabled={true}
        /> */}

          {/* <Text
          className="title_sm"
          display="flex"
          mb={2}
          mt={0}
          component="div"
          textTransform="uppercase"
          fontSize="12px"
        >
          title Font Size?
        </Text>
        <RadioGroup
          onChange={(event) => handleChangeRadio(event, "titleFontSize")}
          value={`${formikValues?.titleFontSize}`}
          className="radio_group_custom"
          mb={6}
          name="titleFontSize"
        >
          <Stack direction="row">
            <Radio
              color="#003865 !important"
              bg="none !important"
              borderColor="#6F767E !important"
              value="Large"
            >
              Large
            </Radio>
            <Radio
              color="#003865 !important"
              bg="none !important"
              borderColor="#6F767E !important"
              value="Small"
            >
              Small
            </Radio>
          </Stack>
        </RadioGroup> */}
        </Box>
      </MainBox>

      <Flex justifyContent="flex-end" alignItems="center" width="100%">
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="إلغاء"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="إلغاء"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={`/ProductsHistory`}
            />
          )}

          <MainButton
            title="حفظ"
            reverseRow={true}
            disabledBtn={isValid && dirty ? false : true}
            onPress={() => {
              handleOnSubmit(formikValues);
            }}
          />
        </Box>
      </Flex>

      <ModalAlert2
        isOpen={isUploadOpen}
        onClose={onUploadClose}
        title="رفع صورة"
      >
        <Box className="form_wrapper">
          {isLoading ? (
            <Box paddingTop={12} paddingBottom={12}>
              <Center>
                <Spinner />
              </Center>
            </Box>
          ) : (
            <>
              <Input
                paddingTop="5px"
                marginBottom="15px"
                size="md"
                type="file"
                name="file"
                errors={errors}
                touched={touched}
                values={formikValues}
                accept="image/*"
                onChange={(event) => {
                  setFieldValue("file", event.currentTarget.files[0]);
                }}
              />

              <InputFieldValidation
                label="alt"
                inputType="text"
                inputId="alt"
                inputName="alt"
                errors={errors}
                touched={touched}
                isRequired
                onChange={setFieldValue}
                values={formikValues}
              />
              <InputFieldValidation
                label="altEn"
                inputType="text"
                inputId="altEn"
                inputName="altEn"
                errors={errors}
                touched={touched}
                isRequired
                onChange={setFieldValue}
                values={formikValues}
              />

              <Box width="150px" height="150px">
                <Thumb file={formikValues?.file} />
              </Box>

              <MainButton
                title="رفع"
                type="submit"
                reverseRow={true}
                onPress={() => {
                  handleUploadImage(formikValues);
                }}
              />
            </>
          )}
        </Box>
      </ModalAlert2>
    </>
  );
};
