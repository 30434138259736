import React, { useState, useCallback } from "react";
import { UploadAPI } from "../../api/HomeAPI";

import {
  Box,
  Divider,
  Flex,
  SimpleGrid,
  GridItem,
  Text,
  Image,
  Input,
  Center,
  Spinner,
  Button,
  Checkbox,
} from "@chakra-ui/react";

import { InputFieldValidation } from "../../components/inputField/InputFieldValidation";
import { MainButton } from "../../components/button/MainButton";
import { TextArea } from "../../components/textArea/TextArea";
import { Thumb } from "../../components/thumb/Thumb";
import { ModalAlert2 } from "../../components/modalAlert/ModalAlert2";
import { MainBox } from "../../components/mainBox/MainBox";
import { DatePickerField } from "../../components/datePickerField/DatePickerField";
import { SelectListMulti } from "../../components/selectField/SelectListMulti";
import clock from "../../assets/images/iconsSvg/clock.svg";

export const ProjectsForm = ({
  errors,
  touched,
  setFieldValue,
  handleBlur,
  onCancelOpen,
  formikValues,
  dirty,
  isValid,
  setDisableSave,
  disableSave,
  dismissSave,
  setDismissSave,
  handleOnSubmit,
  onUploadOpen,
  setInitFormVal,
  generalData,
  onUploadClose,
  isUploadOpen,
  setErrorMessage,
  setImageObj,
  onErrorOpen,
  // allProducts,
  // setAllProducts,
  allProducts2,
  setAllProducts2,
}) => {
  // products = [
  //   { title: "emad", id: "3", clicked: true },
  //   { title: "ahmad", id: "6", clicked: false },
  // ];
  // console.log("products", allProducts);
  //console.log("products 2w", allProducts2, formikValues?.products);
  const [isCheckBoxSelected, setIsCheckBoxSelected] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [imageFile, setImageFile] = useState();
  const [selectedImage, setSelectedImage] = useState("");

  const [clearSelect, setClearSelect] = useState(false);
  //console.log(errors);

  /*  const handleCheckBox = (e) => {
    console.log("e", e.target.name);
    console.log("products2", allProducts);
    if (allProducts.length > 0) {
      let prod = [...allProducts];
      prod = allProducts.map((el) => {
        // console.log("el", el.id, e.target.name);
        if (el.id == e.target.name) {
          // console.log("el", "truuuuuuuuuuuuuuuuuuuue", el);
          return {
            ...el,
            clicked: !el.clicked,
          };
        } else {
          return el;
        }
      });
      console.log("prod", prod);
      setFieldValue(`title`, formikValues.title + " ");
      // // setFieldValue(`products`, prod);
      setAllProducts(prod);

      setIsCheckBoxSelected([e.target.checked]);
    }
    // setFieldValue(`${e.target.name}`, e.target.checked);
  }; */

  const download = (e, url) => {
    e.preventDefault();
    fetch(url, {
      method: "GET",
      mode: "no-cors",
      credentials: "include",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          // const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("target", "_blank"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUploadImage = (values) => {
    //alert(JSON.stringify(values, null, 2));
    //console.log("values", values, values?.file);
    setIsLoading(true);
    let submitValues = {
      file: values.file,
      alt: values.alt,
      altEn: values.altEn,
    };
    //console.log("submitValues", submitValues);
    setErrorMessage("");
    UploadAPI(submitValues)
      .then((res) => {
        //console.log("upload", res);
        setImageObj(res);

        setFieldValue(`image${selectedImage}`, res.id);
        setFieldValue(`alt${selectedImage}`, res.alt);
        setTimeout(() => {
          setImageFile("");
          setFieldValue(`alt`, "");
          setFieldValue(`altEn`, "");
        }, 300);
        setIsLoading(false);
        onUploadClose();
        // if (res.success === true) {
        //   setDisableSave(true);
        //   setErrorMessage("");
        //   onSubmitOpen();
        // }
        return res;
      })
      .catch((err) => {
        setErrorMessage(err?.response?.data?.message || "هناك خطأ ما");
        onErrorOpen();
      });
  };

  return (
    <>
      <MainBox px={6}>
        <Box
          maxW={["100%", "100%", "40%"]}
          width={["100%", "100%", "40%"]}
          alignSelf="flex-start"
        >
          <InputFieldValidation
            label="اسم المشروع"
            inputType="text"
            inputId="title"
            inputName="title"
            errors={errors}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
          />
          <InputFieldValidation
            label="اسم المشروع الإنجليزي"
            inputType="text"
            inputId="titleEn"
            inputName="titleEn"
            errors={errors}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
          />
          <InputFieldValidation
            label="موقع المشروع"
            inputType="text"
            inputId="address"
            inputName="address"
            errors={errors}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
          />
          <InputFieldValidation
            label="موقع المشروع الإنجليزي"
            inputType="text"
            inputId="addressEn"
            inputName="addressEn"
            errors={errors}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
          />
          <DatePickerField
            label="سنة التوريد"
            inputType="date"
            inputId="yearOfSupply"
            inputName="yearOfSupply"
            errors={errors}
            touched={touched}
            isRequired
            values={formikValues.yearOfSupply}
            onChange={setFieldValue}
            onBlur={handleBlur}
            minDate={new Date("01-01-1950")}
            maxDate={new Date("01-01-2090")}
            icon={clock}
          />

          <TextArea
            label="التفاصيل"
            inputId="details"
            inputName="details"
            placeholder="أضف تفاصيل المشروع"
            errors={errors}
            touched={touched}
            isRequired
            values={formikValues}
            onChange={setFieldValue}
            onBlur={handleBlur}
          />
          <TextArea
            label="التفاصيل بالانجليزي"
            inputId="detailsEn"
            inputName="detailsEn"
            placeholder="أضف تفاصيل المشروع الإنجليزية"
            errors={errors}
            touched={touched}
            isRequired
            values={formikValues}
            onChange={setFieldValue}
            onBlur={handleBlur}
          />
          <InputFieldValidation
            label="إسم المقاول"
            inputType="text"
            inputId="contractor"
            inputName="contractor"
            errors={errors}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
          />
          <InputFieldValidation
            label="إسم المقاول بالإنجليزية"
            inputType="text"
            inputId="contractorEn"
            inputName="contractorEn"
            errors={errors}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
          />
          <InputFieldValidation
            label="إسم المالك"
            inputType="text"
            inputId="owner"
            inputName="owner"
            errors={errors}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
          />
          <InputFieldValidation
            label="إسم المالك بالإنجليزية"
            inputType="text"
            inputId="ownerEn"
            inputName="ownerEn"
            errors={errors}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
          />
          {/* <InputFieldValidation
          label="owner"
          inputType="text"
          inputId="owner"
          inputName="owner"
          errors={errors}
          touched={touched}
          isRequired
          onChange={setFieldValue}
          values={formikValues}
        /> */}
        </Box>
        <Box width="100%">
          <SimpleGrid columns={{ sm: 6, md: 6 }} spacingX="15px" width="100%">
            <GridItem colSpan={6}>
              <SimpleGrid
                columns={{ sm: 6, md: 6 }}
                spacing="15px"
                width="100%"
              >
                <GridItem colSpan={2}>
                  <Box paddingY="10px">
                    {!!generalData?.image1URL && (
                      <>
                        <Text
                          className="title_sm"
                          display="flex"
                          mb={2}
                          mt={0}
                          component="div"
                          textTransform="uppercase"
                          fontSize="12px"
                        >
                          الصورة الحالية
                        </Text>
                        <Box>
                          <Button
                            marginBottom="10px"
                            onClick={(e) => download(e, generalData.image1URL)}
                          >
                            عرض الملف
                          </Button>
                          <Image
                            src={generalData.image1URL}
                            width="200px"
                            height="200px"
                            mb="15px"
                          />
                          <Box display="none">
                            <InputFieldValidation
                              label="image1"
                              inputType="text"
                              inputId="image1"
                              inputName="image1"
                              errors={errors}
                              touched={touched}
                              isRequired
                              onChange={setFieldValue}
                              values={formikValues}
                              isDisabled={true}
                            />
                          </Box>
                        </Box>
                      </>
                    )}
                    {formikValues.alt1 !== "" && (
                      <>
                        {" "}
                        <InputFieldValidation
                          label="alt1"
                          inputType="text"
                          inputId="alt1"
                          inputName="alt1"
                          errors={errors}
                          touched={touched}
                          isRequired
                          onChange={setFieldValue}
                          values={formikValues}
                          isDisabled={true}
                        />
                        <InputFieldValidation
                          label="alt 1 En"
                          inputType="text"
                          inputId="alt1En2"
                          inputName="alt1En2"
                          errors={errors}
                          touched={touched}
                          isRequired
                          onChange={setFieldValue}
                          values={formikValues}
                          isDisabled={true}
                        />
                      </>
                    )}
                    <MainButton
                      title="رفع الصورة"
                      reverseRow={true}
                      onPress={() => {
                        setSelectedImage("1");
                        onUploadOpen();
                      }}
                    />
                  </Box>
                </GridItem>
                <GridItem colSpan={2}>
                  <Box paddingY="10px">
                    {!!generalData?.image2URL && (
                      <>
                        <Text
                          className="title_sm"
                          display="flex"
                          mb={2}
                          mt={0}
                          component="div"
                          textTransform="uppercase"
                          fontSize="12px"
                        >
                          الصورة الحالية
                        </Text>
                        <Box>
                          <Button
                            marginBottom="10px"
                            onClick={(e) => download(e, generalData.image2URL)}
                          >
                            عرض الملف
                          </Button>
                          <Image
                            src={generalData.image2URL}
                            width="200px"
                            height="200px"
                            mb="15px"
                          />
                          <Box display="none">
                            <InputFieldValidation
                              label="image2"
                              inputType="text"
                              inputId="image2"
                              inputName="image2"
                              errors={errors}
                              touched={touched}
                              isRequired
                              onChange={setFieldValue}
                              values={formikValues}
                              isDisabled={true}
                            />
                          </Box>
                        </Box>
                      </>
                    )}
                    {formikValues.alt2 !== "" && (
                      <>
                        <InputFieldValidation
                          label="alt2"
                          inputType="text"
                          inputId="alt2"
                          inputName="alt2"
                          errors={errors}
                          touched={touched}
                          isRequired
                          onChange={setFieldValue}
                          values={formikValues}
                          isDisabled={true}
                        />
                        <InputFieldValidation
                          label="alt 2 En"
                          inputType="text"
                          inputId="alt2En2"
                          inputName="alt2En2"
                          errors={errors}
                          touched={touched}
                          isRequired
                          onChange={setFieldValue}
                          values={formikValues}
                          isDisabled={true}
                        />
                      </>
                    )}

                    <MainButton
                      title="رفع الصورة"
                      reverseRow={true}
                      onPress={() => {
                        setSelectedImage("2");
                        onUploadOpen();
                      }}
                    />
                  </Box>
                </GridItem>
                <GridItem colSpan={2}>
                  <Box paddingY="10px">
                    {!!generalData?.image3URL && (
                      <>
                        <Text
                          className="title_sm"
                          display="flex"
                          mb={2}
                          mt={0}
                          component="div"
                          textTransform="uppercase"
                          fontSize="12px"
                        >
                          الصورة الحالية
                        </Text>
                        <Box>
                          <Button
                            marginBottom="10px"
                            onClick={(e) => download(e, generalData.image3URL)}
                          >
                            عرض الملف
                          </Button>
                          <Image
                            src={generalData.image3URL}
                            width="200px"
                            height="200px"
                            mb="15px"
                          />
                          <Box display="none">
                            <InputFieldValidation
                              label="image3"
                              inputType="text"
                              inputId="image3"
                              inputName="image3"
                              errors={errors}
                              touched={touched}
                              isRequired
                              //placeholder="3.75"
                              onChange={setFieldValue}
                              values={formikValues}
                              isDisabled={true}
                            />
                          </Box>
                        </Box>
                      </>
                    )}
                    {formikValues.alt3 !== "" && (
                      <>
                        <InputFieldValidation
                          label="alt3"
                          inputType="text"
                          inputId="alt3"
                          inputName="alt3"
                          errors={errors}
                          touched={touched}
                          isRequired
                          onChange={setFieldValue}
                          values={formikValues}
                          isDisabled={true}
                        />
                        <InputFieldValidation
                          label="alt 3 En"
                          inputType="text"
                          inputId="alt3En2"
                          inputName="alt3En2"
                          errors={errors}
                          touched={touched}
                          isRequired
                          onChange={setFieldValue}
                          values={formikValues}
                          isDisabled={true}
                        />
                      </>
                    )}

                    <MainButton
                      title="رفع الصورة"
                      reverseRow={true}
                      onPress={() => {
                        setSelectedImage("3");
                        onUploadOpen();
                      }}
                    />
                  </Box>
                </GridItem>
              </SimpleGrid>
            </GridItem>
          </SimpleGrid>

          <Text
            className="title_sm"
            display="flex"
            mb={5}
            mt={9}
            component="div"
            letterSpacing="0.06em"
            fontSize="18px"
            fontWeight="bold"
          >
            المنتجات
          </Text>
          <Box display="flex" flexWrap="wrap">
            <SelectListMulti
              label="المنتجات"
              placeholder="Select"
              inputId="products"
              inputName="products"
              errors={errors}
              touched={touched}
              isRequired
              values={formikValues?.products}
              onChange={setFieldValue}
              onBlur={handleBlur}
              uppercase={true}
              isMulti={true}
              errorHeight0={true}
              clearMulti={clearSelect}
              setClearSelect={setClearSelect}
              dropdownItems={allProducts2}
            />
            {/* {allProducts?.map((el) => {
              return (
                <Box width={"25%"} marginY="5px">
                  <Checkbox
                    colorScheme="green"
                    name={`${el.id}`}
                    onChange={handleCheckBox}
                    value={el.clicked}
                    defaultChecked={el.clicked}
                    // defaultChecked={formikValues.el.clicked}
                    // defaultChecked={formikValues.products.includes(el.id)}
                  >
                    <Text>{el.title}</Text>
                  </Checkbox>
                </Box>
              );
            })} */}
          </Box>
        </Box>
      </MainBox>
      <MainBox px={6}>
        <Box
          maxW={["100%", "100%", "40%"]}
          width={["100%", "100%", "40%"]}
          alignSelf="flex-start"
        >
          <InputFieldValidation
            label="Meta Title"
            inputType="text"
            inputId="metaTitle"
            inputName="metaTitle"
            errors={errors}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
          />
          <InputFieldValidation
            label="Meta Title الإنجليزي"
            inputType="text"
            inputId="metaTitleEn"
            inputName="metaTitleEn"
            errors={errors}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
          />
          <InputFieldValidation
            label="Meta Description"
            inputType="text"
            inputId="metaDesc"
            inputName="metaDesc"
            errors={errors}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
          />
          <InputFieldValidation
            label="Meta Description الإنجليزي"
            inputType="text"
            inputId="metaDescEn"
            inputName="metaDescEn"
            errors={errors}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
          />
          <InputFieldValidation
            label="Meta Keywords"
            inputType="text"
            inputId="metaKeys"
            inputName="metaKeys"
            errors={errors}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
          />
          <InputFieldValidation
            label="Meta Keywords الإنجليزية"
            inputType="text"
            inputId="metaKeysEn"
            inputName="metaKeysEn"
            errors={errors}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
          />
        </Box>
      </MainBox>

      <Flex justifyContent="flex-end" alignItems="center" width="100%">
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="إلغاء"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="إلغاء"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={`/ProjectsHistory`}
            />
          )}

          <MainButton
            title="حفظ"
            reverseRow={true}
            disabledBtn={isValid && dirty ? false : true}
            onPress={() => {
              handleOnSubmit(formikValues);
            }}
          />
        </Box>
      </Flex>

      <ModalAlert2
        isOpen={isUploadOpen}
        onClose={onUploadClose}
        title="رفع صورة"
      >
        <Box className="form_wrapper">
          {isLoading ? (
            <Box paddingTop={12} paddingBottom={12}>
              <Center>
                <Spinner />
              </Center>
            </Box>
          ) : (
            <>
              <Input
                paddingTop="5px"
                marginBottom="15px"
                size="md"
                type="file"
                name="file"
                errors={errors}
                touched={touched}
                values={formikValues}
                accept="image/*"
                onChange={(event) => {
                  setFieldValue("file", event.currentTarget.files[0]);
                  setImageFile(event.currentTarget.files[0]);
                }}
              />

              <InputFieldValidation
                label="alt"
                inputType="text"
                inputId="alt"
                inputName="alt"
                errors={errors}
                touched={touched}
                isRequired
                onChange={setFieldValue}
                values={formikValues}
              />
              <InputFieldValidation
                label="altEn"
                inputType="text"
                inputId="altEn"
                inputName="altEn"
                errors={errors}
                touched={touched}
                isRequired
                onChange={setFieldValue}
                values={formikValues}
              />

              <Box width="200px" height="200px">
                <Thumb file={imageFile} />
                {/* <Thumb file={formikValues?.file} /> */}
              </Box>

              <MainButton
                title="رفع"
                type="submit"
                reverseRow={true}
                onPress={() => {
                  handleUploadImage(formikValues);
                }}
              />
            </>
          )}
        </Box>
      </ModalAlert2>
    </>
  );
};
