import * as Yup from "yup";

export const validationCareersForm = Yup.object().shape({
  name: Yup.string().required("هذه الخانة مطلوبة"),
  gender: Yup.string().required("هذه الخانة مطلوبة"),
  email: Yup.string().email("الإيميل غير صالح"),
  mobile: Yup.string().required("هذه الخانة مطلوبة"),
  currentComapny: Yup.string().required("هذه الخانة مطلوبة"),
  yearsOfExperience: Yup.string().required("هذه الخانة مطلوبة"),
  graduationYear: Yup.string().required("هذه الخانة مطلوبة"),
  university: Yup.string().required("هذه الخانة مطلوبة"),
  education: Yup.string().required("هذه الخانة مطلوبة"),
  aboutYourSelf: Yup.string().required("هذه الخانة مطلوبة"),
  status: Yup.string().required("هذه الخانة مطلوبة"),
  company: Yup.string().required("هذه الخانة مطلوبة"),
  cv: Yup.string().required("هذه الخانة مطلوبة"),
});
