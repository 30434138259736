import React, { useState, useEffect } from "react";

import {
  GetOrderByIdAPI,
  UpdateOrderAPI,
  AddOrderAPI,
  GetProductsStandardSizeAPI,
  GetProductsThicknessAPI,
  GetProductsColorAPI,
  GetProductByIdAPI,
  UpdateProductAPI,
  AddProductAPI,
  GetAllCountryAPI,
  GetProductsCountryAPI,
  GetProductCountryByIdAPI,
  GetProductTypeAPI,
} from "../../../api/ProductsAPI";

import { useLocation, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { Box, useDisclosure } from "@chakra-ui/react";

import { activeMenuItem } from "../../../utils/General";
import { ModalAlert } from "../../../components/modalAlert/ModalAlert";
import { MainTitle } from "../../../components/mainTitle/MainTitle";
import { MainBox } from "../../../components/mainBox/MainBox";
import { OrderForm } from "./OrderForm";
import { validationOrderForm } from "./validationOrderForm";
import history from "../../../history";

import checked from "../../../assets/images/iconsSvg/checked.svg";
import infoCircle from "../../../assets/images/iconsSvg/infoCircle.svg";

export const Order = () => {
  const activeMenu = activeMenuItem();
  const { id } = useParams();
  let location = useLocation();
  //const [isRestore, setIsRestore] = useState(false);
  const [initFormVal, setInitFormVal] = useState({});
  const [generalData, setGeneralData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [disableSave, setDisableSave] = useState(true);
  const [dismissSave, setDismissSave] = useState(true);
  const [imageObj, setImageObj] = useState({});
  const [dropdownObj, setDropdownObj] = useState({});

  let submitModalTitle = id ? "تم التحديث بنجاح" : "تم الإضافة بنجاح";

  const {
    isOpen: isCancelOpen,
    onOpen: onCancelOpen,
    onClose: onCancelClose,
  } = useDisclosure();
  const {
    isOpen: isSubmitOpen,
    onOpen: onSubmitOpen,
    onClose: onSubmitClose,
  } = useDisclosure();

  const {
    isOpen: isUploadOpen,
    onOpen: onUploadOpen,
    onClose: onUploadClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();

  const handleOnSubmit = (formikValue) => {
    if (!!id) {
      const submitValues = {
        // ...formikValue,
        title: formikValue.title,
        code: formikValue.code,
        description: formikValue.description,
        countyID: formikValue.countyID,
        typeID: formikValue.typeID,
        unite: formikValue.unite,
        mostSelling: formikValue.mostSelling,
        newArrival: formikValue.newArrival,
        order_date: formikValue.order_date,
        // image: formikValue.image,
      };

      UpdateOrderAPI(id, submitValues)
        .then((res) => {
          setDisableSave(true);
          setErrorMessage("");
          onSubmitOpen();
          if (res.success === true) {
            setDisableSave(true);
            setErrorMessage("");
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(err?.response?.data?.message || "هناك خطأ ما");
          onErrorOpen();
        });
    } else {
      const submitValues = {
        // ...formikValue,
        title: formikValue.title,
        code: formikValue.code,
        description: formikValue.description,
        countyID: formikValue.countyID,
        typeID: formikValue.typeID,
        unite: formikValue.unite,
        mostSelling: formikValue.mostSelling,
        newArrival: formikValue.newArrival,
        // image: formikValue.image,
      };
      AddOrderAPI(submitValues)
        .then((res) => {
          setDisableSave(true);
          setErrorMessage("");
          onSubmitOpen();
          if (res.success === true) {
            setDisableSave(true);
            setErrorMessage("");
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(err?.response?.data?.message || "هناك خطأ ما");
          console.log("err", err);
          onErrorOpen();
        });
    }
  };

  const getDropdowns = () => {
    //colors
    let getColors = new Promise((resolve, reject) => {
      GetProductsColorAPI(100, 0, 1)
        .then((res) => {
          if (res.data.length > 0) {
            let colorOptions = [];
            res.data.forEach((item) => {
              colorOptions.push({
                value: item.id,
                label: item.color,
              });
            });

            resolve(colorOptions);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
          // setErrorMessage("هناك خطأ ما");
        });
    });
    let getThickness = new Promise((resolve, reject) => {
      GetProductsThicknessAPI()
        .then((res) => {
          if (res.data.length > 0) {
            let thicknessOptions = [];
            res.data.forEach((item) => {
              thicknessOptions.push({
                value: item.id,
                label: item.title,
              });
            });

            resolve(thicknessOptions);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
          // setErrorMessage("هناك خطأ ما");
        });
    });
    let getStandardSize = new Promise((resolve, reject) => {
      GetProductsStandardSizeAPI()
        .then((res) => {
          if (res.data.length > 0) {
            let sizeOptions = [];
            res.data.forEach((item) => {
              sizeOptions.push({
                value: item.id,
                label: item.title,
              });
            });

            resolve(sizeOptions);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
          // setErrorMessage("هناك خطأ ما");
        });
    });
    let getCountrys = new Promise((resolve, reject) => {
      GetAllCountryAPI()
        .then((res) => {
          if (res.length > 0) {
            let countryOptions = [];
            let countryAllOptions = [];
            res.forEach((item) => {
              countryOptions.push({
                value: item.id,
                label: item.country,
              });
              countryAllOptions.push({
                value: item.id,
                label: item.country,
              });
            });

            resolve({ countryOp: countryOptions, countryAllOp: res });
          }
          // console.log("operatorOptions2", operatorOptions2);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
          // setErrorMessage("هناك خطأ ما");
        });
    });
    let getProdType = new Promise((resolve, reject) => {
      GetProductTypeAPI()
        .then((res) => {
          // console.log("res data dropdown", res);
          if (res.length > 0) {
            let pTypeOptions = [];
            res.forEach((item) => {
              pTypeOptions.push({
                value: item.id,
                label: item.title,
              });
            });

            resolve(pTypeOptions);
          }
          // console.log("operatorOptions2", operatorOptions2);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
          // setErrorMessage("هناك خطأ ما");
        });
    });
    // let getProdCountrys = new Promise((resolve, reject) => {
    //   GetProductsCountryAPI(200, 0, 1)
    //     .then((res) => {
    //       // console.log("res data dropdown", res);
    //       if (res.data.length > 0) {
    //         // let countryOptions = [];
    //         // res.data.forEach((item) => {
    //         //   countryOptions.push({
    //         //     value: item.id,
    //         //     label: item.country,
    //         //   });
    //         // });

    //         resolve(res.data);
    //         // resolve(countryOptions);
    //       }
    //       // console.log("operatorOptions2", operatorOptions2);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       reject(err);
    //       // setErrorMessage("هناك خطأ ما");
    //     });
    // });

    return Promise.all([
      getColors,
      getThickness,
      getStandardSize,
      getCountrys,
      getProdType,
      // getProdCountrys,
    ]).then((values) => {
      // console.log("vaaaaaal drop", values);
      setDropdownObj({
        colors: values[0],
        thickness: values[1],
        standardSize: values[2],
        prodCountry: values[3].countryOp,
        Country: values[3].countryAllOp,
        prodType: values[4],
      });
      return {
        colors: values[0],
        thickness: values[1],
        standardSize: values[2],
        prodCountry: values[3].countryOp,
        Country: values[3].countryAllOp,
        prodType: values[4],
      };
    });
  };

  useEffect(() => {
    getDropdowns().then((data) => {
      // console.log("dddaa", data);
      let allCountry;
      setErrorMessage("");
      GetProductsCountryAPI(200, 0, 1)
        .then((response) => {
          // console.log("response data dropdown", response);
          if (response.data.length > 0) {
            allCountry = response.data;
            return GetOrderByIdAPI({
              offset: 0,
              limit: 10,
              order_id: id,
            });
          }
        })
        .then((res) => {
          // console.log("reees", res, allCountry, dropdownObj);
          let findCountry = allCountry.find(
            (el) => el.id == res.data[0].Country_id
          );
          if (findCountry) {
            findCountry = {
              value: findCountry.countryID,
              label: findCountry.description,
            };
          }
          // let colorsArr = res.data[0].colors.map((el, ind) => {
          //   let imagesArr = el.imageID.map((image, ind) => {
          //     return image.image_id;
          //   });
          //   return { colorID: el.colorID, imageID: imagesArr };
          // });

          // console.log("standardSizesArr", standardSizesArr);
          // let thicknessArr = res.data[0].thickness_id.map((el, ind) => {
          //   return el.thickness_id;
          // });

          setInitFormVal({
            title: res.data[0].title,
            companyActivity: res.data[0].companyActivity,
            company: res.data[0].company,
            constructionType: res.data[0].constructionType,
            details: res.data[0].details,
            email: res.data[0].email,
            firstName: res.data[0].firstName,
            lastName: res.data[0].lastName,
            mobile: res.data[0].mobile,
            product_id: res.data[0].product_id,
            product_standardSize_id: res.data[0].product_standardSize_id,
            product_thikness_id: res.data[0].product_thikness_id,
            type: res.data[0].type,
            uses: res.data[0].uses,
            website: res.data[0].website,
            area: res.data[0].area,
            address: res.data[0].address,
            colors: res.data[0].color_id,
            standardSize_id: res.data[0].standard_size,
            thickness_id: res.data[0].thickness,
            Attachment: res.data[0].Attachment,
            order_date: res.data[0].order_date,
            productTitle: res.data[0].Product.title,
            productTitleEn: res.data[0].Product.titleEn,
            productDesc: res.data[0].Product.description,
            productDescEn: res.data[0].Product.descriptionEn,
            colorTitle: res.data[0].Color.color,
            colorTitleEn: res.data[0].Color.colorEn,
            thickTitle: res.data[0].Thikness.title,
            thickTitleEn: res.data[0].Thikness.titleEn,
            StandardTitle: res.data[0].StandardSize.title,
          });
          setGeneralData(res.data[0]);
        })
        .catch((err) => {
          console.log(err);

          setErrorMessage("هناك خطأ ما");
          onErrorOpen();
        });
    });
  }, []);
  useEffect(() => {
    let unblock;
    if (!disableSave && dismissSave) {
      unblock = history.block((tx) => {
        let url = tx.location.pathname;
        if (window.confirm(`هل أنت متأكد أنك تريد تجاهل التغييرات؟`)) {
          unblock();

          tx.retry();
        }
      });
    }

    return () => {
      if (typeof unblock === "function") {
        unblock();
      }
    };
  }, [disableSave, dismissSave]);

  return (
    <div className="Assumptions">
      <>
        <MainBox px={0}>
          <MainTitle title="عرض الطلب" bBottom={true} px={7} />

          <Box className="table_content" pt={6} pb={6}>
            <Formik
              initialValues={initFormVal}
              enableReinitialize={true}
              validationSchema={validationOrderForm}
              validate={(values) => {
                if (disableSave) {
                  setDisableSave(false);
                }
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  alert(JSON.stringify(values, null, 2));
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({
                errors,
                touched,
                values,
                handleChange,
                handleBlur,
                setFieldValue,
                isValid,
                dirty,
              }) => (
                <Form className="form_wrapper">
                  <OrderForm
                    onCancelOpen={onCancelOpen}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    formikValues={values}
                    isValid={isValid}
                    dirty={dirty}
                    disableSave={disableSave}
                    setDisableSave={setDisableSave}
                    dismissSave={dismissSave}
                    setDismissSave={setDismissSave}
                    onSubmitOpen={onSubmitOpen}
                    onUploadOpen={onUploadOpen}
                    handleOnSubmit={handleOnSubmit}
                    setInitFormVal={setInitFormVal}
                    generalData={generalData}
                    isUploadOpen={isUploadOpen}
                    onUploadClose={onUploadClose}
                    onErrorOpen={onErrorOpen}
                    setErrorMessage={setErrorMessage}
                    setImageObj={setImageObj}
                    dropdownObj={dropdownObj}
                  />

                  <ModalAlert
                    isOpen={isCancelOpen}
                    onClose={onCancelClose}
                    title="هل أنت متأكد أنك تريد تجاهل التغييرات؟"
                    icon={infoCircle}
                    hasDesc={false}
                    confirmationModal={true}
                    path={`/OrdersHistory`}
                    setDismissSave={() => setDismissSave(true)}
                  />
                </Form>
              )}
            </Formik>
          </Box>
        </MainBox>

        <ModalAlert
          isOpen={isErrorOpen}
          onClose={onErrorClose}
          title={errorMessage}
          icon={infoCircle}
          hasDesc={false}
          confirmationModal={false}
        />

        <ModalAlert
          isOpen={isSubmitOpen}
          onClose={onSubmitClose}
          title={submitModalTitle}
          btnWidthFull={true}
          icon={checked}
          hasDesc={false}
          disableClose={true}
          path={`/OrdersHistory`}
        />
      </>
    </div>
  );
};
