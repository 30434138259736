import React, { useState, useEffect, useCallback } from "react";

import {
  Box,
  Divider,
  Flex,
  RadioGroup,
  Radio,
  SimpleGrid,
  GridItem,
  Text,
  Stack,
  Image,
  Input,
  Center,
  Spinner,
  Button,
  Checkbox,
} from "@chakra-ui/react";

import { MainButton } from "../../../components/button/MainButton";
import { SelectListMulti } from "../../../components/selectField/SelectListMulti";
import { DatePickerField } from "../../../components/datePickerField/DatePickerField";
import clock from "../../../assets/images/iconsSvg/clock.svg";

export const OrderTableForm = ({
  errors,
  touched,
  setFieldValue,
  handleBlur,
  formikValues,
  dirty,
  isValid,
  handleOnSubmit,
  generalData,
  dropdownObj,
  setInitFormVal,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckBoxSelected, setIsCheckBoxSelected] = useState("");
  const [clearSelect, setClearSelect] = useState(false);

  const handleCheckBox = useCallback((e) => {
    // console.log("e", e.target.name);
    setIsCheckBoxSelected([e.target.checked]);
    setFieldValue(`${e.target.name}`, e.target.checked);
  }, []);

  const handleChangeRadio = (event, name) => {
    setFieldValue(name, event);
  };

  const clearFields = () => {
    setFieldValue("order_date", "");
    setFieldValue("order_id", "");
    setFieldValue("product_id", "");
    setClearSelect(!clearSelect);

    setTimeout(() => {
      handleOnSubmit({});
    }, 500);
  };
  // console.log("dropdownObj.prodCountry", formikValues);
  return (
    <>
      <Box
        maxW={["100%", "100%", "100%"]}
        width={["100%", "100%", "100%"]}
        alignSelf="flex-start"
      >
        <SimpleGrid columns={{ sm: 4, md: 4 }} spacingX="5px" width="100%">
          <GridItem colSpan={4}>
            <SimpleGrid columns={{ sm: 4, md: 4 }} spacingX="5px" width="100%">
              <GridItem colSpan={2}>
                <SelectListMulti
                  dropdownItems={dropdownObj.products}
                  label="ID المنتج"
                  inputId="product_id"
                  inputName="product_id"
                  placeholder="إختيار"
                  errors={errors}
                  touched={touched}
                  isRequired
                  values={formikValues?.product_id}
                  onChange={setFieldValue}
                  onBlur={handleBlur}
                  uppercase={true}
                  clearMulti={clearSelect}
                  setClearSelect={setClearSelect}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <SelectListMulti
                  dropdownItems={dropdownObj.orders}
                  label="ID الطلب"
                  inputId="order_id"
                  inputName="order_id"
                  placeholder="إختيار"
                  errors={errors}
                  touched={touched}
                  isRequired
                  values={formikValues?.order_id}
                  onChange={setFieldValue}
                  onBlur={handleBlur}
                  uppercase={true}
                  clearMulti={clearSelect}
                  setClearSelect={setClearSelect}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <DatePickerField
                  label="تاريخ الطلب"
                  inputType="date"
                  inputId="order_date"
                  inputName="order_date"
                  errors={errors}
                  touched={touched}
                  format="fullDate"
                  isRequired
                  values={formikValues.order_date}
                  onChange={setFieldValue}
                  onBlur={handleBlur}
                  icon={clock}
                />
              </GridItem>
              <GridItem
                colSpan={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Flex
                  justifyContent="flex-end"
                  alignItems="center"
                  width="100%"
                >
                  <Box display="flex" gap="10px">
                    <MainButton
                      title="مسح"
                      reverseRow={true}
                      btnStyle="secondary"
                      // disabledBtn={isValid && dirty ? false : true}
                      onPress={() => {
                        clearFields();
                      }}
                    />
                    <MainButton
                      title="تطبيق"
                      reverseRow={true}
                      disabledBtn={isValid && dirty ? false : true}
                      onPress={() => {
                        handleOnSubmit(formikValues);
                      }}
                    />
                  </Box>
                </Flex>
              </GridItem>
            </SimpleGrid>
          </GridItem>
        </SimpleGrid>
      </Box>
    </>
  );
};
