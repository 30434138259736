import * as Yup from "yup";

export const validationProductForm = Yup.object().shape({
  title: Yup.string().required("هذه الخانة مطلوبة"),
  code: Yup.string().required("هذه الخانة مطلوبة"),
  description: Yup.string().required("هذه الخانة مطلوبة"),
  countyID: Yup.string().required("هذه الخانة مطلوبة"),
  typeID: Yup.string().required("هذه الخانة مطلوبة"),
  unite: Yup.string().required("هذه الخانة مطلوبة"),
  // mostSelling: Yup.string().required("هذه الخانة مطلوبة"),
  // image: Yup.string().required("هذه الخانة مطلوبة"),
  // newArrival: Yup.string().required("هذه الخانة مطلوبة"),
  titleEn: Yup.string().required("هذه الخانة مطلوبة"),
  descriptionEn: Yup.string().required("هذه الخانة مطلوبة"),
  metaTitle: Yup.string().required("هذه الخانة مطلوبة"),
  metaDescription: Yup.string().required("هذه الخانة مطلوبة"),
  metaTitleEn: Yup.string().required("هذه الخانة مطلوبة"),
  metaDescriptionEn: Yup.string().required("هذه الخانة مطلوبة"),
});
