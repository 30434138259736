import React, { useState, useEffect } from "react";

import { GetAboutUs, UpdateAboutUsAPI } from "../../../api/AboutUsAPI";

import { useLocation, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { Box, useDisclosure } from "@chakra-ui/react";

import { activeMenuItem } from "../../../utils/General";
import { ModalAlert } from "../../../components/modalAlert/ModalAlert";
import { MainTitle } from "../../../components/mainTitle/MainTitle";
import { MainBox } from "../../../components/mainBox/MainBox";
import { AboutUsForm } from "./AboutUsForm";
import { validationAboutUsForm } from "./validationAboutUsForm";
import history from "../../../history";

import checked from "../../../assets/images/iconsSvg/checked.svg";
import infoCircle from "../../../assets/images/iconsSvg/infoCircle.svg";

export const AboutUs = () => {
  const activeMenu = activeMenuItem();
  const { id } = useParams();
  let location = useLocation();
  //const [isRestore, setIsRestore] = useState(false);
  const [initFormVal, setInitFormVal] = useState({});
  const [generalData, setGeneralData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [disableSave, setDisableSave] = useState(true);
  const [dismissSave, setDismissSave] = useState(true);
  const [imageObj, setImageObj] = useState({});

  let submitModalTitle = id
  ? "تم التحديث بنجاح"
  : "تم الإضافة بنجاح";

  const {
    isOpen: isSubmitOpen,
    onOpen: onSubmitOpen,
    onClose: onSubmitClose,
  } = useDisclosure();

  const {
    isOpen: isUploadOpen,
    onOpen: onUploadOpen,
    onClose: onUploadClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();

  const handleOnSubmit = (formikValue) => {
    if (!!id) {
      const submitValues = {
        //...formikValue,
        introduction: formikValue.introduction,
        vision: formikValue.vision,
        mission: formikValue.mission,
        philosophy: formikValue.philosophy,
        ceoText: formikValue.ceoText,
        ceoName: formikValue.ceoName,
        ceoImage: formikValue.image,
        introductionEn: formikValue.introductionEn,
        visionEn: formikValue.visionEn,
        missionEn: formikValue.missionEn,
        philosophyEn: formikValue.philosophyEn,
        ceoTextEn: formikValue.ceoTextEn,
        ceoNameEn: formikValue.ceoNameEn,
        image: formikValue.ceoImage,
        altImag: formikValue.altImag,
        // altImag: formikValue.altImag,
      };

      UpdateAboutUsAPI(submitValues)
        .then((res) => {
          setDisableSave(true);
          setErrorMessage("");
          onSubmitOpen();
          if (res.success === true) {
            setDisableSave(true);
            setErrorMessage("");
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "هناك خطأ ما"
          );
          onErrorOpen();
        });
    }
  };

  useEffect(() => {
    if (!!id) {
      setErrorMessage("");
      GetAboutUs()
        .then((res) => {
          setInitFormVal({
            introduction: res.introduction,
            vision: res.vision,
            mission: res.mission,
            philosophy: res.philosophy,
            ceoText: res.ceoText,
            ceoName: res.ceoName,
            introductionEn: res.introductionEn,
            visionEn: res.visionEn,
            missionEn: res.missionEn,
            philosophyEn: res.philosophyEn,
            ceoTextEn: res.ceoTextEn,
            ceoNameEn: res.ceoNameEn,
            image: res.ceoImage,
            altImag: res.altImag,
            alt: "",
            altEn: "",
            file: "",
            altEn2: res.altImagEn,
          });
          setGeneralData(res);
        })
        .catch((err) => {
          setErrorMessage("هناك خطأ ما");
          onErrorOpen();
        });
    }
  }, []);
  useEffect(() => {
    let unblock;
    if (!disableSave && dismissSave) {
      unblock = history.block((tx) => {
        let url = tx.location.pathname;
        if (window.confirm(`هل أنت متأكد أنك تريد تجاهل التغييرات؟`)) {
          unblock();

          tx.retry();
        }
      });
    }

    return () => {
      if (typeof unblock === "function") {
        unblock();
      }
    };
  }, [disableSave, dismissSave]);

  return (
    <div className="AboutUs">
      <>
        <MainBox px={0}>
          {id === undefined ? (
            <MainTitle title="إضافة من نحن" bBottom={true} px={7} />
          ) : (
            <MainTitle title="تعديل من نحن" bBottom={true} px={7} />
          )}
          <Box className="table_content" pt={6} pb={6}>
            <Formik
              initialValues={initFormVal}
              enableReinitialize={true}
              validationSchema={validationAboutUsForm}
              validate={(values) => {
                if (disableSave) {
                  setDisableSave(false);
                }
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  alert(JSON.stringify(values, null, 2));
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({
                errors,
                touched,
                values,
                handleChange,
                handleBlur,
                setFieldValue,
                isValid,
                dirty,
              }) => (
                <Form className="form_wrapper">
                  <AboutUsForm
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    formikValues={values}
                    isValid={isValid}
                    dirty={dirty}
                    disableSave={disableSave}
                    setDisableSave={setDisableSave}
                    dismissSave={dismissSave}
                    setDismissSave={setDismissSave}
                    onSubmitOpen={onSubmitOpen}
                    onUploadOpen={onUploadOpen}
                    handleOnSubmit={handleOnSubmit}
                    setInitFormVal={setInitFormVal}
                    generalData={generalData}
                    isUploadOpen={isUploadOpen}
                    onUploadClose={onUploadClose}
                    onErrorOpen={onErrorOpen}
                    setErrorMessage={setErrorMessage}
                    setImageObj={setImageObj}
                  />
                </Form>
              )}
            </Formik>
          </Box>
        </MainBox>

        <ModalAlert
          isOpen={isErrorOpen}
          onClose={onErrorClose}
          title={errorMessage}
          icon={infoCircle}
          hasDesc={false}
          confirmationModal={false}
        />

        <ModalAlert
          isOpen={isSubmitOpen}
          onClose={onSubmitClose}
          title={submitModalTitle}
          btnWidthFull={true}
          icon={checked}
          hasDesc={false}
          disableClose={true}
          path={`/AboutUs/1`}
        />
      </>
    </div>
  );
};
