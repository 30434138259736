import { Box } from "@chakra-ui/react";

import { MainButton } from "../../components/button/MainButton";

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

/* const dataSet1 = [
  {
    name: "Johson",
    amount: 30000,
    sex: "M",
    is_married: true,
  },
  {
    name: "Monika",
    amount: 355000,
    sex: "F",
    is_married: false,
  },
  {
    name: "John",
    amount: 250000,
    sex: "M",
    is_married: false,
  },
  {
    name: "Josef",
    amount: 450500,
    sex: "M",
    is_married: true,
  },
]; */

var dataSet2 = [
  {
    name: "Johnson",
    total: 25,
    remainig: 16,
  },
  {
    name: "Josef",
    total: 25,
    remainig: 7,
  },
];

export const ExportExcel = ({ dataSetExcel, type }) => {
  // <button>Download Data</button>

  const renderTable = () => {
    switch (type) {
      case "uniqueRock":
        return (
          <ExcelSheet data={dataSetExcel} name="table">
            <ExcelColumn label="Title" value="title" />
            {/* <ExcelColumn label="Title English" value="titleEn" /> */}
            <ExcelColumn label="Text" value="text" />
            {/* <ExcelColumn label="Text English" value="textEn" /> */}
            {/* <ExcelColumn
          label="Marital Status"
          value={(col) => (col.is_married ? "Married" : "Single")}
        /> */}
          </ExcelSheet>
        );
    }
  };
  return (
    <ExcelFile
      element={<MainButton title="تصدير إلى Excel" btnHeight="48px" />}
    >
      {renderTable()}
      {/* <ExcelSheet data={dataSetExcel} name="Employees">
        <ExcelColumn label="Name" value="name" />
        <ExcelColumn label="Wallet Money" value="amount" />
        <ExcelColumn label="Gender" value="sex" />
        <ExcelColumn
          label="Marital Status"
          value={(col) => (col.is_married ? "Married" : "Single")}
        />
      </ExcelSheet> */}
      {/*  <ExcelSheet data={dataSet2} name="Leaves">
        <ExcelColumn label="Name" value="name" />
        <ExcelColumn label="Total Leaves" value="total" />
        <ExcelColumn label="Remaining Leaves" value="remaining" />
      </ExcelSheet> */}
    </ExcelFile>
  );
};
