import React, { useState, useEffect } from "react";

import {
  GetProductByIdAPI,
  UpdateProductAPI,
  AddProductAPI,
  GetProductsStandardSizeAPI,
  GetProductsThicknessAPI,
  GetProductsColorAPI,
  GetAllCountryAPI,
  GetProductsCountryAPI,
  GetProductCountryByIdAPI,
  GetProductTypeAPI,
} from "../../../api/ProductsAPI";

import { useLocation, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { Box, useDisclosure } from "@chakra-ui/react";

import { activeMenuItem } from "../../../utils/General";
import { ModalAlert } from "../../../components/modalAlert/ModalAlert";
import { MainTitle } from "../../../components/mainTitle/MainTitle";
import { MainBox } from "../../../components/mainBox/MainBox";
import { ProductForm } from "./ProductForm";
import { validationProductForm } from "./validationProductForm";
import history from "../../../history";

import checked from "../../../assets/images/iconsSvg/checked.svg";
import infoCircle from "../../../assets/images/iconsSvg/infoCircle.svg";

export const Product = () => {
  const activeMenu = activeMenuItem();
  const { id } = useParams();
  let location = useLocation();
  //const [isRestore, setIsRestore] = useState(false);
  const [initFormVal, setInitFormVal] = useState({});
  const [generalData, setGeneralData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [disableSave, setDisableSave] = useState(true);
  const [dismissSave, setDismissSave] = useState(true);
  const [dropdownObj, setDropdownObj] = useState({});
  const [imageObj, setImageObj] = useState({});

  let submitModalTitle = id ? "تم التحديث بنجاح" : "تم الإضافة بنجاح";

  const {
    isOpen: isCancelOpen,
    onOpen: onCancelOpen,
    onClose: onCancelClose,
  } = useDisclosure();
  const {
    isOpen: isSubmitOpen,
    onOpen: onSubmitOpen,
    onClose: onSubmitClose,
  } = useDisclosure();

  const {
    isOpen: isUploadOpen,
    onOpen: onUploadOpen,
    onClose: onUploadClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();

  const handleOnSubmit = (formikValue) => {
    if (!!id) {
      // console.log("submit values formik", formikValue);
      // console.log("generalData", generalData);
      // console.log("generalData2", dropdownObj.prodCountry);
      // console.log("generalData3", dropdownObj?.Country);
      let typeObj = dropdownObj.prodTypeAll.find(
        (el) => el.id == formikValue.typeID
      );
      let countryObj = dropdownObj.prodCountry.find(
        (el) => el.value == formikValue.countyID
      );

      let newDetailsInfo = formikValue.detailsInformation.map((el, ind) => {
        return {
          id: generalData?.detailsInformation[ind]?.id
            ? generalData.detailsInformation[ind].id
            : el.title,
          product_id: generalData?.detailsInformation[ind]?.product_id
            ? generalData.detailsInformation[ind].product_id
            : el.title,
          title: el.title,
          description: el.description,
          titleEn: el.titleEn,
          descriptionEn: el.descriptionEn,
        };
      });
      let newSizes = formikValue.sizes.map((el, ind) => {
        let title = el == "1" ? "الواح" : "مقاسات ستاندرد";
        let titleEn = el == "1" ? "Slabs" : "Standard sizes";

        return {
          id: el,
          product_id: el,
          size_id: el,
          Size_id: el,
          Size_title: title,
          Size_titleEn: titleEn,
        };
      });
      let newٍStandardSizes = formikValue.standardSize_id.map((el, ind) => {
        // let title = el == "1" ? "الواح" : "مقاسات ستاندرد";
        // let titleEn = el == "1" ? "Slabs" : "Standard sizes";

        return {
          id: el,
          product_id: el,
          standardSize_id: el,
          Size_id: el,
          Size_title: el,
          Size_titleEn: el,
        };
      });
      let newٍThickness = formikValue.thickness_id.map((el, ind) => {
        // let title = el == "1" ? "الواح" : "مقاسات ستاندرد";
        // let titleEn = el == "1" ? "Slabs" : "Standard sizes";

        return {
          id: el,
          product_id: el,
          thickness_id: el,
          thickness_title: el,
          thickness_titleEn: el,
        };
      });
      let newٍColors = formikValue.colors.map((el, ind) => {
        // let title = el == "1" ? "الواح" : "مقاسات ستاندرد";
        // let titleEn = el == "1" ? "Slabs" : "Standard sizes";
        let newImages = el.imageID.map((image) => {
          return {
            id: image,
            productColor_id: image,
            image_id: image,
            image: {
              id: image,
              url: image,
              alt: image,
              altEn: image,
            },
          };
        });

        return {
          color: el.colorID,
          colorEn: el.colorID,
          hexCode: el.colorID,
          colorID: el.colorID,
          imageID: newImages,
        };
      });
      let newArrivalval;
      if (typeof formikValue.newArrival === "boolean") {
        newArrivalval =
          formikValue.newArrival == 1 || formikValue.newArrival == true ? 1 : 0;
      } else {
        newArrivalval = formikValue.newArrival;
      }
      let mostSellingval;
      if (typeof formikValue.mostSelling === "boolean") {
        mostSellingval =
          formikValue.mostSelling == 1 || formikValue.mostSelling == true
            ? 1
            : 0;
      } else {
        mostSellingval = formikValue.mostSelling;
      }
      const submitValues = {
        // ...formikValue,
        id: id,
        title: formikValue.title,
        titleEn: formikValue.titleEn,
        code: formikValue.code,
        odooID: parseInt(formikValue.odooID),
        description: formikValue.description,
        descriptionEn: formikValue.descriptionEn,
        countyID: formikValue.countyID,
        typeID: formikValue.typeID,
        unite: formikValue.unite,
        mostSelling: mostSellingval,
        newArrival: newArrivalval,
        metaTitle: formikValue.metaTitle,
        metaDescription: formikValue.metaDescription,
        metaKeys: formikValue.metaKeys,
        metaTitleEn: formikValue.metaTitleEn,
        metaDescriptionEn: formikValue.metaDescriptionEn,
        metaKeysEn: formikValue.metaKeysEn,
        slug: formikValue.slug,
        Type_id: formikValue.typeID,
        Type_title: typeObj.title,
        Type_titleEn: typeObj.titleEn,
        Country_id: formikValue.countyID,
        Country_countryID: formikValue.countyID,
        Country_description: countryObj.label,
        Country_descriptionEn: countryObj.label,

        detailsInformation: newDetailsInfo,
        sizes: newSizes,

        standardSize_id: newٍStandardSizes,
        thickness_id: newٍThickness,
        colors: newٍColors,
        // image: formikValue.image,
      };

      UpdateProductAPI(submitValues)
        .then((res) => {
          setDisableSave(true);
          setErrorMessage("");
          onSubmitOpen();
          if (res.success === true) {
            setDisableSave(true);
            setErrorMessage("");
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(err?.response?.data || "هناك خطأ ما");
          onErrorOpen();
        });
    } else {
      let newArrivalval;
      if (typeof formikValue.newArrival === "boolean") {
        newArrivalval =
          formikValue.newArrival == 1 || formikValue.newArrival == true ? 1 : 0;
      } else {
        newArrivalval = formikValue.newArrival;
      }
      let mostSellingval;
      if (typeof formikValue.mostSelling === "boolean") {
        mostSellingval =
          formikValue.mostSelling == 1 || formikValue.mostSelling == true
            ? 1
            : 0;
      } else {
        mostSellingval = formikValue.mostSelling;
      }
      const submitValues = {
        // ...formikValue,
        title: formikValue.title,
        titleEn: formikValue.titleEn,
        odooID: parseInt(formikValue.odooID),
        code: formikValue.code,
        description: formikValue.description,
        descriptionEn: formikValue.descriptionEn,
        countyID: formikValue.countyID,
        typeID: formikValue.typeID,
        unite: formikValue.unite,
        mostSelling: mostSellingval,
        newArrival: newArrivalval,
        metaTitle: formikValue.metaTitle,
        metaDescription: formikValue.metaDescription,
        metaKeys: formikValue.metaKeys,
        metaTitleEn: formikValue.metaTitleEn,
        metaDescriptionEn: formikValue.metaDescriptionEn,
        metaKeysEn: formikValue.metaKeysEn,
        slug: formikValue.slug,
        detailsInformation: formikValue.detailsInformation,
        colors: formikValue.colors,
        sizes: formikValue.sizes,
        standardSize_id: formikValue.standardSize_id,
        thickness_id: formikValue.thickness_id,
        // image: formikValue.image,
      };
      // if (formikValue.standardSize_id.length === 0) {
      //   delete submitValues.standardSize_id;
      // }
      // if (formikValue.thickness_id === "") {
      //   delete submitValues.thickness_id;
      // }
      AddProductAPI(submitValues)
        .then((res) => {
          setDisableSave(true);
          setErrorMessage("");
          onSubmitOpen();
          if (res.success === true) {
            setDisableSave(true);
            setErrorMessage("");
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(err?.response?.data || "هناك خطأ ما");
          console.log("err", err);
          onErrorOpen();
        });
    }
  };

  const getDropdowns = () => {
    //colors
    let getColors = new Promise((resolve, reject) => {
      GetProductsColorAPI(100, 0, 1)
        .then((res) => {
          // console.log("res data dropdown", res);
          if (res.data.length > 0) {
            let colorOptions = [];
            res.data.forEach((item) => {
              colorOptions.push({
                value: item.id,
                label: item.color,
              });
            });

            resolve(colorOptions);
          }
          // console.log("operatorOptions2", operatorOptions2);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
          // setErrorMessage("هناك خطأ ما");
        });
    });
    let getThickness = new Promise((resolve, reject) => {
      GetProductsThicknessAPI()
        .then((res) => {
          // console.log("res data dropdown", res);
          if (res.data.length > 0) {
            let thicknessOptions = [];
            res.data.forEach((item) => {
              thicknessOptions.push({
                value: item.id,
                label: item.title,
              });
            });

            resolve(thicknessOptions);
          }
          // console.log("operatorOptions2", operatorOptions2);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
          // setErrorMessage("هناك خطأ ما");
        });
    });
    let getStandardSize = new Promise((resolve, reject) => {
      GetProductsStandardSizeAPI()
        .then((res) => {
          // console.log("res data dropdown", res);
          if (res.data.length > 0) {
            let sizeOptions = [];
            res.data.forEach((item) => {
              sizeOptions.push({
                value: item.id,
                label: item.title,
              });
            });

            resolve(sizeOptions);
          }
          // console.log("operatorOptions2", operatorOptions2);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
          // setErrorMessage("هناك خطأ ما");
        });
    });
    let getCountrys = new Promise((resolve, reject) => {
      GetProductsCountryAPI(200, 0, 1)
        .then((res) => {
          if (res.data.length > 0) {
            let countryOptions = [];
            let countryAllOptions = [];
            res.data.forEach((item) => {
              countryOptions.push({
                value: item.id,
                label: item.title,
              });
              // countryAllOptions.push({
              //   value: item.id,
              //   label: item.country,
              // });
            });

            resolve({ countryOp: countryOptions /* , countryAllOp: res */ });
          }
          // console.log("operatorOptions2", operatorOptions2);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
          // setErrorMessage("هناك خطأ ما");
        });
    });
    let getProdType = new Promise((resolve, reject) => {
      GetProductTypeAPI()
        .then((res) => {
          // console.log("res data dropdown", res);
          if (res.length > 0) {
            let pTypeOptions = [];
            res.forEach((item) => {
              pTypeOptions.push({
                value: item.id,
                label: item.title,
              });
            });

            resolve({ pType: pTypeOptions, pTypeAll: res });
          }
          // console.log("operatorOptions2", operatorOptions2);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
          // setErrorMessage("هناك خطأ ما");
        });
    });

    return Promise.all([
      getColors,
      getThickness,
      getStandardSize,
      getCountrys,
      getProdType,
      // getProdCountrys,
    ]).then((values) => {
      // console.log("vaaaaaal drop", values);
      setDropdownObj({
        colors: values[0],
        thickness: values[1],
        standardSize: values[2],
        prodCountry: values[3].countryOp,
        // Country: values[3].countryAllOp,
        prodType: values[4].pType,
        prodTypeAll: values[4].pTypeAll,
        sizes: [
          {
            value: 1,
            label: "الواح",
          },
          {
            value: 2,
            label: "مقاسات ستاندرد",
          },
        ],
      });
      return {
        colors: values[0],
        thickness: values[1],
        standardSize: values[2],
        prodCountry: values[3].countryOp,
        // Country: values[3].countryAllOp,
        prodType: values[4].pType,
        sizes: [
          {
            value: 1,
            label: "الواح",
          },
          {
            value: 2,
            label: "مقاسات ستاندرد",
          },
        ],
      };
    });
  };

  useEffect(() => {
    getDropdowns().then((data) => {
      if (!!id) {
        setErrorMessage("");
        GetProductByIdAPI(id, {
          offset: 0,
          limit: 10,
          product_id: id,
        })
          .then((res) => {
            // console.log("reees", res, allCountry, dropdownObj);
            let findCountry = data.prodCountry.find(
              (el) => el.value == res.data[0].countyID
            );
            if (findCountry) {
              findCountry = {
                value: findCountry.value,
                label: findCountry.label,
              };
            }
            // console.log("findC", findCountry);
            let colorsArr = res.data[0].colors.map((el, ind) => {
              let imagesArr = el.imageID.map((image, ind) => {
                return image.image_id;
              });
              return { colorID: el.colorID, imageID: imagesArr };
            });
            let detailsInfoArr = res.data[0].detailsInformation.map(
              (el, ind) => {
                return {
                  title: el.title,
                  description: el.description,
                  titleEn: el.titleEn,
                  descriptionEn: el.descriptionEn,
                };
              }
            );
            let sizesArr = res.data[0].sizes.map((el, ind) => {
              return el.size_id;
            });
            let standardSizesArr = res.data[0].standardSize_id.map(
              (el, ind) => {
                return el.standardSize_id;
              }
            );
            // console.log("standardSizesArr", standardSizesArr);
            let thicknessArr = res.data[0].thickness_id.map((el, ind) => {
              return el.thickness_id;
            });

            setInitFormVal({
              title: res.data[0].title,
              titleEn: res.data[0].titleEn,
              code: res.data[0].code,
              description: res.data[0].description,
              descriptionEn: res.data[0].descriptionEn,
              // countyID: res.data[0].Country_id,
              countyID: res.data[0].countyID,
              typeID: res.data[0].typeID,
              unite: res.data[0].unite,
              mostSelling: res.data[0].mostSelling == 1 ? true : false,
              newArrival: res.data[0].newArrival ? true : false,
              odooID: res.data[0].odooID,
              metaDescription: res.data[0].metaDescription,
              metaDescriptionEn: res.data[0].metaDescriptionEn,
              metaTitle: res.data[0].metaTitle,
              metaTitleEn: res.data[0].metaTitleEn,
              metaKeys: res.data[0].metaKeys,
              metaKeysEn: res.data[0].metaKeysEn,
              slug: res.data[0].slug,
              colors: colorsArr,
              colorsArr: res.data[0].colors,
              detailsInformation: detailsInfoArr,
              sizes: sizesArr,
              standardSize_id: standardSizesArr,
              thickness_id: thicknessArr,
              // image: res.data[0].image,
              // alt: res.data[0].altImage,
              alt: "",
              altEn: "",
              file: "",
            });
            setGeneralData(res.data[0]);
          })
          .catch((err) => {
            console.log(err);

            setErrorMessage("هناك خطأ ما");
            onErrorOpen();
          });
      } else {
        setInitFormVal({
          title: "",
          code: "",
          description: "",
          countyID: "",
          typeID: "",
          unite: "",
          mostSelling: false,
          newArrival: false,
          odooID: "",
          metaTitle: "",
          metaDescription: "",
          colors: [{ colorID: "", imageID: [] }],
          colorsArr: [{ colorID: "", imageID: [] }],
          detailsInformation: [
            { title: "", description: "", titleEn: "", descriptionEn: "" },
          ],
          sizes: "",
          standardSize_id: [],
          thickness_id: [],

          alt: "",
          altEn: "",
          file: "",
          // image: "",
          // alt: "",
          // file: "",
        });
      }
    });
  }, []);
  useEffect(() => {
    let unblock;
    if (!disableSave && dismissSave) {
      unblock = history.block((tx) => {
        let url = tx.location.pathname;
        if (window.confirm(`هل أنت متأكد أنك تريد تجاهل التغييرات؟`)) {
          unblock();

          tx.retry();
        }
      });
    }

    return () => {
      if (typeof unblock === "function") {
        unblock();
      }
    };
  }, [disableSave, dismissSave]);

  return (
    <div className="Assumptions">
      <>
        <MainBox px={0} mb={0}>
          {id === undefined ? (
            <MainTitle title="إضافة منتج" bBottom={false} px={7} />
          ) : (
            <MainTitle title="تعديل منتج" bBottom={false} px={7} />
          )}
        </MainBox>
        <Box className="" pb={6}>
          <Formik
            initialValues={initFormVal}
            enableReinitialize={true}
            validationSchema={validationProductForm}
            validate={(values) => {
              if (disableSave) {
                setDisableSave(false);
              }
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2));
                setSubmitting(false);
              }, 400);
            }}
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              handleBlur,
              setFieldValue,
              isValid,
              dirty,
            }) => (
              <Form className="form_wrapper">
                <ProductForm
                  onCancelOpen={onCancelOpen}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  formikValues={values}
                  isValid={isValid}
                  dirty={dirty}
                  disableSave={disableSave}
                  setDisableSave={setDisableSave}
                  dismissSave={dismissSave}
                  setDismissSave={setDismissSave}
                  onSubmitOpen={onSubmitOpen}
                  onUploadOpen={onUploadOpen}
                  handleOnSubmit={handleOnSubmit}
                  setInitFormVal={setInitFormVal}
                  generalData={generalData}
                  isUploadOpen={isUploadOpen}
                  onUploadClose={onUploadClose}
                  onErrorOpen={onErrorOpen}
                  setErrorMessage={setErrorMessage}
                  setImageObj={setImageObj}
                  dropdownObj={dropdownObj}
                  id={id}
                />

                <ModalAlert
                  isOpen={isCancelOpen}
                  onClose={onCancelClose}
                  title="هل أنت متأكد أنك تريد تجاهل التغييرات؟"
                  icon={infoCircle}
                  hasDesc={false}
                  confirmationModal={true}
                  path={`/ProductsHistory`}
                  setDismissSave={() => setDismissSave(true)}
                />
              </Form>
            )}
          </Formik>
        </Box>

        <ModalAlert
          isOpen={isErrorOpen}
          onClose={onErrorClose}
          title={errorMessage}
          icon={infoCircle}
          hasDesc={false}
          confirmationModal={false}
        />

        <ModalAlert
          isOpen={isSubmitOpen}
          onClose={onSubmitClose}
          title={submitModalTitle}
          btnWidthFull={true}
          icon={checked}
          hasDesc={false}
          disableClose={true}
          path={`/ProductsHistory`}
        />
      </>
    </div>
  );
};
