import React from "react";

import { ErrorMessage } from "formik";
import DatePicker from "react-datepicker";
import { Box, Text } from "@chakra-ui/react";
import { format as formatFNS } from "date-fns";

import styles from "../forms.module.scss";

// - You can hide the label throw putting labelVisibility to false
export const DatePickerField = ({
  label,
  inputType,
  inputId,
  inputName,
  isRequired = true,
  labelVisibility = true,
  placeholder,
  errors,
  touched,
  dColumn = false,
  minDate,
  maxDate,
  fullWidth = "",
  icon,
  hintText,
  values,
  onBlur,
  onChange,
  uppercase = true,
  format = "year",
  isDisable,
  handleClose,
}) => {
  const errorInputClassname =
    errors[inputName] && touched[inputName] && errors[inputName]
      ? `${styles.form_control} ${styles.form_control_error} ${
          dColumn ? "flex_column" : ""
        }`
      : `${styles.form_control} ${dColumn ? "flex_column" : ""}`;

  let formInputClassname = labelVisibility
    ? `${styles.form_control_input} ${fullWidth}`
    : `${styles.form_control_input} ${styles.form_control_full}  ${fullWidth}`;

  formInputClassname +=
    format === "year" && format !== "fullDate"
      ? `datepicker_year`
      : `datepicker_year_month`;
  formInputClassname +=
    format === "fullDate" && format !== "year" ? ` datepicker_fullDate ` : ``;

  let labelUppercaseClassname = uppercase ? `${styles.uppercase}` : "";

  const renderDatePicker = () => {
    if (format === "year") {
      return (
        <DatePicker
          id={inputId}
          name={inputName}
          onBlur={onBlur}
          selected={(values && new Date(values)) || null}
          onChange={(val) => {
            onChange(inputName, val);
          }}
          dateFormat="yyyy"
          minDate={minDate}
          maxDate={maxDate}
          showYearPicker
          disabled={isDisable}
        />
      );
    } else if (format === "yearMonth") {
      return (
        <>
          <DatePicker
            onCalendarClose={handleClose}
            id={inputId}
            name={inputName}
            onBlur={onBlur}
            selected={(values && new Date(values)) || null}
            onChange={(val) => {
              onChange(inputName, val);
            }}
            dateFormat="MM/yyyy"
            minDate={minDate}
            maxDate={maxDate}
            showMonthYearPicker
            disabled={isDisable}
          />
        </>
      );
    } else if (format === "fullDate") {
      let newValue;
      let newDate;
      // console.log("values", values);
      if (!!values && !`${values}`?.includes("GMT")) {
        newValue = `${values}`.split("/");
        newDate = newValue[1] + "/" + newValue[0] + "/" + newValue[2];

        // console.log("newDate", newDate);
      } else {
        if (!!values) {
          // console.log(
          //   "values format",
          //   formatFNS(new Date(values), "dd/mm/yyyy")
          // );
          // newValue = formatFNS(new Date(values), "dd/mm/yyyy");
          // console.log(
          //   "values format no dep",
          //   new Date(values).getDate() +
          //     "/" +
          //     parseInt(new Date(values).getMonth() + 1) +
          //     "/" +
          //     new Date(values).getFullYear()
          // );
          newDate =
            new Date(values).getDate() +
            "/" +
            parseInt(new Date(values).getMonth() + 1) +
            "/" +
            new Date(values).getFullYear();
          newValue = `${newDate}`.split("/");
          newDate = newValue[1] + "/" + newValue[0] + "/" + newValue[2];
        } else {
          newDate = values;
        }
      }
      return (
        <>
          <DatePicker
            onCalendarClose={handleClose}
            id={inputId}
            name={inputName}
            onBlur={onBlur}
            selected={(newDate && new Date(newDate)) || null}
            onChange={(val) => {
              if (!!val && !`${val}`?.includes("GMT")) {
                newValue = `${val}`.split("/");
                newDate = newValue[1] + "/" + newValue[0] + "/" + newValue[2];
              } else {
                if (!!val) {
                  newDate =
                    new Date(val).getDate() +
                    "/" +
                    parseInt(new Date(val).getMonth() + 1) +
                    "/" +
                    new Date(val).getFullYear();
                  newValue = `${newDate}`.split("/");
                } else {
                  newDate = val;
                }
              }
              onChange(inputName, newDate);
            }}
            dateFormat="dd/MM/yyyy"
            disabled={isDisable}
          />
        </>
      );
    }
  };

  return (
    <div className={errorInputClassname}>
      {labelVisibility && (
        <label
          htmlFor={inputId}
          className={`${fullWidth} ${labelUppercaseClassname}`}
        >
          {label}
        </label>
      )}
      <div className={formInputClassname}>
        {renderDatePicker()}

        {icon && (
          <Box className="iconic_input">
            <img src={icon} alt="date" />
          </Box>
        )}
        {isRequired && (
          <ErrorMessage
            name={inputName}
            component="div"
            className={styles.error}
          />
        )}
        {hintText && (
          <Text mt="2" mb="3" className="hint_sm">
            {hintText}
          </Text>
        )}
      </div>
    </div>
  );
};
