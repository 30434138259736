import React, { useState, useEffect, useCallback } from "react";

import { Box, Text, Center, Spinner, useDisclosure } from "@chakra-ui/react";

import { GetMetaAPI } from "../../api/MetaAPI";

import { MainTitle } from "../../components/mainTitle/MainTitle";
import { MainBox } from "../../components/mainBox/MainBox";
import { MainButton } from "../../components/button/MainButton";
import { MetaTable } from "./MetaTable";
import { ModalAlert } from "../../components/modalAlert/ModalAlert";

import doc from "../../assets/images/iconsSvg/doc.svg";
import plus from "../../assets/images/iconsSvg/plus.svg";
import infoCircle from "../../assets/images/iconsSvg/infoCircle.svg";

export const MetaHistory = () => {
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();
  const [formData, setFormData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const loadTableData = useCallback(() => {
    document.getElementById("main_content").scrollTo(0, 0);

    GetMetaAPI()
      .then((res) => {
        setFormData(res);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    loadTableData();
  }, [loadTableData]);

  return (
    <>
      {isLoading ? (
        <>
          <MainTitle title="Meta" />
          <MainBox>
            <Box paddingTop={12} paddingBottom={12}>
              <Center>
                <Spinner />
              </Center>
            </Box>
          </MainBox>
        </>
      ) : (
        <>
          {formData?.data?.length <= 0 ? (
            <>
              <MainTitle title="Meta" />
              <MainBox>
                <Box className="table_content" pt={6} pb={6}>
                  <Center flexDirection="column">
                    <img src={doc} alt="icon" />
                    <Box mt={3} mb={5}>
                      <Text className="title_sm"> لا يوجد إدخالات حالياً </Text>
                    </Box>
                    <MainButton
                      title="إضافة"
                      marginRight="1"
                      icon={plus}
                      navLink={true}
                      path="/Meta"
                    />
                  </Center>
                </Box>
              </MainBox>
            </>
          ) : (
            <>
              <MetaTable formData={formData} />
            </>
          )}
        </>
      )}

      <ModalAlert
        isOpen={isErrorOpen}
        onClose={onErrorClose}
        title={errorMessage}
        icon={infoCircle}
        hasDesc={false}
        confirmationModal={false}
      />
    </>
  );
};
