import { useState, useEffect } from "react";
import {
  Box,
  Flex,
  HStack,
  TabPanel,
  TabPanels,
  Tabs,
  Image,
} from "@chakra-ui/react";

import { GetFactoryAPI } from "../../../api/AboutUsAPI";

import { MainButton } from "../../../components/button/MainButton";
import { TablePagination2 } from "../../../components/tablePagination/TablePagination2";

export const FactoryTable = ({ generalData }) => {
  let [currPagData, setCurrPagData] = useState({
    page: 1,
  });

  const [dataArray, setDataArray] = useState([]);
  const columns = [
    {
      name: "الصورة",
      selector: (row) => row.image,
      cell: (row) => (
        <Box color={`#007BB5`} paddingY="10px">
          <Image
            width="60px"
            height="60px"
            src={row.image}
            //borderRadius="full"
          ></Image>
        </Box>
      ),
    },
    {
      name: "العنوان",
      selector: (row) => row.title,
    },
    {
      name: "الإجراء",
      selector: (row) => row.action,
      cell: (row) => (
        <Flex
          justifyContent="space-between"
          gap="15px"
          alignItems="center"
          flexDirection={["column", "column", "row"]}
        >
          <MainButton
            title="تعديل"
            btnStyle="transparent"
            fontColor="dark"
            marginRight="5"
            navLink={true}
            path={`/Factory/${row.idCustom}`}
            //fSize="sm"
          />
        </Flex>
      ),
    },
  ];

  useEffect(() => {
    let data = [];
    if (generalData?.data?.length > 0 && !!generalData) {
      generalData.data.forEach((item) => {
        let dataObj = {
          id: `${item.id + Math.floor(Math.random() * Date.now())}`,
          idCustom: `${item.id}`,
          title: `${item.title}`,
          image: `${item.Image1.url}`,
        };

        data.push(dataObj);
      });
      setDataArray(data);
    }
    setCurrPagData({
      page: 1,
      // sortOrder: "DESC",
      // sort: "lastModifiedAt",
    });
  }, []);

  const handleOnPageChange = (page, sort = "lastModifiedAt", sortOrder) => {
    //GetFactoryAPI(page, sort, sortOrder)
    GetFactoryAPI(10, 0, page)
      .then((res) => {
        let data = [];

        if (res.data.length > 0) {
          res.data.forEach((item) => {
            let dataObj = {
              id: `${item.id + Math.floor(Math.random() * Date.now())}`,
              idCustom: `${item.id}`,
              title: `${item.title}`,
              image: `${item.Image1.url}`,
            };

            data.push(dataObj);
          });

          setDataArray(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <HStack width="100%">
        <Tabs variant="unstyled" width="100%">
          <TabPanels>
            <TabPanel w="98%" px="0">
              <TablePagination2
                data={dataArray}
                columns={columns}
                isSearchable={false}
                title="المصنع"
                hasDelete={true}
                totalRows={generalData.totalRows}
                pageSize={generalData.limit}
                GetDataAPI={GetFactoryAPI}
                handleOnPageChange={handleOnPageChange}
                setCurrPagData={setCurrPagData}
                currPagData={currPagData}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </HStack>
    </>
  );
};
