import { useState, useEffect } from "react";
import {
  Flex,
  HStack,
  TabPanel,
  TabPanels,
  Tabs,
  Box,
  Image,
} from "@chakra-ui/react";

import { GetConsultaionFormAPI } from "../../api/ContactsAPI";

import { MainButton } from "../../components/button/MainButton";
import { TablePagination2 } from "../../components/tablePagination/TablePagination2";

export const ConsultaionFormTable = ({
  onDelModalOpen,
  formData,
  setSelectedID,
  seoRole,
}) => {
  let [currPagData, setCurrPagData] = useState({
    page: 1,
  });

  const [dataArray, setDataArray] = useState([]);
  const columns = [
    {
      name: "الإسم",
      selector: (row) => row.name,
    },
    {
      name: "تاريخ الإستشارة",
      selector: (row) => row.orderDate,
    },
    {
      name: "الإجراء",
      selector: (row) => row.action,
      cell: (row) => (
        <Flex
          justifyContent="space-between"
          gap="15px"
          alignItems="center"
          flexDirection={["column", "column", "row"]}
        >
          <MainButton
            title="تفاصيل"
            btnStyle="transparent"
            fontColor="dark"
            marginRight="5"
            navLink={true}
            path={`/ConsultaionForm/${row.idCustom}`}
          />
          {!seoRole && (
            <MainButton
              title="حذف"
              btnStyle="transparent"
              fontColor="danger"
              marginRight="5"
              onPress={() => {
                setSelectedID(`delete-${row.idCustom}`);
                onDelModalOpen();
              }}
            />
          )}
        </Flex>
      ),
    },
  ];

  useEffect(() => {
    let data = [];
    if (formData?.data?.length > 0 && !!formData) {
      formData?.data.forEach((item) => {
        let dataObj = {
          id: `${item.id + Math.floor(Math.random() * Date.now())}`,
          idCustom: `${item.id}`,
          name: `${item.firstName} ${item.lastName}`,
          image: `${item.image}`,
          orderDate: `${item.order_date}`.split("T")[0],
        };

        data.push(dataObj);
      });
      setDataArray(data);
    }
    setCurrPagData({
      page: 1,
    });
  }, []);

  const handleOnPageChange = (page) => {
    GetConsultaionFormAPI(10, 0, page)
      .then((res) => {
        let data = [];

        if (res?.data.length > 0) {
          res?.data.forEach((item) => {
            let dataObj = {
              id: `${item.id + Math.floor(Math.random() * Date.now())}`,
              idCustom: `${item.id}`,
              name: `${item.firstName} ${item.lastName}`,
              image: `${item.image}`,
              orderDate: `${item.order_date}`.split("T")[0],
            };

            data.push(dataObj);
          });

          setDataArray(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <HStack width="100%">
        <Tabs variant="unstyled" width="100%">
          <TabPanels>
            <TabPanel w="98%" px="0">
              <TablePagination2
                data={dataArray}
                columns={columns}
                isSearchable={false}
                title="الإستشارات"
                hasDelete={true}
                totalRows={formData.totalRows}
                pageSize={formData.limit}
                GetDataAPI={GetConsultaionFormAPI}
                handleOnPageChange={handleOnPageChange}
                setCurrPagData={setCurrPagData}
                currPagData={currPagData}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </HStack>
    </>
  );
};
