import React, { useState } from "react";
import { UploadAPI } from "../../../api/HomeAPI";

import {
  Box,
  Divider,
  Flex,
  Text,
  Image,
  Input,
  Center,
  Spinner,
  Button,
} from "@chakra-ui/react";

import { InputFieldValidation } from "../../../components/inputField/InputFieldValidation";
import { MainButton } from "../../../components/button/MainButton";
import { Thumb } from "../../../components/thumb/Thumb";
import { ModalAlert2 } from "../../../components/modalAlert/ModalAlert2";

export const ServicesForm = ({
  errors,
  touched,
  setFieldValue,
  handleBlur,
  onCancelOpen,
  formikValues,
  dirty,
  isValid,
  setDisableSave,
  disableSave,
  dismissSave,
  setDismissSave,
  handleOnSubmit,
  onUploadOpen,
  setInitFormVal,
  generalData,
  onUploadClose,
  isUploadOpen,
  setErrorMessage,
  setImageObj,
  onErrorOpen,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const download = (e, url) => {
    e.preventDefault();
    fetch(url, {
      method: "GET",
      mode: "no-cors",
      credentials: "include",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          // const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("target", "_blank"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUploadImage = (values) => {
    setIsLoading(true);
    let submitValues = {
      file: values.file,
      alt: values.altAr,
      altEn: values.altEn,
    };
    setErrorMessage("");
    UploadAPI(submitValues)
      .then((res) => {
        setImageObj(res);

        setFieldValue("image", res.id);
        setFieldValue("alt", res.alt);
        setIsLoading(false);
        onUploadClose();
        setTimeout(() => {
          setFieldValue(`file`, "");
          setFieldValue(`altAr`, "");
          setFieldValue(`altEn`, "");
        }, 300);
        // if (res.success === true) {
        //   setDisableSave(true);
        //   setErrorMessage("");
        //   onSubmitOpen();
        // }
        return res;
      })
      .catch((err) => {
        setErrorMessage(err?.response?.data?.message || "هناك خطأ ما");
        onErrorOpen();
      });
  };
  return (
    <>
      <Box
        maxW={["100%", "100%", "40%"]}
        width={["100%", "100%", "40%"]}
        alignSelf="flex-start"
      >
        <InputFieldValidation
          label="العنوان"
          inputType="text"
          inputId="title"
          inputName="title"
          errors={errors}
          touched={touched}
          isRequired
          onChange={setFieldValue}
          values={formikValues}
        />
        <InputFieldValidation
          label="العنوان الإنجليزي"
          inputType="text"
          inputId="titleEn"
          inputName="titleEn"
          errors={errors}
          touched={touched}
          isRequired
          onChange={setFieldValue}
          values={formikValues}
        />

        <Box paddingY="10px">
          {!!generalData?.imageURL && (
            <>
              <Text
                className="title_sm"
                display="flex"
                mb={2}
                mt={0}
                component="div"
                textTransform="uppercase"
                fontSize="12px"
              >
                الصورة الحالية
              </Text>
              <Button
                marginBottom="10px"
                onClick={(e) => download(e, generalData.imageURL)}
              >
                عرض الملف
              </Button>
              <Image
                src={generalData.imageURL}
                width="200px"
                height="200px"
                mb="15px"
              />
            </>
          )}

          <MainButton
            title="رفع صورة"
            reverseRow={true}
            onPress={() => {
              setInitFormVal({ ...formikValues });
              onUploadOpen();
            }}
          />
        </Box>
        <Box display="none">
          <InputFieldValidation
            label="Image"
            inputType="text"
            inputId="image"
            inputName="image"
            errors={errors}
            touched={touched}
            isRequired
            onChange={setFieldValue}
            values={formikValues}
            isDisabled={true}
          />
        </Box>
        <InputFieldValidation
          label="alt Image"
          inputType="text"
          inputId="alt"
          inputName="alt"
          errors={errors}
          touched={touched}
          isRequired
          onChange={setFieldValue}
          values={formikValues}
          isDisabled={true}
        />
        <InputFieldValidation
          label="alt En"
          inputType="text"
          inputId="altEn2"
          inputName="altEn2"
          errors={errors}
          touched={touched}
          isRequired
          onChange={setFieldValue}
          values={formikValues}
          isDisabled={true}
        />
      </Box>

      <Divider orientation="horizontal" mt="20px" mb="30px" />
      <Flex justifyContent="flex-end" alignItems="center" width="100%">
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="إلغاء"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="إلغاء"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={`/ServicesHistory`}
            />
          )}

          <MainButton
            title="حفظ"
            reverseRow={true}
            disabledBtn={isValid && dirty ? false : true}
            onPress={() => {
              handleOnSubmit(formikValues);
            }}
          />
        </Box>
      </Flex>

      <ModalAlert2
        isOpen={isUploadOpen}
        onClose={onUploadClose}
        title="رفع صورة"
      >
        <Box className="form_wrapper">
          {isLoading ? (
            <Box paddingTop={12} paddingBottom={12}>
              <Center>
                <Spinner />
              </Center>
            </Box>
          ) : (
            <>
              <Input
                paddingTop="5px"
                marginBottom="15px"
                size="md"
                type="file"
                name="file"
                errors={errors}
                touched={touched}
                values={formikValues}
                accept="image/*"
                onChange={(event) => {
                  setFieldValue("file", event.currentTarget.files[0]);
                }}
              />

              <InputFieldValidation
                label="alt"
                inputType="text"
                inputId="altAr"
                inputName="altAr"
                errors={errors}
                touched={touched}
                isRequired
                onChange={setFieldValue}
                values={formikValues}
              />
              <InputFieldValidation
                label="altEn"
                inputType="text"
                inputId="altEn"
                inputName="altEn"
                errors={errors}
                touched={touched}
                isRequired
                onChange={setFieldValue}
                values={formikValues}
              />

              <Box width="200px" height="200px">
                <Thumb file={formikValues?.file} />
              </Box>

              <MainButton
                title="رفع"
                type="submit"
                reverseRow={true}
                onPress={() => {
                  handleUploadImage(formikValues);
                }}
              />
            </>
          )}
        </Box>
      </ModalAlert2>
    </>
  );
};
