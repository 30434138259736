import React, { useState } from "react";
import { UploadAPI } from "../../../api/HomeAPI";

import {
  Box,
  Divider,
  Flex,
  SimpleGrid,
  GridItem,
  Text,
  Image,
  Button,
} from "@chakra-ui/react";

import { InputFieldValidation } from "../../../components/inputField/InputFieldValidation";
import { MainButton } from "../../../components/button/MainButton";
import { TextArea } from "../../../components/textArea/TextArea";
import { SelectListMulti } from "../../../components/selectField/SelectListMulti";

export const OrderForm = ({
  errors,
  touched,
  setFieldValue,
  handleBlur,
  onCancelOpen,
  formikValues,
  dirty,
  isValid,
  setDisableSave,
  disableSave,
  dismissSave,
  setDismissSave,
  handleOnSubmit,
  onUploadOpen,
  generalData,
  onUploadClose,
  isUploadOpen,
  setErrorMessage,
  setImageObj,
  onErrorOpen,
  dropdownObj,
}) => {
  const download = (e, url) => {
    e.preventDefault();
    fetch(url, {
      method: "GET",
      mode: "no-cors",
      credentials: "include",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          // const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("target", "_blank"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Box
        maxW={["100%", "100%", "60%"]}
        width={["100%", "100%", "60%"]}
        alignSelf="flex-start"
      >
        <InputFieldValidation
          label="اللقب"
          inputType="text"
          inputId="title"
          inputName="title"
          errors={errors}
          touched={touched}
          isRequired
          onChange={setFieldValue}
          values={formikValues}
          isDisabled={true}
        />
        <SimpleGrid columns={{ sm: 4, md: 4 }} spacingX="15px" width="100%">
          <GridItem colSpan={4}>
            <SimpleGrid columns={{ sm: 4, md: 4 }} spacing="15px" width="100%">
              <GridItem colSpan={2}>
                <InputFieldValidation
                  label="الإسم الأول"
                  inputType="text"
                  inputId="firstName"
                  inputName="firstName"
                  errors={errors}
                  touched={touched}
                  isRequired
                  onChange={setFieldValue}
                  values={formikValues}
                  isDisabled={true}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <InputFieldValidation
                  label="الإسم الأخير"
                  inputType="text"
                  inputId="lastName"
                  inputName="lastName"
                  errors={errors}
                  touched={touched}
                  isRequired
                  placeholder=""
                  onChange={setFieldValue}
                  values={formikValues}
                  isDisabled={true}
                />
              </GridItem>
            </SimpleGrid>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid columns={{ sm: 4, md: 4 }} spacingX="15px" width="100%">
          <GridItem colSpan={4}>
            <SimpleGrid columns={{ sm: 4, md: 4 }} spacing="15px" width="100%">
              <GridItem colSpan={2}>
                <InputFieldValidation
                  label="الإيميل"
                  inputType="text"
                  inputId="email"
                  inputName="email"
                  errors={errors}
                  touched={touched}
                  isRequired
                  onChange={setFieldValue}
                  values={formikValues}
                  isDisabled={true}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <InputFieldValidation
                  label="رقم الهاتف"
                  inputType="text"
                  inputId="mobile"
                  inputName="mobile"
                  errors={errors}
                  touched={touched}
                  isRequired
                  placeholder=""
                  onChange={setFieldValue}
                  values={formikValues}
                  isDisabled={true}
                />
              </GridItem>
            </SimpleGrid>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid columns={{ sm: 4, md: 4 }} spacingX="15px" width="100%">
          <GridItem colSpan={4}>
            <SimpleGrid columns={{ sm: 4, md: 4 }} spacing="15px" width="100%">
              <GridItem colSpan={2}>
                <InputFieldValidation
                  label="العنوان"
                  inputType="text"
                  inputId="address"
                  inputName="address"
                  errors={errors}
                  touched={touched}
                  isRequired
                  onChange={setFieldValue}
                  values={formikValues}
                  isDisabled={true}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <InputFieldValidation
                  label="المنطقة"
                  inputType="text"
                  inputId="area"
                  inputName="area"
                  errors={errors}
                  touched={touched}
                  isRequired
                  placeholder=""
                  onChange={setFieldValue}
                  values={formikValues}
                  isDisabled={true}
                />
              </GridItem>
            </SimpleGrid>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid columns={{ sm: 4, md: 4 }} spacingX="15px" width="100%">
          <GridItem colSpan={4}>
            <SimpleGrid columns={{ sm: 4, md: 4 }} spacing="15px" width="100%">
              <GridItem colSpan={2}>
                <InputFieldValidation
                  label="إسم الشركة"
                  inputType="text"
                  inputId="company"
                  inputName="company"
                  errors={errors}
                  touched={touched}
                  isRequired
                  placeholder=""
                  onChange={setFieldValue}
                  values={formikValues}
                  isDisabled={true}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <InputFieldValidation
                  label="نشاط الشركة"
                  inputType="text"
                  inputId="companyActivity"
                  inputName="companyActivity"
                  errors={errors}
                  touched={touched}
                  isRequired
                  onChange={setFieldValue}
                  values={formikValues}
                  isDisabled={true}
                />
              </GridItem>
            </SimpleGrid>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid columns={{ sm: 4, md: 4 }} spacingX="15px" width="100%">
          <GridItem colSpan={4}>
            <SimpleGrid columns={{ sm: 4, md: 4 }} spacing="15px" width="100%">
              <GridItem colSpan={2}>
                <InputFieldValidation
                  label="مقدم الطلب"
                  inputType="text"
                  inputId="type"
                  inputName="type"
                  errors={errors}
                  touched={touched}
                  isRequired
                  onChange={setFieldValue}
                  values={formikValues}
                  isDisabled={true}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <InputFieldValidation
                  label="الإستخدامات"
                  inputType="text"
                  inputId="uses"
                  inputName="uses"
                  errors={errors}
                  touched={touched}
                  isRequired
                  placeholder=""
                  onChange={setFieldValue}
                  values={formikValues}
                  isDisabled={true}
                />
              </GridItem>
            </SimpleGrid>
          </GridItem>
        </SimpleGrid>
        <InputFieldValidation
          label="نوع البناء"
          inputType="tel"
          inputId="constructionType"
          inputName="constructionType"
          errors={errors}
          touched={touched}
          isRequired
          onChange={setFieldValue}
          values={formikValues}
          isDisabled={true}
        />
        <InputFieldValidation
          label="الموقع الإلكتروني"
          inputType="text"
          inputId="website"
          inputName="website"
          errors={errors}
          touched={touched}
          isRequired
          onChange={setFieldValue}
          values={formikValues}
          isDisabled={true}
        />

        <TextArea
          label="التفاصيل"
          inputId="details"
          inputName="details"
          placeholder="Add a details"
          errors={errors}
          touched={touched}
          isRequired
          values={formikValues}
          onChange={setFieldValue}
          onBlur={handleBlur}
          isDisabled={true}
        />
        <Box paddingY="10px">
          {!!generalData?.Attachment?.url && (
            <>
              <Text
                className="title_sm"
                display="flex"
                mb={2}
                mt={0}
                component="div"
                textTransform="uppercase"
                fontSize="12px"
              >
                الصورة الحالية
              </Text>
              <Box>
                {/* <a
                  href={generalData.Attachment.url}
                   download
                >
                  <Button>Download</Button>
                </a> */}

                <Button
                  onClick={(e) => download(e, generalData.Attachment.url)}
                >
                  عرض الملف
                </Button>

                {/* <Image
                  src={generalData.Attachment.url}
                  width="200px"
                  height="200px"
                  mb="15px"
                /> */}
              </Box>
            </>
          )}
        </Box>

        <SelectListMulti
          dropdownItems={dropdownObj.colors}
          label="اللون"
          inputId="colors"
          inputName="colors"
          placeholder="Select"
          errors={errors}
          touched={touched}
          isRequired
          values={formikValues?.colors}
          onChange={setFieldValue}
          onBlur={handleBlur}
          uppercase={true}
          isDisabled={true}
        />
        <SelectListMulti
          dropdownItems={dropdownObj.thickness}
          label="سمك المنتج"
          inputId="product_thikness_id"
          inputName="product_thikness_id"
          placeholder="Select"
          errors={errors}
          touched={touched}
          isRequired
          values={formikValues?.product_thikness_id}
          onChange={setFieldValue}
          onBlur={handleBlur}
          uppercase={true}
          isDisabled={true}
        />
        <SelectListMulti
          dropdownItems={dropdownObj.standardSize}
          label="الحجم القياسي للمنتج"
          inputId="product_standardSize_id"
          inputName="product_standardSize_id"
          placeholder="Select"
          errors={errors}
          touched={touched}
          isRequired
          values={formikValues?.product_standardSize_id}
          onChange={setFieldValue}
          onBlur={handleBlur}
          uppercase={true}
          isDisabled={true}
        />

        <InputFieldValidation
          label="رقم المنتج"
          inputType="product_id"
          inputId="product_id"
          inputName="product_id"
          errors={errors}
          touched={touched}
          isRequired
          placeholder=""
          onChange={setFieldValue}
          values={formikValues}
          isDisabled={true}
        />
        <InputFieldValidation
          label="تاريخ الطلب"
          inputType="order_date"
          inputId="order_date"
          inputName="order_date"
          errors={errors}
          touched={touched}
          isRequired
          placeholder=""
          onChange={setFieldValue}
          values={formikValues}
          isDisabled={true}
        />

        <SimpleGrid columns={{ sm: 4, md: 4 }} spacingX="15px" width="100%">
          <GridItem colSpan={4}>
            <SimpleGrid columns={{ sm: 4, md: 4 }} spacing="15px" width="100%">
              <GridItem colSpan={2}>
                <InputFieldValidation
                  label="اسم المنتج"
                  inputType="text"
                  inputId="productTitle"
                  inputName="productTitle"
                  errors={errors}
                  touched={touched}
                  isRequired
                  onChange={setFieldValue}
                  values={formikValues}
                  isDisabled={true}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <InputFieldValidation
                  label="اسم المنتج بالانجليزية"
                  inputType="text"
                  inputId="productTitleEn"
                  inputName="productTitleEn"
                  errors={errors}
                  touched={touched}
                  isRequired
                  placeholder=""
                  onChange={setFieldValue}
                  values={formikValues}
                  isDisabled={true}
                />
              </GridItem>
            </SimpleGrid>
            {/* <SimpleGrid columns={{ sm: 4, md: 4 }} spacing="15px" width="100%">
              <GridItem colSpan={2}>
                <InputFieldValidation
                  label="تفاصيل المنتج"
                  inputType="text"
                  inputId="productDesc"
                  inputName="productDesc"
                  errors={errors}
                  touched={touched}
                  isRequired
                  onChange={setFieldValue}
                  values={formikValues}
                  isDisabled={true}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <InputFieldValidation
                  label="تفاصيل المنتج بالانجليزية"
                  inputType="text"
                  inputId="productDescEn"
                  inputName="productDescEn"
                  errors={errors}
                  touched={touched}
                  isRequired
                  placeholder=""
                  onChange={setFieldValue}
                  values={formikValues}
                  isDisabled={true}
                />
              </GridItem>
            </SimpleGrid> */}
            {/* <SimpleGrid columns={{ sm: 4, md: 4 }} spacing="15px" width="100%">
              <GridItem colSpan={2}>
                <InputFieldValidation
                  label="اللون"
                  inputType="text"
                  inputId="colorTitle"
                  inputName="colorTitle"
                  errors={errors}
                  touched={touched}
                  isRequired
                  onChange={setFieldValue}
                  values={formikValues}
                  isDisabled={true}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <InputFieldValidation
                  label="اللون بالانجليزية"
                  inputType="text"
                  inputId="colorTitleEn"
                  inputName="colorTitleEn"
                  errors={errors}
                  touched={touched}
                  isRequired
                  placeholder=""
                  onChange={setFieldValue}
                  values={formikValues}
                  isDisabled={true}
                />
              </GridItem>
            </SimpleGrid>
            <SimpleGrid columns={{ sm: 4, md: 4 }} spacing="15px" width="100%">
              <GridItem colSpan={2}>
                <InputFieldValidation
                  label="السمك"
                  inputType="text"
                  inputId="thickTitle"
                  inputName="thickTitle"
                  errors={errors}
                  touched={touched}
                  isRequired
                  onChange={setFieldValue}
                  values={formikValues}
                  isDisabled={true}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <InputFieldValidation
                  label="الحجم القياسي"
                  inputType="text"
                  inputId="StandardTitle"
                  inputName="StandardTitle"
                  errors={errors}
                  touched={touched}
                  isRequired
                  placeholder=""
                  onChange={setFieldValue}
                  values={formikValues}
                  isDisabled={true}
                />
              </GridItem>
            </SimpleGrid> */}
          </GridItem>
        </SimpleGrid>
      </Box>

      <Divider orientation="horizontal" mt="20px" mb="30px" />
      <Flex justifyContent="flex-end" alignItems="center" width="100%">
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="إلغاء"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="إلغاء"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={`/OrdersHistory`}
            />
          )}

          {/* <MainButton
            title="Submit"
            reverseRow={true}
            disabledBtn={isValid && dirty ? false : true}
            onPress={() => {
              handleOnSubmit(formikValues);
            }}
          /> */}
        </Box>
      </Flex>
    </>
  );
};
