import React from "react";

import { Box, Divider, Flex } from "@chakra-ui/react";

import { MainButton } from "../../../components/button/MainButton";
import { TextArea } from "../../../components/textArea/TextArea";
import { DatePickerField } from "../../../components/datePickerField/DatePickerField";

import clock from "../../../assets/images/iconsSvg/clock.svg";

export const JourneyForm = ({
  errors,
  touched,
  setFieldValue,
  handleBlur,
  onCancelOpen,
  formikValues,
  dirty,
  isValid,
  setDisableSave,
  disableSave,
  dismissSave,
  setDismissSave,
  //isRestore,
  handleOnSubmit,
}) => {
  return (
    <>
      <Box
        maxW={["100%", "100%", "40%"]}
        width={["100%", "100%", "40%"]}
        alignSelf="flex-start"
      >
        <DatePickerField
          label="السنة"
          inputType="date"
          inputId="year"
          inputName="year"
          errors={errors}
          touched={touched}
          isRequired
          values={formikValues.year}
          onChange={setFieldValue}
          onBlur={handleBlur}
          minDate={new Date("01-01-1950")}
          maxDate={new Date("01-01-2050")}
          icon={clock}
        />

        <TextArea
          label="النص"
          inputId="text"
          inputName="text"
          placeholder="أضف النص"
          errors={errors}
          touched={touched}
          isRequired
          values={formikValues}
          onChange={setFieldValue}
          onBlur={handleBlur}
        />
        <TextArea
          label="النص الإنجليزي"
          inputId="textEn"
          inputName="textEn"
          placeholder="أضف النص الإنجليزي"
          errors={errors}
          touched={touched}
          isRequired
          values={formikValues}
          onChange={setFieldValue}
          onBlur={handleBlur}
        />
      </Box>

      <Divider orientation="horizontal" mt="20px" mb="30px" />
      <Flex justifyContent="flex-end" alignItems="center" width="100%">
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="إلغاء"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="إلغاء"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={`/JourneysHistory`}
            />
          )}

          <MainButton
            title="حفظ"
            reverseRow={true}
            disabledBtn={isValid && dirty ? false : true}
            onPress={() => {
              handleOnSubmit(formikValues);
            }}
          />
        </Box>
      </Flex>
    </>
  );
};
