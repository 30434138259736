import React, { useRef, useState } from "react";

import { Route, Routes } from "react-router-dom";

import styles from "./main.module.scss";
import { parseToken } from "../../utils/Auth";

import { Header } from "../header";
import { DashboardHome } from "../../pages/DashboardHome";

import { BannersHistory } from "../../pages/home/banners/BannersHistory";
import { Banner } from "../../pages/home/banners/Banner";
import { UniqueRockHistory } from "../../pages/home/uniqueRock/UniqueRockHistory";
import { UniqueRock } from "../../pages/home/uniqueRock/UniqueRock";
import { UniqueRockOrderHistory } from "../../pages/home/uniqueRockOrders/UniqueRockOrderHistory";
import { UniqueRockOrder } from "../../pages/home/uniqueRockOrders/UniqueRockOrder";
import { JourneysHistory } from "../../pages/aboutUs/journey/JourneysHistory";
import { Journey } from "../../pages/aboutUs/journey/Journey";
import { ServicesHistory } from "../../pages/aboutUs/services/ServicesHistory";
import { Services } from "../../pages/aboutUs/services/Services";
import { FactoryHistory } from "../../pages/aboutUs/factory/FactoryHistory";
import { Factory } from "../../pages/aboutUs/factory/Factory";
import { ConsultationHomeBanner } from "../../pages/home/consultationHomeBanner/ConsultationHomeBanner";
import { ContactDetails } from "../../pages/contacts/contactDetails/ContactDetails";
import { ProjectsHistory } from "../../pages/projects/ProjectsHistory";
import { Projects } from "../../pages/projects/Projects";
import { AboutUs } from "../../pages/aboutUs/about/AboutUs";
import { CareersHistory } from "../../pages/contacts/careers/CareersHistory";
import { Careers } from "../../pages/contacts/careers/Careers";
import { ContactFormHistory } from "../../pages/contacts/contactForm/ContactFormHistory";
import { ContactForm } from "../../pages/contacts/contactForm/ContactForm";
import { MediaHistory } from "../../pages/mediaCenter/media/MediaHistory";
import { Media } from "../../pages/mediaCenter/media/Media";
import { PhotoGalleryHistory } from "../../pages/mediaCenter/photoGallery/PhotoGalleryHistory";
import { PhotoGallery } from "../../pages/mediaCenter/photoGallery/PhotoGallery";
import { UploadHistory } from "../../pages/uploads/UploadHistory";
import { Upload } from "../../pages/uploads/Upload";
import { AvailableJobHistory } from "../../pages/contacts/availableJobs/AvailableJobHistory";
import { AvailableJob } from "../../pages/contacts/availableJobs/AvailableJob";
import { MetaHistory } from "../../pages/meta/MetaHistory";
import { Meta } from "../../pages/meta/Meta";
import { ConsultaionFormHistory } from "../../pages/consultaionForm/ConsultaionFormHistory";
import { ConsultaionForm } from "../../pages/consultaionForm/ConsultaionForm";
import { ProductsHistory } from "../../pages/products/product/ProductsHistory";
import { Product } from "../../pages/products/product/Product";
import { ProductsColorHistory } from "../../pages/products/productsColor/ProductsColorHistory";
import { ProductsColor } from "../../pages/products/productsColor/ProductsColor";
import { ProductsCountryHistory } from "../../pages/products/productsCountry/ProductsCountryHistory";
import { ProductsCountry } from "../../pages/products/productsCountry/ProductsCountry";
import { ProductsThicknessHistory } from "../../pages/products/productsThickness/ProductsThicknessHistory";
import { ProductsThickness } from "../../pages/products/productsThickness/ProductsThickness";
import { ProductsStandardSizeHistory } from "../../pages/products/productsStandardSize/ProductsStandardSizeHistory";
import { ProductsStandardSize } from "../../pages/products/productsStandardSize/ProductsStandardSize";
import { OrdersHistory } from "../../pages/products/order/OrdersHistory";
import { Order } from "../../pages/products/order/Order";
import { DirectReqFormHistory } from "../../pages/products/directReqForm/DirectReqFormHistory";
import { DirectReqForm } from "../../pages/products/directReqForm/DirectReqForm";
import { CkEditor } from "../../pages/CkEditor";

export const Main = ({ user, isOpen }) => {
  const navbarRef = useRef(null);

  const handleScroll = (event) => {
    if (event.currentTarget.scrollTop > 100) {
      navbarRef.current.classList.add(`${styles.navbar_scroll}`);
    } else {
      navbarRef.current.classList.remove(`${styles.navbar_scroll}`);
    }
  };

  const renderRoutes = () => {
    let userRole =
      parseToken()?.role === "ROLE_MODERATOR" ? "ROLE_MODERATOR" : "admin";

    // let userRole = "admin";
    // console.log("userrole", userRole);
    switch (userRole) {
      case "admin":
        return (
          <Routes>
            <Route path="/" element={<DashboardHome />} />
            <Route path="/CkEditor" element={<CkEditor />} />

            <Route path="/BannersHistory" element={<BannersHistory />} />
            <Route path="/Banner" element={<Banner />} />
            <Route path="/Banner/:id" element={<Banner />} />
            <Route path="/UniqueRockHistory" element={<UniqueRockHistory />} />
            <Route path="/UniqueRock" element={<UniqueRock />} />
            <Route path="/UniqueRock/:id" element={<UniqueRock />} />
            <Route
              path="/UniqueRockOrderHistory"
              element={<UniqueRockOrderHistory />}
            />
            <Route path="/UniqueRockOrder" element={<UniqueRockOrder />} />
            <Route path="/UniqueRockOrder/:id" element={<UniqueRockOrder />} />
            <Route path="/JourneysHistory" element={<JourneysHistory />} />
            <Route path="/Journey" element={<Journey />} />
            <Route path="/Journey/:id" element={<Journey />} />
            <Route path="/FactoryHistory" element={<FactoryHistory />} />
            <Route path="/Factory" element={<Factory />} />
            <Route path="/Factory/:id" element={<Factory />} />
            <Route path="/ServicesHistory" element={<ServicesHistory />} />
            <Route path="/Services" element={<Services />} />
            <Route path="/Services/:id" element={<Services />} />
            <Route
              path="/ConsultationHomeBanner/:id"
              element={<ConsultationHomeBanner />}
            />
            <Route path="/ContactDetails/:id" element={<ContactDetails />} />
            <Route path="/ProjectsHistory" element={<ProjectsHistory />} />
            <Route path="/Projects" element={<Projects />} />
            <Route path="/Projects/:id" element={<Projects />} />
            <Route path="/AboutUs/:id" element={<AboutUs />} />
            <Route path="/CareersHistory" element={<CareersHistory />} />
            <Route path="/Careers" element={<Careers />} />
            <Route path="/Careers/:id" element={<Careers />} />
            <Route
              path="/ContactFormHistory"
              element={<ContactFormHistory />}
            />
            <Route path="/ContactForm" element={<ContactForm />} />
            <Route path="/ContactForm/:id" element={<ContactForm />} />
            <Route path="/MediaHistory/:type" element={<MediaHistory />} />
            <Route path="/Media/:type" element={<Media />} />
            <Route path="/Media/:type/:id" element={<Media />} />
            <Route
              path="/PhotoGalleryHistory"
              element={<PhotoGalleryHistory />}
            />
            <Route path="/PhotoGallery" element={<PhotoGallery />} />
            <Route path="/PhotoGallery/:id" element={<PhotoGallery />} />
            <Route path="/UploadHistory" element={<UploadHistory />} />
            <Route path="/Upload" element={<Upload />} />
            <Route path="/Upload/:id" element={<Upload />} />
            <Route
              path="/AvailableJobHistory"
              element={<AvailableJobHistory />}
            />
            <Route path="/AvailableJob" element={<AvailableJob />} />
            <Route path="/AvailableJob/:id" element={<AvailableJob />} />
            <Route path="/MetaHistory" element={<MetaHistory />} />
            <Route path="/Meta" element={<Meta />} />
            <Route path="/Meta/:id/:page" element={<Meta />} />
            <Route
              path="/ConsultaionFormHistory"
              element={<ConsultaionFormHistory />}
            />
            <Route path="/ConsultaionForm" element={<ConsultaionForm />} />
            <Route path="/ConsultaionForm/:id" element={<ConsultaionForm />} />
            <Route path="/ProductsHistory" element={<ProductsHistory />} />
            <Route path="/Product" element={<Product />} />
            <Route path="/Product/:id" element={<Product />} />
            <Route path="/OrdersHistory" element={<OrdersHistory />} />
            <Route path="/Order" element={<Order />} />
            <Route path="/Order/:id" element={<Order />} />
            <Route
              path="/ProductsColorHistory"
              element={<ProductsColorHistory />}
            />
            <Route path="/ProductsColor" element={<ProductsColor />} />
            <Route path="/ProductsColor/:id" element={<ProductsColor />} />
            <Route
              path="/ProductsCountryHistory"
              element={<ProductsCountryHistory />}
            />
            <Route path="/ProductsCountry" element={<ProductsCountry />} />
            <Route path="/ProductsCountry/:id" element={<ProductsCountry />} />
            <Route
              path="/ProductsThicknessHistory"
              element={<ProductsThicknessHistory />}
            />
            <Route path="/ProductsThickness" element={<ProductsThickness />} />
            <Route
              path="/ProductsThickness/:id"
              element={<ProductsThickness />}
            />
            <Route
              path="/ProductsStandardSizeHistory"
              element={<ProductsStandardSizeHistory />}
            />
            <Route
              path="/ProductsStandardSize"
              element={<ProductsStandardSize />}
            />
            <Route
              path="/ProductsStandardSize/:id"
              element={<ProductsStandardSize />}
            />
            <Route
              path="/DirectReqFormHistory"
              element={<DirectReqFormHistory />}
            />
            <Route path="/DirectReqForm" element={<DirectReqForm />} />
            <Route path="/DirectReqForm/:id" element={<DirectReqForm />} />
          </Routes>
        );
      case "ROLE_MODERATOR":
        return (
          <Routes>
            <Route path="/" element={<DashboardHome />} />
            <Route path="/CkEditor" element={<CkEditor />} />

            <Route path="/BannersHistory" element={<BannersHistory />} />
            <Route path="/Banner/:id" element={<Banner />} />
            <Route path="/UniqueRockHistory" element={<UniqueRockHistory />} />
            <Route path="/UniqueRock/:id" element={<UniqueRock />} />
            <Route
              path="/UniqueRockOrderHistory"
              element={<UniqueRockOrderHistory />}
            />
            <Route path="/JourneysHistory" element={<JourneysHistory />} />
            <Route path="/Journey/:id" element={<Journey />} />
            <Route path="/FactoryHistory" element={<FactoryHistory />} />
            <Route path="/Factory/:id" element={<Factory />} />
            <Route path="/ServicesHistory" element={<ServicesHistory />} />
            <Route path="/Services/:id" element={<Services />} />
            <Route
              path="/ConsultationHomeBanner/:id"
              element={<ConsultationHomeBanner />}
            />
            <Route path="/ContactDetails/:id" element={<ContactDetails />} />
            <Route path="/ProjectsHistory" element={<ProjectsHistory />} />
            <Route path="/Projects/:id" element={<Projects />} />
            <Route path="/AboutUs/:id" element={<AboutUs />} />

            <Route path="/MediaHistory/:type" element={<MediaHistory />} />
            <Route path="/Media/:type" element={<Media />} />
            <Route path="/Media/:type/:id" element={<Media />} />
            <Route
              path="/PhotoGalleryHistory"
              element={<PhotoGalleryHistory />}
            />
            <Route path="/PhotoGallery/:id" element={<PhotoGallery />} />
            <Route path="/UploadHistory" element={<UploadHistory />} />
            <Route path="/Upload" element={<Upload />} />
            <Route path="/Upload/:id" element={<Upload />} />

            <Route path="/MetaHistory" element={<MetaHistory />} />
            <Route path="/Meta/:id/:page" element={<Meta />} />
            <Route path="/ConsultaionForm/:id" element={<ConsultaionForm />} />
            <Route path="/ProductsHistory" element={<ProductsHistory />} />
            <Route path="/Product/:id" element={<Product />} />

            <Route
              path="/DirectReqFormHistory"
              element={<DirectReqFormHistory />}
            />
            <Route path="/DirectReqForm/:id" element={<DirectReqForm />} />
          </Routes>
        );
      default:
        return (
          <Routes>
            <Route path="/" element={<DashboardHome />} />
          </Routes>
        );
    }
  };

  return (
    <div id="main_content" className={styles.content} onScroll={handleScroll}>
      <Header ref={navbarRef} user={user} isOpen={isOpen} />
      <div className={styles.container}>{renderRoutes()}</div>
    </div>
  );
};
