import { useState, useEffect } from "react";
import {
  Box,
  Flex,
  HStack,
  TabPanel,
  TabPanels,
  Tabs,
  Image,
} from "@chakra-ui/react";

import { GetPhotoGalleryAPI } from "../../../api/MediaAPI";

import { MainButton } from "../../../components/button/MainButton";
import { TablePagination2 } from "../../../components/tablePagination/TablePagination2";

export const PhotoGalleryTable = ({
  onDelModalOpen,
  generalData,
  setSelectedID,
  seoRole,
}) => {
  let [currPagData, setCurrPagData] = useState({
    page: 1,
    // sortOrder: "DESC",
    // sort: "lastModifiedAt",
  });
  const handleSort = async (column, sortDirection) => {
    // let sortOrder = sortDirection.toUpperCase();
    // let sort = column.sortField;
    //console.log("handleSort", sort, sortOrder, currPagData);
    handleOnPageChange(currPagData.page /* , sort, sortOrder */);
    setCurrPagData({ ...currPagData /* , sort: sort, sortOrder: sortOrder */ });
  };
  //console.log("currPagData", currPagData);
  const [dataArray, setDataArray] = useState([]);
  const columns = [
    {
      name: "الصورة",
      selector: (row) => row.image,
      cell: (row) => (
        <Box color={`#007BB5`} paddingY="10px">
          <Image width="60px" height="60px" src={row.image}></Image>
        </Box>
      ),
    },
    {
      name: "العنوان",
      selector: (row) => row.title,
    },

    {
      name: "الإجراء",
      selector: (row) => row.action,
      cell: (row) => (
        <Flex
          justifyContent="space-between"
          gap="15px"
          alignItems="center"
          flexDirection={["column", "column", "row"]}
        >
          <MainButton
            title="تعديل"
            btnStyle="transparent"
            fontColor="dark"
            marginRight="5"
            navLink={true}
            path={`/PhotoGallery/${row.idCustom}`}
          />
          {!seoRole && (
            <MainButton
              title="حذف"
              btnStyle="transparent"
              fontColor="danger"
              marginRight="5"
              onPress={() => {
                setSelectedID(`delete-${row.idCustom}`);
                onDelModalOpen();
              }}
            />
          )}
        </Flex>
      ),
    },
  ];

  useEffect(() => {
    let data = [];
    if (generalData?.data?.length > 0 && !!generalData) {
      generalData.data.forEach((item) => {
        let dataObj = {
          id: `${item.id + Math.floor(Math.random() * Date.now())}`,
          idCustom: `${item.id}`,
          title: `${item.title}`,
          image: `${item?.Image?.url}`,
        };

        data.push(dataObj);
      });
      setDataArray(data);
    }
    setCurrPagData({
      page: 1,
      // sortOrder: "DESC",
      // sort: "lastModifiedAt",
    });
  }, []);

  const handleOnPageChange = (page, sort = "lastModifiedAt", sortOrder) => {
    //GetPhotoGalleryAPI(page, sort, sortOrder)
    GetPhotoGalleryAPI(10, 0, page)
      .then((res) => {
        let data = [];

        if (res.data.length > 0) {
          res.data.forEach((item) => {
            let dataObj = {
              id: `${item.id + Math.floor(Math.random() * Date.now())}`,
              idCustom: `${item.id}`,
              title: `${item.title}`,
              image: `${item?.Image?.url}`,
            };

            data.push(dataObj);
          });

          setDataArray(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <HStack width="100%">
        <Tabs variant="unstyled" width="100%">
          <TabPanels>
            <TabPanel w="98%" px="0">
              <TablePagination2
                data={dataArray}
                columns={columns}
                isSearchable={false}
                title="معرض الصور"
                hasDelete={true}
                totalRows={generalData.totalRows}
                pageSize={generalData.limit}
                GetDataAPI={GetPhotoGalleryAPI}
                handleOnPageChange={handleOnPageChange}
                onSort={handleSort}
                setCurrPagData={setCurrPagData}
                currPagData={currPagData}
                haveCreateButton={!seoRole}
                path={`/PhotoGallery`}
                buttonTitle="إضافة صورة"
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </HStack>
    </>
  );
};
