import React from "react";
import {
  Box,
  Divider,
  Flex,
  RadioGroup,
  Radio,
  SimpleGrid,
  GridItem,
  Text,
  Stack,
  Image,
  Button,
} from "@chakra-ui/react";

import { InputFieldValidation } from "../../../components/inputField/InputFieldValidation";
import { MainButton } from "../../../components/button/MainButton";

export const CareersForm = ({
  errors,
  touched,
  setFieldValue,
  handleBlur,
  onCancelOpen,
  formikValues,
  disableSave,
  setDismissSave,
  generalData,
}) => {
  const download = (e, url) => {
    e.preventDefault();
    fetch(url, {
      method: "GET",
      mode: "no-cors",
      credentials: "include",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          // const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("target", "_blank"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Box
        maxW={["100%", "100%", "60%"]}
        width={["100%", "100%", "60%"]}
        alignSelf="flex-start"
      >
        <InputFieldValidation
          label="العنوان"
          inputType="text"
          inputId="title"
          inputName="title"
          errors={errors}
          touched={touched}
          isRequired
          onChange={setFieldValue}
          values={formikValues}
          isDisabled={true}
        />
        <InputFieldValidation
          label="الإسم"
          inputType="text"
          inputId="name"
          inputName="name"
          errors={errors}
          touched={touched}
          isRequired
          onChange={setFieldValue}
          values={formikValues}
          isDisabled={true}
        />

        <SimpleGrid columns={{ sm: 4, md: 4 }} spacingX="15px" width="100%">
          <GridItem colSpan={4}>
            <SimpleGrid columns={{ sm: 4, md: 4 }} spacing="15px" width="100%">
              <GridItem colSpan={2}>
                <InputFieldValidation
                  label="الإيميل"
                  inputType="email"
                  inputId="email"
                  inputName="email"
                  errors={errors}
                  touched={touched}
                  isRequired
                  onChange={setFieldValue}
                  values={formikValues}
                  isDisabled={true}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <InputFieldValidation
                  label="رقم الهاتف"
                  inputType="tel"
                  inputId="mobile"
                  inputName="mobile"
                  errors={errors}
                  touched={touched}
                  isRequired
                  placeholder=""
                  onChange={setFieldValue}
                  values={formikValues}
                  isDisabled={true}
                />
              </GridItem>
            </SimpleGrid>
          </GridItem>
        </SimpleGrid>
        <Box paddingY="10px">
          {!!generalData?.cvURL && (
            <>
              <Text
                className="title_sm"
                display="flex"
                mb={2}
                mt={0}
                component="div"
                textTransform="uppercase"
                fontSize="12px"
              >
                السيرة الذاتية
              </Text>
              <a
                href={generalData.cvURL}
                /* href="/tst.docx" */ download
                target="_blank"
              >
                <Button>عرض الملف</Button>
              </a>
            </>
          )}
        </Box>
        <Box display="none">
          <InputFieldValidation
            label="cv"
            inputType="text"
            inputId="cv"
            inputName="cv"
            errors={errors}
            touched={touched}
            isRequired
            //placeholder="3.75"
            onChange={setFieldValue}
            values={formikValues}
            isDisabled={true}
          />
        </Box>

        <Text
          className="title_sm"
          display="flex"
          mb={2}
          mt={0}
          component="div"
          textTransform="uppercase"
          fontSize="12px"
        >
          هل عملت في قطاع الرخام من قبل؟
        </Text>
        <RadioGroup
          value={`${formikValues?.previouslyWork}`}
          className="radio_group_custom"
          mb={6}
          name="previouslyWork"
          isDisabled={true}
        >
          <Stack direction="row">
            <Radio
              color="#003865 !important"
              bg="none !important"
              borderColor="#6F767E !important"
              value="yes"
            >
              نعم
            </Radio>
            <Radio
              color="#003865 !important"
              bg="none !important"
              borderColor="#6F767E !important"
              value="no"
            >
              لا
            </Radio>
          </Stack>
        </RadioGroup>
        <Text
          className="title_sm"
          display="flex"
          mb={2}
          mt={0}
          component="div"
          textTransform="uppercase"
          fontSize="12px"
        >
          الجنس
        </Text>
        <RadioGroup
          value={`${formikValues?.gender}`}
          className="radio_group_custom"
          mb={6}
          name="gender"
          isDisabled={true}
        >
          <Stack direction="row">
            <Radio
              color="#003865 !important"
              bg="none !important"
              borderColor="#6F767E !important"
              value="Female"
            >
              انثى
            </Radio>
            <Radio
              color="#003865 !important"
              bg="none !important"
              borderColor="#6F767E !important"
              value="Male"
            >
              ذكر
            </Radio>
          </Stack>
        </RadioGroup>
      </Box>

      <Divider orientation="horizontal" mt="20px" mb="30px" />
      <Flex justifyContent="flex-end" alignItems="center" width="100%">
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="الغاء"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="الغاء"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={`/CareersHistory`}
            />
          )}

          {/* <MainButton title="Submit" reverseRow={true} disabledBtn={true} /> */}
        </Box>
      </Flex>
    </>
  );
};
