import React from "react";

import { Box, Divider, Flex, SimpleGrid, GridItem } from "@chakra-ui/react";

import { InputFieldValidation } from "../../../components/inputField/InputFieldValidation";
import { MainButton } from "../../../components/button/MainButton";
import { TextArea } from "../../../components/textArea/TextArea";

export const ContactFormForm = ({
  errors,
  touched,
  setFieldValue,
  handleBlur,
  onCancelOpen,
  formikValues,
  disableSave,
  setDismissSave,
}) => {
  return (
    <>
      <Box
        maxW={["100%", "100%", "60%"]}
        width={["100%", "100%", "60%"]}
        alignSelf="flex-start"
      >
        <SimpleGrid columns={{ sm: 4, md: 4 }} spacingX="15px" width="100%">
          <GridItem colSpan={4}>
            <SimpleGrid columns={{ sm: 4, md: 4 }} spacing="15px" width="100%">
              <GridItem colSpan={2}>
                <InputFieldValidation
                  label="الإسم الأول"
                  inputType="text"
                  inputId="firstName"
                  inputName="firstName"
                  errors={errors}
                  touched={touched}
                  isRequired
                  onChange={setFieldValue}
                  values={formikValues}
                  isDisabled={true}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <InputFieldValidation
                  label="الإسم الأخير"
                  inputType="text"
                  inputId="lastName"
                  inputName="lastName"
                  errors={errors}
                  touched={touched}
                  isRequired
                  placeholder=""
                  onChange={setFieldValue}
                  values={formikValues}
                  isDisabled={true}
                />
              </GridItem>
            </SimpleGrid>
          </GridItem>
        </SimpleGrid>
        <InputFieldValidation
          label="الهاتف"
          inputType="tel"
          inputId="mobile"
          inputName="mobile"
          errors={errors}
          touched={touched}
          isRequired
          onChange={setFieldValue}
          values={formikValues}
          isDisabled={true}
        />
        <InputFieldValidation
          label="الخدمة"
          inputType="text"
          inputId="service"
          inputName="service"
          errors={errors}
          touched={touched}
          isRequired
          onChange={setFieldValue}
          values={formikValues}
          isDisabled={true}
        />
        <TextArea
          label="التفاصيل"
          inputId="details"
          inputName="details"
          placeholder="تفاصيل التواصل"
          errors={errors}
          touched={touched}
          isRequired
          values={formikValues}
          onChange={setFieldValue}
          onBlur={handleBlur}
          isDisabled={true}
        />
      </Box>

      <Divider orientation="horizontal" mt="20px" mb="30px" />
      <Flex justifyContent="flex-end" alignItems="center" width="100%">
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="إلغاء"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="إلغاء"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={`/ContactFormHistory`}
            />
          )}

          {/* <MainButton title="Submit" reverseRow={true} disabledBtn={true} /> */}
        </Box>
      </Flex>
    </>
  );
};
