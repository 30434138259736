import * as Yup from "yup";

export const validationAboutUsForm = Yup.object().shape({
  introduction: Yup.string().required("هذه الخانة مطلوبة"),
  vision: Yup.string().required("هذه الخانة مطلوبة"),
  mission: Yup.string().required("هذه الخانة مطلوبة"),
  philosophy: Yup.string().required("هذه الخانة مطلوبة"),
  ceoText: Yup.string().required("هذه الخانة مطلوبة"),
  ceoName: Yup.string().required("هذه الخانة مطلوبة"),
  image: Yup.string().required("هذه الخانة مطلوبة"),
  altImag: Yup.string().required("هذه الخانة مطلوبة"),
});
