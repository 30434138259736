import React, { useState, useEffect, useRef } from "react";

import { NavLink, useLocation } from "react-router-dom";

import { format as dateFnsFormat, parseISO as dateFnsParseISO } from "date-fns";
import { useParams } from "react-router-dom";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
//import Image from "@ckeditor/ckeditor5-image/src/image";

import { Formik, Form } from "formik";
import { Box, Divider, Flex } from "@chakra-ui/react";

import { ModalAlert } from "../components/modalAlert/ModalAlert";
import { MainTitle } from "../components/mainTitle/MainTitle";
import { MainBox } from "../components/mainBox/MainBox";
import { MainButton } from "../components/button/MainButton";
import { InputFieldValidation } from "../components/inputField/InputFieldValidation";
import { TextArea } from "../components/textArea/TextArea";

//ClassicEditor.builtinPlugins = [Image];

export const CkEditor = () => {
  const [initFormVal, setInitFormVal] = useState({});

  useEffect(() => {
    setInitFormVal({
      comments: "",
      visitorNumbers: [],
    });
  }, []);
  // console.log("initForm", initFormVal);
  const custom_config = {
    /* extraPlugins: [MyCustomUploadAdapterPlugin], */
    ckfinder: {
      uploadUrl: "/uploads",
    },
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "link",
        // "bulletedList",
        // "numberedList",
        "|",
        // "blockQuote",
        "insertTable",
        "|",
        "imageUpload",
        "undo",
        "redo",
      ],
    },
    image: {
      toolbar: ["imageTextAlternative", "imageStyle:full", "imageStyle:side"],
    },
    table: {
      contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
  };

  return (
    <div>
      <MainBox>
        <Box className="table_content" pt={6} pb={6}>
          <Formik
            initialValues={initFormVal}
            enableReinitialize={true}
            //validationSchema={validationVisAssumptionsForm[0]}
            validate={(values) => {
              // console.log("vaaal", values);
              /* let form = values.visitorNumbers;
                let formLength = [];
                
                console.log(formLength, emptyform);
                if (formLength.length === emptyform.length) {
                  setIsDisabledForm(false);
                } else {
                  setIsDisabledForm(true);
                } */
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2));
              }, 400);
            }}
          >
            {({
              isSubmitting,
              errors,
              touched,
              values,
              handleChange,
              handleBlur,
              setFieldValue,
              setValues,
              isValid,
              dirty,
            }) => (
              <Form className="form_wrapper">
                <>
                  <InputFieldValidation
                    label={`Year 5`}
                    placeholder="0"
                    inputType="number"
                    inputId={`visitorNumbers.numbers`}
                    inputName={`visitorNumbers.numbers`}
                    errors={errors}
                    touched={touched}
                    isRequired
                    onChange={setFieldValue}
                    values={values?.visitorNumbers}
                    labelCenter={true}
                    errorHeight0={true}
                    uppercase={false}
                    //isDisabled={isRestore}
                  />

                  <CKEditor
                    editor={ClassicEditor}
                    data="<p>Hello from CKEditor 5!</p>"
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      // console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      // console.log("data", data);
                      // console.log({ event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                      //console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      //console.log("Focus.", editor);
                    }}
                    /* config={{
                      toolbar: ["imageUpload"],
                    }} */
                    //config={custom_config}
                    config={{
                      ckfinder: {
                        // uploadUrl: "http://localhost:5000/uploads",
                        uploadUrl: "https://alamin-marble.com:8085/uploads",
                      },
                      toolbar: {
                        items: [
                          "heading",
                          "|",
                          "bold",
                          "italic",
                          "link",
                          // "bulletedList",
                          // "numberedList",
                          "|",
                          // "blockQuote",
                          "insertTable",
                          "|",
                          "imageUpload",
                          "undo",
                          "redo",
                        ],
                      },
                    }}
                  />

                  <Divider orientation="horizontal" mt="20px" mb="30px" />
                  <Box display="flex" width="100%">
                    <Box minW={["100%", "40%", "40%"]}>
                      <TextArea
                        label="Add Comment"
                        inputId={`comments`}
                        inputName={`comments`}
                        placeholder="Add a comment"
                        errors={errors}
                        touched={touched}
                        isRequired
                        values={values}
                        onChange={setFieldValue}
                        onBlur={handleBlur}
                        //isDisabled={isRestore}
                      />
                    </Box>
                  </Box>
                </>

                <Divider orientation="horizontal" mt="20px" mb="30px" />
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                  <MainButton
                    title="Save Draft"
                    //disabledBtn={isRestore}
                    btnStyle="transparent"
                    fontColor="dark"
                    onPress={() => {
                      //onDraftOpen();
                    }}
                  />

                  <Box display="flex" gap="30px">
                    {dirty ? (
                      <MainButton
                        title="Cancel"
                        btnStyle="transparent"
                        fontColor="light"
                        onPress={() => {
                          //onCancelOpen();
                        }}
                      />
                    ) : (
                      <MainButton
                        title="Cancel"
                        btnStyle="transparent"
                        fontColor="light"
                        navLink={true}
                        path={`/VisitorNumHistory`}
                      />
                    )}
                    <MainButton
                      title={"Submit"}
                      reverseRow={true}
                      marginLeft="2"
                      //disabledBtn={isDisabledForm ? true : false}
                      onPress={() => {
                        // console.log("final value formikValue", values);
                        //handleOnSubmit(values);
                      }}
                    />
                  </Box>
                </Flex>
              </Form>
            )}
          </Formik>
        </Box>
      </MainBox>
    </div>
  );
};
