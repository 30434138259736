import { useState, useEffect } from "react";
import {
  Box,
  Flex,
  HStack,
  TabPanel,
  TabPanels,
  Tabs,
  Image,
} from "@chakra-ui/react";

import { GetUploadAPI } from "../../api/UploadAPI";

import { MainButton } from "../../components/button/MainButton";
import { TablePagination2 } from "../../components/tablePagination/TablePagination2";

export const UploadTable = ({ onDelModalOpen, generalData, setSelectedID }) => {
  let [currPagData, setCurrPagData] = useState({
    page: 1,
  });
  const handleSort = async () => {
    handleOnPageChange(currPagData.page);
    setCurrPagData({ ...currPagData });
  };
  const [dataArray, setDataArray] = useState([]);
  const columns = [
    {
      name: "IMAGE",
      selector: (row) => row.image,
      cell: (row) => (
        <Box color={`#007BB5`} paddingY="10px">
          <Image width="60px" height="60px" src={row.image}></Image>
        </Box>
      ),
    },
    {
      name: "ALT",
      selector: (row) => row.title,
    },
    {
      name: "ACTION",
      selector: (row) => row.action,
      cell: (row) => (
        <Flex
          justifyContent="space-between"
          gap="15px"
          alignItems="center"
          flexDirection={["column", "column", "row"]}
        >
          <MainButton
            title="Details"
            btnStyle="transparent"
            fontColor="dark"
            marginRight="5"
            navLink={true}
            path={`/Upload/${row.idCustom}`}
          />
          <MainButton
            title="Delete"
            btnStyle="transparent"
            fontColor="danger"
            marginRight="5"
            onPress={() => {
              setSelectedID(`delete-${row.idCustom}`);
              onDelModalOpen();
            }}
          />
        </Flex>
      ),
    },
  ];

  useEffect(() => {
    let data = [];
    if (generalData?.data?.length > 0 && !!generalData) {
      generalData.data.forEach((item) => {
        let dataObj = {
          id: `${item.id + Math.floor(Math.random() * Date.now())}`,
          idCustom: `${item.id}`,
          title: `${item.alt}`,
          image: `${item.url}`,
        };

        data.push(dataObj);
      });
      setDataArray(data);
    }
    setCurrPagData({
      page: 1,
    });
  }, []);

  const handleOnPageChange = (page) => {
    GetUploadAPI(10, 0, page)
      .then((res) => {
        let data = [];

        if (res.data.length > 0) {
          res.data.forEach((item) => {
            let dataObj = {
              id: `${item.id + Math.floor(Math.random() * Date.now())}`,
              idCustom: `${item.id}`,
              title: `${item.alt}`,
              image: `${item.url}`,
            };

            data.push(dataObj);
          });

          setDataArray(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <HStack width="100%">
        <Tabs variant="unstyled" width="100%">
          <TabPanels>
            <TabPanel w="98%" px="0">
              <TablePagination2
                data={dataArray}
                columns={columns}
                isSearchable={false}
                title="Upload"
                hasDelete={true}
                totalRows={generalData.totalRows}
                pageSize={generalData.limit}
                GetDataAPI={GetUploadAPI}
                handleOnPageChange={handleOnPageChange}
                onSort={handleSort}
                setCurrPagData={setCurrPagData}
                currPagData={currPagData}
                haveCreateButton={true}
                path={`/Upload`}
                buttonTitle="Create Upload"
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </HStack>
    </>
  );
};
