import react, { useState, useEffect, useCallback, useRef } from "react";

import { format as dateFnsFormat, parseISO as dateFnsParseISO } from "date-fns";
import { NavLink } from "react-router-dom";

import { GetOrdersAPI, GetProductsAPI } from "../api/ProductsAPI";

import { OrdersTable } from "./OrdersTable";
import { ProductsTable } from "./ProductsTable";
import { Box, HStack, Text, Center, Spinner } from "@chakra-ui/react";

import { activeMenuItem } from "../utils/General";
import { MainTitle } from "../components/mainTitle/MainTitle";
import { MainBox } from "../components/mainBox/MainBox";
import { parseToken } from "../utils/Auth";

import doc from "../assets/images/iconsSvg/doc.svg";
import plus from "../assets/images/iconsSvg/plus.svg";
import infoCircle from "../assets/images/iconsSvg/infoCircle.svg";

// import document from "../assets/images/icons/";
// import note from "../assets/images/icons/";
// import filled from "../assets/images/icons/";
// import key from "../assets/images/icons/";

export const DashboardHome = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [generalData, setGeneralData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [generalData2, setGeneralData2] = useState([]);
  const [isLoading2, setIsLoading2] = useState(true);
  const [seoRole, setSeoRole] = useState(
    parseToken().role === "ROLE_MODERATOR"
  );

  const loadTableData = useCallback(() => {
    document.getElementById("main_content").scrollTo(0, 0);

    GetOrdersAPI({ offset: 0, limit: 10 }, 1)
      .then((res) => {
        setGeneralData(res);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    GetProductsAPI({ offset: 0, limit: 10 }, 1)
      .then((res) => {
        setGeneralData2(res);
        setIsLoading2(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    loadTableData();
  }, [loadTableData]);

  return (
    <div>
      <MainTitle title="لوحة التحكم" />

      {isLoading ? (
        <>
          <MainTitle title="طلبات المنتجات" />
          <MainBox>
            <Box paddingTop={12} paddingBottom={12}>
              <Center>
                <Spinner />
              </Center>
            </Box>
          </MainBox>
        </>
      ) : (
        <>
          {generalData?.data?.length <= 0 ? (
            <>
              <MainTitle title="طلبات المنتجات" />
              <MainBox pb={0}>
                <Box className="table_content" pt={6} pb={0}>
                  <Center flexDirection="column">
                    <img src={doc} alt="icon" />
                    <Box mt={3} mb={5}>
                      <Text className="title_sm">لا يوجد طلبات للمنتجات</Text>
                    </Box>
                  </Center>
                </Box>
              </MainBox>
            </>
          ) : (
            <>
              <OrdersTable generalData={generalData} />
            </>
          )}
        </>
      )}

      {isLoading2 ? (
        <>
          <MainTitle title="المنتجات" />
          <MainBox>
            <Box paddingTop={12} paddingBottom={12}>
              <Center>
                <Spinner />
              </Center>
            </Box>
          </MainBox>
        </>
      ) : (
        <>
          {generalData2?.length <= 0 ? (
            <>
              <MainTitle title="المنتجات" />
              <MainBox pb={0}>
                <Box className="table_content" pt={0} pb={6}>
                  <Center flexDirection="column">
                    <img src={doc} alt="icon" />
                    <Box mt={3} mb={5}>
                      <Text className="title_sm">No product created yet</Text>
                    </Box>
                  </Center>
                </Box>
              </MainBox>
            </>
          ) : (
            <>
              <Box marginTop="-50px">
                <ProductsTable generalData={generalData2} seoRole={seoRole} />
              </Box>
            </>
          )}
        </>
      )}
    </div>
  );
};
