import React, { useState, useEffect } from "react";

import {
  GetMediaByIdAPI,
  UpdateMediaAPI,
  AddMediaAPI,
} from "../../../api/MediaAPI";

import { useLocation, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { Box, useDisclosure } from "@chakra-ui/react";

import { activeMenuItem } from "../../../utils/General";
import { ModalAlert } from "../../../components/modalAlert/ModalAlert";
import { MainTitle } from "../../../components/mainTitle/MainTitle";
import { MainBox } from "../../../components/mainBox/MainBox";
import { MediaForm } from "./MediaForm";
import { validationMediaForm } from "./validationMediaForm";
import history from "../../../history";

import checked from "../../../assets/images/iconsSvg/checked.svg";
import infoCircle from "../../../assets/images/iconsSvg/infoCircle.svg";

export const Media = () => {
  const activeMenu = activeMenuItem();
  const { type, id } = useParams();
  let location = useLocation();
  //const [isRestore, setIsRestore] = useState(false);
  const [initFormVal, setInitFormVal] = useState({});
  const [generalData, setGeneralData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [disableSave, setDisableSave] = useState(true);
  const [dismissSave, setDismissSave] = useState(true);

  const [ckData, setCkData] = useState("");
  const [ckDataEn, setCkDataEn] = useState("");
  const [imageObj, setImageObj] = useState({});

  let submitModalTitle = id ? "تم التحديث بنجاح" : "تم الإضافة بنجاح";

  const {
    isOpen: isCancelOpen,
    onOpen: onCancelOpen,
    onClose: onCancelClose,
  } = useDisclosure();
  const {
    isOpen: isSubmitOpen,
    onOpen: onSubmitOpen,
    onClose: onSubmitClose,
  } = useDisclosure();

  const {
    isOpen: isUploadOpen,
    onOpen: onUploadOpen,
    onClose: onUploadClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();

  const handleOnSubmit = (formikValue) => {
    if (!!id) {
      const submitValues = {
        // ...formikValue,
        id: generalData.id,
        title: formikValue.title,
        titleEn: formikValue.titleEn,
        description: ckData,
        //description: formikValue.description,
        // descriptionEn: formikValue.descriptionEn,
        descriptionEn: ckDataEn,
        video: formikValue.video,
        mediaCategoryID: parseInt(type),
        mediaDate: formikValue.mediaDate,
        mediaLink: formikValue.mediaLink,
        metaTitle: formikValue.metaTitle,
        metaDesc: formikValue.metaDesc,
        metaTitleEn: formikValue.metaTitleEn,
        metaDescEn: formikValue.metaDescEn,
        metaKeys: formikValue.metaKeys,
        metaKeysEn: formikValue.metaKeysEn,
        imageURL: generalData.imageURL,
        image: formikValue.image,
        altImage: formikValue.altImage,
        slug:formikValue.slug,
        flag:formikValue.flag,
      };
      // console.log(JSON.stringify(submitValues));
      UpdateMediaAPI(id, submitValues)
        .then((res) => {
          setDisableSave(true);
          setErrorMessage("");
          onSubmitOpen();
          if (res.success === true) {
            setDisableSave(true);
            setErrorMessage("");
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(err?.response?.data?.message || "هناك خطأ ما");
          onErrorOpen();
        });
    } else {
      const submitValues = {
        // ...formikValue,
        title: formikValue.title,
        titleEn: formikValue.titleEn,
        description: ckData,
        descriptionEn: ckDataEn,
        video: formikValue.video,
        mediaCategoryID: parseInt(type),
        mediaDate: formikValue.mediaDate,
        mediaLink: formikValue.mediaLink,
        metaTitle: formikValue.metaTitle,
        metaTitleEn: formikValue.metaTitleEn,
        metaDesc: formikValue.metaDesc,
        metaDescEn: formikValue.metaDescEn,
        metaKeys: formikValue.metaKeys,
        metaKeysEn: formikValue.metaKeysEn,
        image: formikValue.image,
        altImage: formikValue.altImage,
        slug:formikValue.slug,
        flag:formikValue.flag,
      };

      // console.log(JSON.stringify(submitValues));
      AddMediaAPI(submitValues)
        .then((res) => {
          setDisableSave(true);
          setErrorMessage("");
          onSubmitOpen();
          if (res.success === true) {
            setDisableSave(true);
            setErrorMessage("");
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(err?.response?.data?.message || "هناك خطأ ما");
          console.log("err", err);
          onErrorOpen();
        });
    }
  };

  useEffect(() => {
    var date = new Date();

    if (!!id) {
      setErrorMessage("");
      GetMediaByIdAPI(id)
        .then((res) => {
          setInitFormVal({
            title: !!res.title ? res.title : "",
            titleEn: !!res.titleEn ? res.titleEn : "",
            description: !!res.description ? res.description : "",
            descriptionEn: !!res.descriptionEn ? res.descriptionEn : "",
            video: !!res.video ? res.video : "",
            mediaLink: !!res.mediaLink ? res.mediaLink : "",
            metaTitle: !!res.metaTitle ? res.metaTitle : "",
            metaTitleEn: !!res.metaTitleEn ? res.metaTitleEn : "",
            metaDesc: !!res.metaDesc ? res.metaDesc : "",
            metaDescEn: !!res.metaDescEn ? res.metaDescEn : "",
            metaKeys: !!res.metaKeys ? res.metaKeys : "",
            metaKeysEn: !!res.metaKeysEn ? res.metaKeysEn : "",
            slug: !!res.slug ? res.slug : "",
            flag: !!res.flag ? res.flag : "",
            // titleEn: res.titleEn,
            // description: res.description,
            // descriptionEn: res.descriptionEn,
            // video: res.video,
            // mediaDate: res.mediaDate,
            // mediaDate: `${new Date(res.mediaDate)}`,
            mediaDate: `${res.mediaDate}`,
            // mediaLink: res.mediaLink,
            // metaTitle: res.metaTitle,
            // metaTitleEn: res.metaTitleEn,
            // metaDesc: res.metaDesc,
            // metaDescEn: res.metaDescEn,
            image: res.image,
            altImage: res.altImage,
            alt: res.altImage,
            altEn: "",
            altEn2: res?.altImageEn,
            altAr: "",
            file: "",
          });
          setGeneralData(res);
        })
        .catch((err) => {
          setErrorMessage("هناك خطأ ما");
          onErrorOpen();
        });
    } else {
      setInitFormVal({
        title: "",
        titleEn: "",
        description: "",
        descriptionEn: "",
        metaDesc: "",
        metaDescEn: "",
        metaTitle: "",
        metaTitleEn: "",
        metaKeys: "",
        metaKeysEn: "",
        mediaLink: "",
        mediaDate: `${date.toLocaleDateString("en-GB")}`,
        video: "",
        image: "",
        altImage: "",
        alt: "",
        altEn: "",
        altEn2: "",
        altAr: "",
        file: "",
        slug: "",
        flag: "",
      });
    }
  }, []);
  useEffect(() => {
    let unblock;
    if (!disableSave && dismissSave) {
      unblock = history.block((tx) => {
        let url = tx.location.pathname;
        if (window.confirm(`هل أنت متأكد أنك تريد تجاهل التغييرات؟`)) {
          unblock();

          tx.retry();
        }
      });
    }

    return () => {
      if (typeof unblock === "function") {
        unblock();
      }
    };
  }, [disableSave, dismissSave]);

  return (
    <div className="Media">
      <>
        <MainBox px={0} mb={0}>
          {id === undefined ? (
            <MainTitle
              title={`إضافة ${type == 1 ? "خبر" : "مقال"}`}
              bBottom={false}
              px={7}
            />
          ) : (
            <MainTitle
              title={`تعديل ${type == 1 ? "الأخبار" : "المقالات"}`}
              bBottom={false}
              px={7}
            />
          )}
        </MainBox>
        <Box className="" pt={0} pb={6}>
          <Formik
            initialValues={initFormVal}
            enableReinitialize={true}
            validationSchema={validationMediaForm}
            validate={(values) => {
              if (disableSave) {
                setDisableSave(false);
              }
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2));
                setSubmitting(false);
              }, 400);
            }}
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              handleBlur,
              setFieldValue,
              isValid,
              dirty,
            }) => (
              <Form className="form_wrapper">
                <MediaForm
                  onCancelOpen={onCancelOpen}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  formikValues={values}
                  isValid={isValid}
                  dirty={dirty}
                  disableSave={disableSave}
                  setDisableSave={setDisableSave}
                  dismissSave={dismissSave}
                  setDismissSave={setDismissSave}
                  onSubmitOpen={onSubmitOpen}
                  onUploadOpen={onUploadOpen}
                  handleOnSubmit={handleOnSubmit}
                  setInitFormVal={setInitFormVal}
                  generalData={generalData}
                  isUploadOpen={isUploadOpen}
                  onUploadClose={onUploadClose}
                  onErrorOpen={onErrorOpen}
                  setErrorMessage={setErrorMessage}
                  setImageObj={setImageObj}
                  type={type}
                  ckData={ckData}
                  setCkData={setCkData}
                  ckDataEn={ckDataEn}
                  setCkDataEn={setCkDataEn}
                />

                <ModalAlert
                  isOpen={isCancelOpen}
                  onClose={onCancelClose}
                  title="هل أنت متأكد أنك تريد تجاهل التغييرات؟"
                  icon={infoCircle}
                  hasDesc={false}
                  confirmationModal={true}
                  path={`/MediaHistory/${type}`}
                  setDismissSave={() => setDismissSave(true)}
                />
              </Form>
            )}
          </Formik>
        </Box>

        <ModalAlert
          isOpen={isErrorOpen}
          onClose={onErrorClose}
          title={errorMessage}
          icon={infoCircle}
          hasDesc={false}
          confirmationModal={false}
        />

        <ModalAlert
          isOpen={isSubmitOpen}
          onClose={onSubmitClose}
          title={submitModalTitle}
          btnWidthFull={true}
          icon={checked}
          hasDesc={false}
          disableClose={true}
          path={`/MediaHistory/${type}`}
        />
      </>
    </div>
  );
};
