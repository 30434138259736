import * as Yup from "yup";

export const validationProjectsForm = Yup.object().shape({
  title: Yup.string().required("هذه الخانة مطلوبة"),
  // titleEn: Yup.string().required("هذه الخانة مطلوبة"),
  address: Yup.string().required("هذه الخانة مطلوبة"),
  details: Yup.string().required("هذه الخانة مطلوبة"),
  contractor: Yup.string().required("هذه الخانة مطلوبة"),
  // owner: Yup.string().required("هذه الخانة مطلوبة"),
  yearOfSupply: Yup.string().required("هذه الخانة مطلوبة"),
  image1: Yup.string().required("هذه الخانة مطلوبة"),
  alt1: Yup.string().required("هذه الخانة مطلوبة"),
  image3: Yup.string().required("هذه الخانة مطلوبة"),
  alt3: Yup.string().required("هذه الخانة مطلوبة"),
  // metaTitle: Yup.string().required("هذه الخانة مطلوبة"),
  // metaDesc: Yup.string().required("هذه الخانة مطلوبة"),
  // detailsEn: Yup.string().required("هذه الخانة مطلوبة"),
  // addressEn: Yup.string().required("هذه الخانة مطلوبة"),
  // metaDescEn: Yup.string().required("هذه الخانة مطلوبة"),
  // metaTitleEn: Yup.string().required("هذه الخانة مطلوبة"),
});
